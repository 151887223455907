/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer2 from "../../common/Footer2";
import Header2 from "../../common/Header2";
import { getItem } from "../../common/reusable";
import { apiurl } from "../Url";
import Loader from "../../common/Loader";

function InvestmentHistory() {
  const userId = getItem("UserId");
  const navigate = useNavigate();

  const [loading, Setloading] = useState(true);
  const [historyData, sethistoryData] = useState([]);
  const [intialhistoryData, setInitialhistoryData] = useState([]);
  const [filterText, setFilterText] = useState('');
  useEffect(() => {
    axios
      .get(`${apiurl}investors/viewInvestments/${userId}`)
      // .get(`${apiurl}investors/viewInvestments/6672b0649bad690ebfdd9b9a`)
      .then(response => {

        if (response?.data?.data) {
          sethistoryData(response?.data?.data);
          setInitialhistoryData(response?.data?.data)
          Setloading(false);
        } else if (
          !response?.data?.data &&
          response?.data?.message === "Investment not found"
        ) {
          Setloading(false);
          sethistoryData();
        }

        Setloading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const getsinglestartupdetails = (e, id, _id) => {

    e.preventDefault();
    navigate("/investor-view-details", { state: { user_id: id, member_id: _id, slug: "/investment-history" } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const goInvestorProfile = e => {
    e.preventDefault();
    navigate("/investor-profile");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };
  useEffect(() => {
    if (filterText != '') {
      filterData();
    } else {
      sethistoryData(intialhistoryData)
    }

  }, [filterText]);


  const filterData = () => {
    const filtered = historyData.filter(item => {
      const { companyName, sector, state } = item;
      const lowerCaseFilterText = filterText.toLowerCase();
      return (
        companyName.toLowerCase().includes(lowerCaseFilterText) ||
        sector.toLowerCase().includes(lowerCaseFilterText) ||
        state.toLowerCase().includes(lowerCaseFilterText)
      );
    });
    if (filtered.length > 0) {

      sethistoryData(filtered);
    } else {
      sethistoryData([])
    }


  }
  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Investment history</title>
      </Helmet>
      <section className="dashboard-sec payment-history">
        <div className="container">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Investment History</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Investment History</li>
              </ul>
            </div>
            <button className="cta-btn" onClick={e => goInvestorProfile(e)}>
              Back
            </button>
          </div>
          <div className="filter-search-box flex space-between align-center">
            <div className="search-box">
              <img src="src/images/Search.svg" alt="" />
              <input
                type="search"
                id="gsearch"
                name="gsearch"
                value={filterText}
                onChange={handleFilterTextChange}
                placeholder="Search by Company Name, Sector, or Location"
              />
            </div>
          </div>
          <div className="box-wrapper">
            <div id="tab-2" className="tab-content active">
              <div className="grid-view-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Industry Sector</th>
                      <th>Location</th>
                      <th>Investment Raise</th>
                      <th>Date</th>
                      <th>Equity Stake</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                    {historyData.length > 0 && historyData != "undefined" ? (
                      <>
                        {historyData
                          .sort(
                            (a, b) =>
                              new Date(b.updatedAt) - new Date(a.updatedAt)
                          )
                          .map((item, index) => (
                            <tr key={index}>
                              <td onClick={e => {
                                e.preventDefault();
                                getsinglestartupdetails(e, item.startup._id, item.startup.user_id);
                              }} className="investortr">
                                <ul>
                                  <li className="flex">
                                    <div className="img-box logo-size">
                                      <figure>
                                        <img src={
                                          item?.startup?.logo
                                            ? `${item?.startup?.logo}`
                                            : "src/images/dashbord-img01.jpg"
                                        } alt="" />
                                      </figure>
                                    </div>
                                    <div className="text">
                                      <h6>{item?.companyName}</h6>
                                      <p>{item?.description}</p>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                              <td>{item?.sector}</td>
                              <td>{item?.state}</td>
                              <td>₹ {item?.raise} </td>
                              <td>{item?.date}</td>
                              <td>
                                <span>{item?.equity}%</span>
                              </td>
                              <td>
                                <div className="icons flex">
                                  <a
                                    onClick={e => {
                                      e.preventDefault();
                                      getsinglestartupdetails(e, item.startup._id, item.startup.user_id);
                                    }}
                                  >
                                    <img
                                      src="src/images/fill-eye-icon.svg"
                                      alt=""
                                    />
                                  </a>
                                  {/* <a href="#">
                              <img src="src/images/dots.svg" alt="" />
                            </a> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <div className="startupnotfound">
                        {" "}
                        <h2>No Investment History Found</h2>
                      </div>
                    )}


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default InvestmentHistory;
