import React from "react";
import Header from "../common/Header";
import Footer2 from "../common/Footer2";
import { Helmet } from "react-helmet";
function NotFound() {
  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || 404 error</title>
      
      </Helmet>
      <div className="notfound">
      <img src="src/images/404.png" alt="404 image"/>  
            {/* <h1>404</h1>
            <p>Page not Found </p> */}
      </div>
      <Footer2/>
    </>
  );
}
export default NotFound;
