/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import Loader from "../../common/Loader";
import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { login, privacypolicy } from "../../Routes/Routes";
import Loaderbutton from "../../common/loaderbutton";
import { setItem } from "../../common/reusable";
import { ChatContext } from "../../Context/ChatContext";

function SignupStep2() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
    acceptPolicy: false,
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accountType = params.get("accountType");
  const [call, setCall] = useState(false);
  const [calltoken, setCalltoken] = useState("");
	const [loadingloader, Setloadingloader] = useState(false);
  const [errors, setErrors] = useState({});
  const { setStartConnection } = useContext(ChatContext);

  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleChange = e => {
    const { name, value } = e.target;
    const namePattern = /^[A-Za-z\s\-\'\.]*$/;
    if (name === "name") {
      if (!namePattern.test(value)) {
        setErrors({ ...errors, [name]: "Only Alphabets are allowed" });
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 8) {
      newErrors.password = "Please enter more then 8 character.";
    }
    if (!formData.repeatPassword) {
      newErrors.repeatPassword = "Repeat Password is required.";
    } else if (formData.repeatPassword !== formData.password) {
      newErrors.repeatPassword = "Passwords do not match.";
    }
    if (!formData.acceptPolicy) {
      newErrors.acceptPolicy = "Please accept the Privacy Policy.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.repeatPassword,
        userType: accountType,
      };
      axios
        .post(`${apiurl}users`, data)
        .then(response => {
          if (response.data.status === true) {
            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem(
              "UserId",
              JSON.stringify(response.data.lastUser?._id)
            );
            localStorage.setItem(
              "userType",
              JSON.stringify(response.data.lastUser?.userType)
            );
            setItem("url", "/identity-business-verfication1");
            setStartConnection(true);
            navigate("/identity-business-verfication1");
          } else if (response.data.message == "Email already registered") {
            toast.error("user already exists");
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.message);
          setLoading(false);
        });
    }
  };
  // const GoogleLogin = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log(tokenResponse);
  //     navigate("/identity-business-verfication1");
  //   },
  //   onFailure: (error) => {
  //     console.error(error);
  //   },
  // });
  const GoogleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        // Fetch user information from Google
        const userInfoResponse = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`
        );
        const userInfo = userInfoResponse.data;
           Setloadingloader(true);
        // Send the token to your backend
        const response = await axios.post(`${apiurl}users/googlesignup`, {
          google: userInfo,
          userType: accountType,
        });

        const result = response.data;

        if (result?.status == true) {
          localStorage.setItem(
            "token",
            JSON.stringify(result?.lastUser?.token)
          );
          localStorage.setItem("UserId", JSON.stringify(result?.lastUser?._id));
          localStorage.setItem(
            "userType",
            JSON.stringify(result?.lastUser?.userType)
          );
          setItem("url", "/identity-business-verfication1");
          setStartConnection(true);
          navigate("/identity-business-verfication1");
        } else {
          toast.error(result.message);
          Setloadingloader(false);
        }
      } catch (error) {
        console.error(error);
        Setloadingloader(false);
      }
    },
    onFailure: error => {
      console.error(error);
    },
  });

  const fetchProfileData = async accessToken => {
    Setloadingloader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      linkedIn: accessToken,
      userType: accountType,
      redirect_uri: `${window.location.origin}/linkedin`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiurl}users/linkedInsignup`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status == true) {
          setCall(false);
          localStorage.setItem(
            "token",
            JSON.stringify(result?.lastUser?.token)
          );
          localStorage.setItem("UserId", JSON.stringify(result?.lastUser?._id));
          localStorage.setItem(
            "userType",
            JSON.stringify(result?.lastUser?.userType)
          );
          setItem("url", "/identity-business-verfication1");
          setStartConnection(true);
          navigate("/identity-business-verfication1");
        } else {
          toast.error(result.message);
          Setloadingloader(false);
          setCall(false);
        }
      })
      .catch(error => console.error(error));
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: response => {
      setCall(true);
      setCalltoken(response);
      // fetchProfileData(response);
    },
    onError: error => {
      console.log(error);
    },
  });
  useEffect(() => {
    if (call) {
      fetchProfileData(calltoken);
    }
  }, [call]);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Startup Signup</title>
      </Helmet>
      <div
        className="loading-overlay"
        style={{ display: loadingloader ? "block" : "none" }}
      >
        <Loader />
      </div>
      <section className="create-account-sec">
        <div className="container">
          <div className="row flex">
            <div className="img-box">
              <Link to="/">
                <img src="src/images/logo-second.svg" alt="" />
              </Link>
              <h5>Join the Startup Community</h5>
              <p>
                Create a <a href="/">Startup Account</a> to showcase your
                business idea, connect with <a href="/">potential investors</a>,
                and take your startup to the next level. Join a community of
                entrepreneurs and <a href="/">innovators</a> who are{" "}
                <a href="/">shaping the future.</a>
              </p>
            </div>
            <div className="form-box">
              <div className="form-wrapper">
                <div className="top-comman-text text-center">
                  <h4>Create an account</h4>
                  {/* <p>Your social campaigns</p> */}
                </div>
                <ul className="signup-method flex">
                  <li onClick={GoogleLogin}>
                    <img src="src/images/Google.svg" alt="" />
                    Sign up with Google
                  </li>
                  <li onClick={linkedInLogin}>
                    <img src="src/Linkedin1.png" alt="" />
                    Sign up with LinkedIn
                  </li>
                  <a className="with-mail">
                    <span>Or with email</span>
                  </a>
                </ul>
                <form>
                  <div className="form-group">
                    <label htmlFor="">
                      Name<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="John Doe"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error-msg">{errors.name}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Email<span className="required-sign">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="example@udharaa.com"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Password<span className="required-sign">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="************"
                      className="form-control"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="error-msg">{errors.password}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Repeat Password<span className="required-sign">*</span>
                    </label>
                    <input
                      type="password"
                      name="repeatPassword"
                      placeholder="************"
                      className="form-control"
                      value={formData.repeatPassword}
                      onChange={handleChange}
                    />
                    {errors.repeatPassword && (
                      <p className="error-msg">{errors.repeatPassword}</p>
                    )}
                  </div>
                  <div className="form-group checkbox">
                    <label className="flex">
                      I Accept the{" "}
                      <a href={privacypolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                      <input
                        type="checkbox"
                        name="acceptPolicy"
                        checked={formData.acceptPolicy}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {errors.acceptPolicy && (
                      <p className="error-msg">{errors.acceptPolicy}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <Link onClick={handleSubmit}>
                      <button className="cta-btn">
                        {" "}
                        {loading ? (
                          <div>
                            <Loaderbutton/>{" "}
                          </div>
                        ) : (
                          "Sign up"
                        )}
                      </button>
                    </Link>
                  </div>
                  <div className="form-group sign-in">
                    <p>
                      Already have an Account? <Link to={login}>Login</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep2;
