/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { getItem } from "../../../common/reusable";
import { get } from "../../../services/services";
import {
  MessageBox,
  Input,
  Button,
  Navbar,
  Avatar,
} from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "./Chat.css";
import { ChatContext } from "../../../Context/ChatContext";
import { toast } from "react-toastify";
import EmojiPicker from "emoji-picker-react";
import { post } from "../../../services/services";
import ReactImageGallery from "react-image-gallery";
import { json, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
const imgs = [
  ".jpeg",
  ".jpg",
  ".png",
  ".gif",
  ".bmp",
  ".tiff",
  ".tif",
  ".webp",
  ".svg",
  ".ico",
  ".heif",
  ".heic",
  ".cr2",
  ".nef",
  ".arw",
  ".orf",
  ".rw2",
  ".avif",
];

const videos = [
  ".mp4",
  ".mkv",
  ".webm",
  ".flv",
  ".vob",
  ".ogv",
  ".avi",
  ".mov",
  ".wmv",
  ".mpg",
  ".mpeg",
  ".m4v",
  ".3gp",
  ".3g2",
  ".f4v",
  ".f4p",
  ".f4a",
  ".f4b",
];

const Chat = ({
  selectedChat,
  _messages,
  chatId,
  startupID,
  investorID,
  receiverId,
  isPin,
  isBlockedByYou,
  areUBlocked,
  draft,
  setDraft,
  onChatSelect,
}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [replyingTo, setReplyingTo] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [menuActive, setMenuActive] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [search, setSearch] = useState("");
  const [issearch, setIsSearch] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const { socket, isUserOnline, userChats } = useContext(ChatContext);
  const [zoommeeting, Setzoommeeting] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [onlineFromSettings, setOnlineFromSettings] = useState(true);
  const [openGallery, setOpenGallery] = useState({ status: false, index: "" });
  const [images, setImages] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const [clearchatPopup, SetclearchatPopup] = useState(false);
  // const [deletechatPopup, SetDeletechatPopup] = useState(false);
  const [isStartupReplied, setIsStartupReplied] = useState(true);

  // const userType = getItem("userType");
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { width } = useWindowDimensions();

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    if (getItem("userType") == "StartUp") {
      setIsStartupReplied(true);
    } else {
      if (userChats?.length > 0) {
        let u = userChats?.filter(
          a => a?.members?.filter(b => b?._id == receiverId)?.length > 0
        );

        if (u?.length > 0 && u[0]?.isStartupReplied == true) {
          setIsStartupReplied(true);
        } else {
          setIsStartupReplied(false);
        }
      } else {
      }
    }
  }, [userChats, receiverId]);

  const chatContainerStyle = {
    width: width < 450 ? "100%" : "calc(100% - 367px)",
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (searchActive && width > 768) {
      setSearchActive(false);
    }
  }, [width]);

  useEffect(() => {
    if (draft?.input) {
      setInput(draft?.input);
    } else {
      setInput("");
    }
    if (draft?.files && draft?.files?.length) {
      setSelectedFiles(draft.files);
    } else {
      setSelectedFiles([]);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat?._id != undefined) {
      fetchOnlineStatus();
    }
  }, [selectedChat?._id]);

  useEffect(() => {
    let intervalId;
    if (selectedChat?._id) {
      intervalId = setInterval(() => {
        socket.emit("IsThisUserOnline", {
          userId: receiverId,
          socketId: socket?.id,
        });
      }, 6000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedChat?._id]);

  const fetchOnlineStatus = () => {
    get(`users/getprofileSetting/${selectedChat?._id}`)
      .then(response => {
        setOnlineFromSettings(response?.data?.data?.onlineStatus);
      })
      .catch(err => console.log(err));
  };

  const [zoommeetingPayload, setZoommeetingPayload] = useState({
    chatId: "",
    token: "",
    senderId: "",
    meeting: null,
    receiverId: "",
  });
  // const [highlightedMessages, setHighlightedMessages] = useState([]);
  const [searchCount, setSearchCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const messagesEndRef = useRef(null);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, chatId]);

  useEffect(() => {
    if (openGallery?.status) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [openGallery]);

  useEffect(() => {
    const messageId = `$message${currentIndex}`;
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [currentIndex]);

  useEffect(() => {
    if (search.trim() === "") {
      // setHighlightedMessages([]);
      setSearchCount(0);
      return;
    }

    let count = 0;
    const updatedMessages = messages.map((msg, index) => {
      if (msg.text) {
        const regex = new RegExp(
          `(${search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
          "gi"
        );
        const parts = msg.text.split(regex);

        const highlightedText = parts.map((part, index) =>
          regex.test(part) ? (
            <span key={`${msg._id}_${index}`} className="highlight">
              {part}
            </span>
          ) : (
            part
          )
        );

        count += (msg.text.match(regex) || []).length;

        return {
          ...msg,
          text: highlightedText,
          index,
        };
      } else {
        return msg;
      }
    });

    // setHighlightedMessages(updatedMessages);
    setSearchCount(count);
    setCurrentIndex(0);
  }, [messages, search]);

  useEffect(() => {
    let i = [];
    _messages?.map(m => {
      if (m?.file) {
        if (imgs?.filter(a => m?.file?.includes(a))?.length > 0) {
          i.push({
            original: m?.file,
            thumbnail: m?.file,
          });
        }
      }
      if (videos?.filter(a => m?.file?.includes(a))?.length > 0) {
        i.push({
          videoSrc: m?.file,
          thumbnail: "src/images/VideoPlay.png",
          renderItem: item => (
            <div>
              <video controls>
                <source src={item.videoSrc} type="video/mp4" />
              </video>
            </div>
          ),
        });
      }
    });
    setImages(i);
  }, [_messages]);

  const Previousresult = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const Nextresult = () => {
    if (currentIndex < searchCount - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const EmojiSelect = emoji => {
    // console.log("Selected emoji:", emoji);
    const text = input + emoji.emoji;
    setInput(text);
    setDraft({ ...draft, input: text });
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    if (zoommeeting) {
      socket.emit("createMessage", zoommeetingPayload);
      setZoommeetingPayload({});
      if (messages) {
        setMessages([...messages, zoommeetingPayload]);
      } else {
        setMessages([zoommeetingPayload]);
      }
      // Setzoommeeting(false);
      setInput("");
      setDraft({ ...draft, input: "" });
      setReplyingTo(null);
    } else {
      // Setzoommeeting(false);
    }
  }, [zoommeeting]);

  useEffect(() => {
    if (search === "") {
      setIsSearch(false);
    }
  }, [search]);

  useEffect(() => {
    let finalArr = [];
    let imagesArr = [];
    _messages?.map(m => {
      if (m?.file && imgs?.filter(a => m?.file?.includes(a))?.length > 0) {
        imagesArr.push(m);
      } else {
        if (imagesArr?.length > 0) {
          if (imagesArr.length > 3) {
            let dateToCheck = imagesArr[0]?.createdAt;
            let newImagesArr = [];
            imagesArr?.map((ia, i) => {
              if (true) {
                if (isWithinFiveMinutes(dateToCheck, ia?.createdAt)) {
                  newImagesArr.push(ia);
                  if (i == imagesArr?.length - 1) {
                    if (newImagesArr?.length > 3) {
                      finalArr.push({
                        ...newImagesArr[0],
                        subImageMessages: newImagesArr?.filter(
                          ia => ia?._id != newImagesArr[0]?._id
                        ),
                      });
                      newImagesArr = [];
                    } else {
                      finalArr = [...finalArr, ...newImagesArr];
                      newImagesArr = [];
                    }
                  }
                } else {
                  dateToCheck = ia?.createdAt;

                  if (newImagesArr?.length > 3) {
                    finalArr.push({
                      ...newImagesArr[0],
                      subImageMessages: newImagesArr?.filter(
                        ia => ia?._id != newImagesArr[0]?._id
                      ),
                    });
                    newImagesArr = [];
                  } else {
                    finalArr = [...finalArr, ...newImagesArr];
                    newImagesArr = [];
                  }
                  newImagesArr.push(ia);
                }
              }
            });
          } else {
            finalArr = [...finalArr, ...imagesArr];
            imagesArr = [];
          }
          imagesArr = [];
        }
        finalArr.push(m);
      }
    });

    if (imagesArr?.length > 0) {
      if (imagesArr.length > 3) {
        let dateToCheck = imagesArr[0]?.createdAt;
        let newImagesArr = [];
        imagesArr?.map((ia, i) => {
          if (true) {
            if (isWithinFiveMinutes(dateToCheck, ia?.createdAt)) {
              newImagesArr.push(ia);
              if (i == imagesArr?.length - 1) {
                if (newImagesArr?.length > 3) {
                  finalArr.push({
                    ...newImagesArr[0],
                    subImageMessages: newImagesArr?.filter(
                      ia => ia?._id != newImagesArr[0]?._id
                    ),
                  });
                  newImagesArr = [];
                } else {
                  finalArr = [...finalArr, ...newImagesArr];
                  newImagesArr = [];
                }
              }
            } else {
              dateToCheck = ia?.createdAt;

              if (newImagesArr?.length > 3) {
                finalArr.push({
                  ...newImagesArr[0],
                  subImageMessages: newImagesArr?.filter(
                    ia => ia?._id != newImagesArr[0]?._id
                  ),
                });
                newImagesArr = [];
              } else {
                finalArr = [...finalArr, ...newImagesArr];
                newImagesArr = [];
              }
              newImagesArr.push(ia);
            }
          }
        });
      } else {
        finalArr = [...finalArr, ...imagesArr];
        imagesArr = [];
      }
    }

    setMessages(finalArr);
  }, [_messages]);

  useEffect(() => {
    if (selectedFiles?.length > 0 && input) {
      setInput("");
      setDraft({ ...draft, input: "" });
    }
    if (selectedFiles?.length > 0) {
      let d = document.querySelectorAll(
        ".chatbox-wrapper .chat-container .rce-input"
      );

      if (d?.length > 0) {
        for (let i = 0; i < d.length; i++) {
          d[i].style.display = "none";
        }
      }
      let d1 = document.getElementsByClassName("rce-container-input");
      if (d1?.length > 0) {
        d1[0].style.width = "25%";
      }
    } else {
      let d = document.querySelectorAll(
        ".chatbox-wrapper .chat-container .rce-input"
      );

      if (d?.length > 0) {
        for (let i = 0; i < d.length; i++) {
          d[i].style.display = "block";
        }
      }
      let d1 = document.getElementsByClassName("rce-container-input");
      if (d1?.length > 0) {
        d1[0].style.width = "100%";
      }
    }
  }, [selectedFiles]);

  useEffect(() => {
    const handleClickOutside = event => {
      const menu = document.getElementById("chat_menu");
      if (menu && !menu.contains(event.target)) {
        setMenuActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuActive]);

  useEffect(() => {
    if (isBlockedByYou || areUBlocked) {
      setShow(true);
    }
  }, [isBlockedByYou, areUBlocked]);

  // const popup = {
  //   show: show,
  //   header: "Alert",
  //   headerButtons: [
  //     {
  //       type: "transparent",
  //       color: "black",
  //       text: "X",
  //       onClick: () => {
  //         setShow(false);
  //       },
  //     },
  //   ],
  //   text: isBlockedByYou
  //     ? "You have blocked this user. You will no longer receive messages from them."
  //     : "You have been blocked by this user. You can no longer send or receive messages from them.",
  //   footerButtons: [
  //     {
  //       color: "white",
  //       backgroundColor: "#43b8f5",
  //       text: "OK",
  //       onClick: () => {
  //         setShow(false);
  //       },
  //     },
  //   ],
  // };

  // const popup1 = {
  //   show: show1,
  //   header: "Alert",
  //   headerButtons: [
  //     {
  //       type: "transparent",
  //       color: "black",
  //       text: "X",
  //       onClick: () => {
  //         setShow1(false);
  //       },
  //     },
  //   ],
  //   text: `Are you sure you want to ${
  //     isBlockedByYou ? "unblock" : "block"
  //   } this contact?`,
  //   footerButtons: [
  //     {
  //       color: "white",
  //       backgroundColor: "#43b8f5",
  //       text: "Cancel",
  //       onClick: () => {
  //         setShow1(false);
  //       },
  //     },
  //     {
  //       color: "white",
  //       backgroundColor: "red",
  //       text: "Yes",
  //       onClick: () => {
  //         blockUnblock();
  //         setShow1(false);
  //       },
  //     },
  //   ],
  // };

  const isWithinFiveMinutes = (date1, date2) =>
    Math.abs(new Date(date1) - new Date(date2)) <= 5 * 60 * 1000;

  const handleSend = async e => {
    e?.preventDefault();
    if (zoommeetingPayload?.link == input) {
      Setzoommeeting(true);
    } else {
      let newMessage = {};
      let toastId = null;
      if (input?.includes("web.zoom.us")) {
        setInput("");
        setDraft({ ...draft, input: "" });
        return toast.error(
          "You can not send your zoom meeting link. You need to generate one"
        );
      }

      if (selectedFiles?.length > 0) {
        setSendLoading(true);
        let files = [];
        let videoUrls = [];
        let videos = selectedFiles?.filter(file =>
          file?.type?.includes("video/") || file?.type?.includes("image/")
        );
        let others = selectedFiles?.filter(
          file => !file?.type?.includes("video/") && !file?.type?.includes("image/")
        );

        if (videos?.length > 0) {
          toastId = toast.loading(
            `Your ${
              selectedFiles?.length > 1 ? "files are" : "file is"
            } being uploaded!`,
            {
              autoClose: false,
            }
          );

          const fd = new FormData();
          fd.append("chatId", chatId);
          fd.append(
            "senderId",
            localStorage?.getItem("UserId")?.replaceAll('"', "")
          );

          for (let i = 0; i < videos.length; i++) {
            const e = videos[i];
            fd.append("files", e);
          }

          let res = await post("investors/sendVideoMessage", fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (res.status == 200) {
            if (res?.data?.urls && res?.data?.urls?.length > 0) {
              videoUrls = res?.data?.urls;
              newMessage = {
                ...newMessage,
                videoUrls,
              };
            }
          }
        }

        others?.map(file => {
          files.push({
            buffer: file,
            name: file?.name?.replaceAll(" ", "_"),
            type: file?.type,
          });
        });

       

        newMessage = {
          ...newMessage,
          chatId: chatId,
          token: localStorage?.getItem("token")?.replaceAll('"', ""),
          senderId: localStorage?.getItem("UserId")?.replaceAll('"', ""),
          file: files,
          receiverId: selectedChat?._id,
        };
  
        if (input.trim() !== "") {
          newMessage = {
            ...newMessage,
            text: input,
          };
        }
      } else {
        if (input.trim() != "") {
          newMessage = {
            chatId: chatId,
            token: localStorage?.getItem("token")?.replaceAll('"', ""),
            senderId: localStorage?.getItem("UserId")?.replaceAll('"', ""),
            text: input,
            receiverId: selectedChat?._id,
          };
          // const newMessage = {
          //   position: "right",
          //   type: "text",
          //   text: input,
          //   date: new Date(),
          //   replyTo: replyingTo ? replyingTo.id : null,
          //   id: messages.length + 1, // Assuming unique ID generation
          //   attachments: selectedFiles.map((file) => ({
          //     name: file.name,
          //     url: URL.createObjectURL(file),
          //     type: file.type.startsWith("image/") ? "photo" : "file",
          //   })),
          // };
        }
      }

      if (newMessage?.chatId) {
        socket.emit("createMessage", newMessage);
        if (toastId) {
          toast.update(toastId, {
            render: `${
              selectedFiles?.length > 1 ? "Files" : "File"
            } uploaded successfully!`,
            type: "success",
            autoClose: 3000,
            isLoading: false,
          });
        }
  
        if (messages) {
          // console.log('message pdf')
          let m = { ...newMessage };
          if (selectedFiles?.length > 0) {
            m = {
              ...m,
              file: "src/images/Uploading.gif",
              isUploading: true,
            };
          }
          // console.log([...messages,m])
          setMessages([...messages, m]);
        } else {
          setMessages([newMessage]);
        }
      }

      setSendLoading(false);
      setInput("");
      setReplyingTo(null);
      setSelectedFiles([]);
      setDraft({ files: [], input: "" });
    }
  };
  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files); 
    const maxSizeMB = 10; 
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
  
   const validFiles = filesArray.filter(file => file.size <= maxSizeBytes);
  
    if (validFiles.length === 0) {
    
      toast.warn("File should not exceed by 10mb.");
      return;
    }
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  
   
    if (draft?.files) {
      setDraft((prevDraft) => ({
        ...prevDraft,
        files: [...prevDraft.files, ...filesArray],
      }));
    } else {
      setDraft((prevDraft) => ({
        ...prevDraft,
        files: [...filesArray],
      }));
    }
  
 
    setTimeout(() => {
      event.target.value = null;
    }, 800);
  
 
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  
  // const handleFileChange = event => {
  //   setSelectedFiles([...selectedFiles, ...event.target.files]);
  //   if (draft?.files) {
  //     setDraft({ ...draft, files: [...draft?.files, ...event.target.files] });
  //   } else {
  //     setDraft({ ...draft, files: [...event.target.files] });
  //   }
  //   setTimeout(() => {
  //     event.target.value = null
  //   }, 800);
  //   messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  const renderMessage = (msg, index) => {
    msg = {
      ...msg,
      position:
        msg?.senderId == localStorage.getItem("UserId")?.replaceAll('"', "")
          ? "right"
          : "left",
      // title:
      //   msg?.senderId == localStorage.getItem("UserId")?.replaceAll('"', "")
      //     ? "Me"
      //     : selectedChat?.name,
      date: new Date(msg?.createdAt),
    };

    if (msg?.meeting) {
      msg = {
        ...msg,
        type: "text",
        text: (
          <>
            Topic: {msg.meeting?.topic} <br />
            Time:{" "}
            {new Intl.DateTimeFormat("en-GB", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
              .format(new Date(msg?.meeting?.time))
              .replace(",", "")}{" "}
            <br />
            <br />
            Join Zoom Meeting
            <br />
            Link: <a href={msg?.meeting?.link}>{msg?.meeting?.link}</a> <br />
            Pwd: {msg?.meeting?.pwd}
            <br />
            <br />
            Meeting ID: {msg?.meeting.id} <br />
            Passcode: {msg?.meeting?.passcode} <br />
          </>
        ),
      };
    } else if (msg?.file) {
      if (msg?.isUploading) {
        msg = {
          ...msg,
          type: "photo",
          data: {
            uri: msg?.file,
            autoDownload: false,
            height: 150,
            width: 250,
          },
        };
      } else if (
        imgs?.filter(a => msg?.file?.includes(a))?.length > 0 ||
        typeof msg?.file != "string"
      ) {
        msg = {
          ...msg,
          type: "photo",
          data: {
            uri: msg?.file,
            autoDownload: false,
            height: 254,
            width: 260,
          },
          onOpen: function open() {
            let index = images.findIndex(
              image => image?.original === msg?.file
            );
            setOpenGallery({ status: true, index });
          },
        };
      } else if (videos?.filter(a => msg?.file?.includes(a))?.length > 0) {
        msg = {
          ...msg,
          type: "video",
          data: {
            videoURL: msg?.file,
            status: {
              click: true,
              loading: 0.5,
              download: true,
            },
            height: 260,
            width: 260,
          },
          onOpen: function open() {
            let index = images.findIndex(image => image?.videoSrc == msg?.file);
            setOpenGallery({ status: true, index });
          },
        };
      } else if (msg?.file) {
        msg = {
          ...msg,
          type: "file",
          text:
            typeof msg?.file == "string"
              ? (msg?.file?.substring(msg?.file?.lastIndexOf("/") + 1).length > 15
              ? msg?.file?.substring(msg?.file?.lastIndexOf("/") + 1, msg?.file?.lastIndexOf("/") + 1 + 20) + "..."
              : msg?.file?.substring(msg?.file?.lastIndexOf("/") + 1))
              : "Sending...",
          onOpen: async function open() {
            if (
              msg?.senderId ==
              localStorage.getItem("UserId")?.replaceAll('"', "")
            ) {
              window.open(msg?.file, "_blank", "noopener,noreferrer");
            } else {
              if (msg?.isFileDownloaded) {
                window.open(msg?.file, "_blank", "noopener,noreferrer");
              }
            }
          },
          onDownload: async function download() {
            if (
              msg?.senderId ==
              localStorage.getItem("UserId")?.replaceAll('"', "")
            ) {
            } else {
              let payload = {
                messages: [
                  {
                    _id: msg?._id,
                    isFileDownloaded: true,
                  },
                ],
                senderId: localStorage?.getItem("UserId")?.replaceAll('"', ""),
                receiverId: receiverId,
              };

              socket.emit("updateMessage", payload);
            }
            const response = await fetch(msg?.file);
            const blob = await response.blob();
            const url1 = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url1;
            link.setAttribute(
              "download",
              msg?.file?.substring(msg?.file?.lastIndexOf("/") + 1) || "file"
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url1);
          },
          data: {
            uri: msg?.file,
            status: {
              loading: 0,
              error: false,
              autoDownload: false,
              download:
                msg?.senderId ==
                localStorage.getItem("UserId")?.replaceAll('"', "")
                  ? true
                  : msg?.isFileDownloaded
                  ? true
                  : false,
            },
          },
        };
      }
    } else if (msg?.text) {
      if (issearch) {
        let highlightedText = msg.text;

        const regex = new RegExp(
          `(${search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
          "gi"
        );

        const parts = msg.text.split(regex);

        highlightedText = parts.map((part, index) =>
          regex.test(part) ? (
            <span
              key={`${msg._id}_${index}`}
              id={`$message${index}`}
              className="highlight"
            >
              {part}
            </span>
          ) : (
            part
          )
        );

        msg = {
          ...msg,
          type: "text",
          text: highlightedText,
        };
      } else {
        msg = {
          ...msg,
          type: "text",
          text: msg?.text,
        };
      }
    }

    // const replyMessage = messages.find(m => m._id === msg.replyTo);
    return (
      <div key={msg.id}>
        <MessageBox
          {...msg}
          renderAddCmp={
            msg?.subImageMessages && msg?.subImageMessages?.length > 0 ? (
              <div
                className={
                  msg?.senderId ==
                  localStorage.getItem("UserId")?.replaceAll('"', "")
                    ? "chat_image_preview_sender"
                    : "chat_image_preview"
                }
                style={{
                  backgroundColor:
                    msg?.senderId ==
                    localStorage.getItem("UserId")?.replaceAll('"', "")
                      ? "#43b8f5"
                      : "white",
                }}
                onClick={() => {
                  let index = images.findIndex(
                    image => image?.original === msg?.file
                  );
                  setOpenGallery({ status: true, index });
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="div1">
                    <img
                      src={msg?.file}
                      alt="fileimage"
                      style={{
                        width: "100%",
                        height: "120px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="div2">
                    <img
                      src={"src/images/image_preview.png"}
                      alt="fileimage"
                      style={{ width: "100%", height: "120px" }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="div3">
                    <img
                      src={"src/images/image_preview.png"}
                      alt="fileimage"
                      style={{ width: "100%", height: "120px" }}
                    />
                  </div>
                  <div className="div4">
                    <img
                      src={"src/images/black_img_preview.svg"}
                      alt="fileimage"
                      style={{ width: "100%", height: "120px" }}
                    />
                    <h2 className="preview_text">
                      +{msg?.subImageMessages?.length - 3}
                    </h2>
                  </div>
                </div>
              </div>
            ) : null
          }
        />
        {msg.attachments &&
          msg.attachments.map((file, index) => (
            <div key={index} className="attachment">
              <a
                href={URL.createObjectURL(file)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.name}
              </a>
            </div>
          ))}
      </div>
    );
  };

  const getImg = file => {
    if (file.type?.startsWith("image/")) {
      return URL.createObjectURL(file);
    } else if (file.type.startsWith("video/")) {
      return "src/images/VideoPlay.png";
    } else if (file.type === "application/pdf") {
      return "src/images/PDF.png";
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword"
    ) {
      return "src/images/DOC.png";
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    ) {
      return "src/images/XLS.png";
    } else {
      return "src/images/DOC.png";
    }
  };

  const renderPreview = (file, i) => {
    return (
      <div
        className="attachment-preview"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "150px",
        }}
        key={file.name}
      >
        <img
          src="src/images/cross-icon.svg"
          height={"14px"}
          width={"14px"}
          alt="close"
          style={{
            position: "absolute",
            right: "5px",
            top: "4px",
            cursor: "pointer",
          }}
          onClick={() => {
            // Create a new array without the selected file
            const newSelectedFiles = selectedFiles.filter(
              (_, index) => index !== i
            );
            setSelectedFiles(newSelectedFiles);
            setDraft({ ...draft, files: newSelectedFiles });
          }}
        />
        <img
          style={{ maxHeight: "100px" }}
          src={getImg(file)}
          alt={file.name}
        />
        {file?.name
          ? file?.name.length > 35
            ? file.name.substring(0, 35) + "..."
            : file.name
          : ""}{" "}
      </div>
    );
    // ) : (
    //   <div className="attachment-preview" key={file.name}>
    //     <a
    //       href={URL.createObjectURL(file)}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       {file.name}
    //     </a>
    //   </div>
    // );
  };

  if (!chatId) {
    return (
      <div className="chat-container">
        <div className="chat-container-div">
          <img alt="message" src="src/images/MessageIcon.svg" />
          <span>Welcome To Messages</span>
          <p style={{ fontSize: "20px" }}>
            Start a conversation with the{" "}
            {getItem("userType") != "StartUp" ? "StartUp" : "Investor"}, team to
            get updates and information.
          </p>
        </div>
      </div>
    );
  }

  const pinChat = () => {
    let payload = {
      chatId,
      userId: localStorage.getItem("UserId")?.replaceAll('"', ""),
    };
    socket.emit("pinChat", payload);
    setMenuActive(!menuActive);
  };

  const blockUnblock = () => {
    let payload = {
      chatId,
      userId: localStorage.getItem("UserId")?.replaceAll('"', ""),
      receiverId,
    };
    socket.emit("blockUser", payload);
    // setMenuActive(!menuActive);
  };

  const clearChat = e => {
    e.preventDefault();
    let messagesToClear = _messages?.map(a => {
      return {
        _id: a?._id,
        deletedFor: [
          ...a.deletedFor,
          localStorage.getItem("UserId")?.replaceAll('"', ""),
        ],
      };
    });

    if (messagesToClear?.length > 0) {
      let payload = {
        messages: messagesToClear,
        senderId: localStorage?.getItem("UserId")?.replaceAll('"', ""),
        isClearChat: true,
        receiverId: receiverId,
      };
      socket.emit("updateMessage", payload);
      SetclearchatPopup(false);
    }
    setMenuActive(!menuActive);
  };

  const createMeeting = async () => {
    let response = await post("meeting/create", {
      topic: "Meeting With Startup",
    });

    if (response.status != 200) {
      return toast.error("Not able to create meeting link");
    }
    Setzoommeeting(false);
    let data = response?.data?.data;

    setZoommeetingPayload(prevState => ({
      chatId: chatId,
      token: localStorage?.getItem("token")?.replaceAll('"', ""),
      senderId: localStorage?.getItem("UserId")?.replaceAll('"', ""),
      meeting: data,
      receiverId: selectedChat?._id,
      link: data?.link,
    }));
    setInput(data.link);
    setDraft({ ...draft, input: data.link });
  };

  const searchmessage = () => {
    if (search != "") {
      setIsSearch(true);
    }
  };

  const handleClearSearch = () => {
    setSearch("");
    setIsSearch();
  };


  return (
    <>
      <div className="chat-container" style={chatContainerStyle}>
        <div
          className={`chat-menu ${
            menuActive ? "active messengerchatmenu" : ""
          }`}
          id="chat_menu"
        >
          <ul className="chat-menu-options">
            <li onClick={() => pinChat()} className="chat-menu-text">
              {isPin ? "Unpin" : "Pin"}
            </li>
            <li
              onClick={() => {
                if (messages.length > 0) {
                  SetclearchatPopup(true);
                }
              }}
              className="chat-menu-text"
            >
              Clear Chat
            </li>
            <li
              onClick={() => {
                setMenuActive(!menuActive);
                setShow1(true);
                // SetDeletechatPopup(true);
              }}
              className="chat-menu-text"
            >
              {isBlockedByYou ? "Unblock" : "Block"}
            </li>
          </ul>
        </div>
        <Navbar
          left=<div>
            {width <= 768 ? (
              <li
                className="flex chatbackarrow"
                // style={{ justifySelf: "flex-end", marginRight: "10px" }}
                onClick={() => onChatSelect(null)}
              >
                <img src="src/images/Icon20.png" alt="icon" />
              </li>
            ) : null}
            <Avatar
              src={
                selectedChat?.profile_img
                  ? selectedChat?.profile_img
                  : "src/images/deletedUser.png"
              }
              alt="avatar"
              size="xlarge"
              type="rounded"
            />
            <h3 style={{ color: "white", position: "relative" }}>
              {selectedChat?.name ? selectedChat?.name : "User Deleted"}{" "}
              {selectedChat?.name ? (
                <span
                  className={`status-indicator ${
                    onlineFromSettings == "false"
                      ? "offline"
                      : isUserOnline
                      ? "online"
                      : "offline"
                  }`}
                ></span>
              ) : null}
            </h3>
          </div>
          right=<div>
            <div className="search-wrapper">
              <ul className="flex align-center">
                {width <= 768 ? (
                  <li>
                    <img
                      onClick={() => setSearchActive(!searchActive)}
                      src="src/images/Button_search.png"
                      alt="icon"
                      style={{ height: "41px", maxWidth: "41px" }}
                    />
                  </li>
                ) : (
                  <SearchInput
                    handleClearSearch={handleClearSearch}
                    Nextresult={Nextresult}
                    Previousresult={Previousresult}
                    currentIndex={currentIndex}
                    searchCount={searchCount}
                    searchmessage={searchmessage}
                    search={search}
                    setSearch={setSearch}
                    issearch={issearch}
                  />
                )}

                <li
                  onClick={() =>
                    navigate("/investor-view-details", {
                      state: { user_id: startupID, member_id: receiverId },
                    })
                  }
                  style={
                    getItem("userType") == "StartUp" ? { display: "none" } : {}
                  }
                >
                  <img
                    style={{ height: "38px", maxWidth: "38px" }}
                    src="src/images/Buttonback.png"
                    alt="icon"
                    title="Profile view"
                  />{" "}
                  {/* </Link> */}
                </li>

                <li
                  style={{
                    height: "38px",
                    maxWidth: "38px",
                    marginLeft:
                      getItem("userType") == "StartUp" ? "10px" : "0px",
                  }}
                >
                  <img
                    onClick={() => setMenuActive(true)}
                    src="src/images/ThreeDotsVertical.png"
                    alt="icon"
                  />
                </li>
              </ul>
            </div>
          </div>
          type="dark"
        />
        {searchActive ? (
          <SearchInput
            handleClearSearch={handleClearSearch}
            Nextresult={Nextresult}
            Previousresult={Previousresult}
            currentIndex={currentIndex}
            searchCount={searchCount}
            searchmessage={searchmessage}
            search={search}
            setSearch={setSearch}
            issearch={issearch}
            styleInput={{ paddingLeft: "30px" }}
            styleDiv={{ position: "relative" }}
            styleImg={{ position: "absolute", top: "12px", left: "6px" }}
          />
        ) : null}

        <div className="chat-messages">
          {messages &&
            messages?.map((msg, index) => (
              <div
                key={index}
                onClick={() => setReplyingTo(msg)}
                style={{ position: "relative" }}
              >
                {renderMessage(msg, index)}
              </div>
            ))}
          <div ref={messagesEndRef} />
        </div>
        {false && (
          <div className="replying-to">
            Replying to: <MessageBox {...replyingTo} />
          </div>
        )}
        {isBlockedByYou || areUBlocked ? null : (
          <form
            onSubmit={e => {
              e.preventDefault();
              if (
                isStartupReplied == false &&
                getItem("userType") == "Investor"
              ) {
                toast.error("Message already sent to this Startup");
              } else {
                if (!sendLoading) {
                  handleSend(e);
                }
              }
            }}
          >
            {" "}
            <div className="chat-input">
              {selectedFiles.length > 0 && (
                <div className="attachments-preview">
                  {selectedFiles.map((m, i) => renderPreview(m, i))}
                </div>
              )}
              <Input
                style={{ color: "black" }}
                placeholder={
                  selectedFiles?.length == 0 ? "Write Your Message..." : ""
                }
                value={input}
                onChange={e => {
                  if (selectedFiles?.length == 0) {
                    setInput(e.target.value);
                    setDraft({ ...draft, input: e.target.value });
                  }
                }}
                rightButtons={
                  <>
                    <input
                      type="file"
                      id="file-input"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                
                    />
                    {/* <Button
             text="Attachmenet"
             onClick={() => document.getElementById("file-input").click()}
           /> */}
                    <Button
                      type="submit"
                      text={
                        <img src="/src/images/bottomicon06.png" alt="icon" />
                      }
                      style={{ backgroundColor: "white !important" }}
                      onClick={e => {
                        // if(isStartupReplied == false && getItem("userType") == "Investor") {
                        //   toast.error("Message already sent to this Startup");
                        // } else {
                        //   if (!sendLoading) {
                        //     handleSend(e);
                        //   }
                        // }
                      }}
                    />
                    <div className="icon-wrapper chatbottomicon">
                      <ul className="flex">
                        <li onClick={toggleEmojiPicker}>
                          <img src="src/images/bottomicon02.png" alt="icon" />
                        </li>

                        <li
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          <img src="src/images/bottomicon05.png" alt="icon" />
                        </li>
                        <li onClick={() => createMeeting()}>
                          <img src="src/images/bottomicon04.png" alt="icon" />
                        </li>
                      </ul>
                    </div>
                  </>
                }
              />
            </div>
          </form>
        )}
        {clearchatPopup && (
          <div className="custom-modal clearchatpopup">
            <div
              className={
                clearchatPopup
                  ? "popup-wrapper pop-open deletepopup"
                  : "popup-wrapper"
              }
            >
              <div
                onClick={() => SetclearchatPopup(false)}
                className="pop-cross-btn accountcross"
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
              <div className="sorry-popup">
                <h1>Are you sure you want to clear chat ?</h1>
                <form action="" className="sorryform">
                  <div className="form-wrapper-box flex">
                    <div className="form-group btngroup full-width flex">
                      <button
                        onClick={e => clearChat(e)}
                        className="cta-btn sorrycancelbtn"
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => SetclearchatPopup(false)}
                        className="cta-btn sorrygotbtn"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {show1 && (
          <div className="custom-modal clearchatpopup">
            <div
              className={
                show1 ? "popup-wrapper pop-open deletepopup" : "popup-wrapper"
              }
            >
              <div
                onClick={() => setShow1(false)}
                className="pop-cross-btn accountcross"
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
              <div className="sorry-popup">
                <h1>
                  Are you sure you want to{" "}
                  {isBlockedByYou ? "unblock" : "block"} this contact?
                </h1>
                <form action="" className="sorryform">
                  <div className="form-wrapper-box flex">
                    <div className="form-group btngroup full-width flex">
                      <button
                        //   onClick: () => {
                        //   blockUnblock();
                        //   setShow1(false);
                        // },
                        onClick={() => {
                          blockUnblock();
                          setShow1(false);
                        }}
                        className="cta-btn sorrycancelbtn"
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => setShow1(false)}
                        className="cta-btn sorrygotbtn"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {show && (
          <div className="custom-modal clearchatpopup">
            <div
              className={
                show ? "popup-wrapper pop-open deletepopup" : "popup-wrapper"
              }
            >
              <div
                onClick={() => setShow(false)}
                className="pop-cross-btn accountcross"
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
              <div className="sorry-popup">
                <h1>
                  {isBlockedByYou
                    ? "You have blocked this user. You will no longer receive messages from them."
                    : "You have been blocked by this user. You can no longer send or receive messages from them."}
                </h1>
                <form action="" className="sorryform">
                  <div className="form-wrapper-box flex">
                    <div className="form-group btngroup full-width flex">
                      <button
                        onClick={() => setShow(false)}
                        className="cta-btn sorrygotbtn"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {showEmojiPicker && (
          <div className="emojipicker">
            <button
              className="close-button"
              onClick={() => setShowEmojiPicker(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <EmojiPicker onEmojiClick={EmojiSelect} defaultSkinTone="false" />
          </div>
        )}
        {/* <Popup popup={popup} /> */}
        {/* <Popup popup={popup1} /> */}
      </div>
      {openGallery?.status ? (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              zIndex: "99",
            }}
          >
            <ReactImageGallery
              items={images}
              startIndex={openGallery?.index ? openGallery?.index : 0}
            />
          </div>
          <img
            src="src/images/cross-icon.svg"
            height={"25px"}
            width={"25px"}
            alt="close"
            style={{
              position: "absolute",
              right: "20px",
              top: "120px",
              cursor: "pointer",
              zIndex: "991",
            }}
            onClick={() => setOpenGallery({ status: !openGallery, index: "" })}
          />
        </>
      ) : null}
    </>
  );
};

export default Chat;

const SearchInput = ({
  handleClearSearch,
  Nextresult,
  Previousresult,
  currentIndex,
  searchCount,
  searchmessage,
  search,
  setSearch,
  issearch,
  styleInput,
  styleDiv,
  styleImg,
}) => {
  return (
    <li>
      <div className="form-group" style={styleDiv ? styleDiv : {}}>
        <img
          src="src/images/Search.svg"
          alt=""
          onClick={searchmessage}
          style={styleImg ? styleImg : {}}
        />
        <input
          type="text"
          placeholder="Search..."
          className="form-control"
          style={styleInput ? styleInput : {}}
          value={search}
          onChange={e => {
            searchmessage();
            setSearch(e.target.value);
          }}
          onBlur={searchmessage}
        />
        {search && issearch && (
          <div className="meesage-search">
            <span>
              {searchCount != 0 ? (
                <>
                  {currentIndex + 1}/ {searchCount}
                </>
              ) : (
                <>
                  {" "}
                  {currentIndex}/ {searchCount}
                </>
              )}
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <button
                onClick={Previousresult}
                disabled={currentIndex === 0}
                style={{ height: "15px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="10"
                  height="10"
                >
                  <path d="M12 2l8 10H4z" />
                </svg>
              </button>
              <button
                onClick={Nextresult}
                disabled={currentIndex === searchCount - 1}
                style={{ height: "22px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="10"
                  height="10"
                >
                  <path d="M4 14l8 8 8-8z" />
                </svg>
              </button>
            </span>
          </div>
        )}
        {search && (
          <button
            className="clear-button"
            type="button"
            onClick={handleClearSearch}
          >
            clear
          </button>
        )}
      </div>
    </li>
  );
};
