/* eslint-disable eqeqeq */
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { forgetpassword, home, login } from "../Routes/Routes";

export const HandleChange = (e, setContact) => {
  const { name, value, type, files } = e.target;
  const phoneNumberPattern = /^[+\d\s\-\(\)\.\/]*$/;
  if(name==="phone"){
      if (!phoneNumberPattern.test(value)) {
        toast.warn("Alphabets not allowed");
        return;
      }
  }
  if (type == "file") {
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (allowedImageTypes.includes(files[0].type)) {
      setContact(prev => ({ ...prev, [name]: files[0] }));
      setTimeout(() => {
        e.target.value = null;
      }, 1000);
    } else {
      toast.warn("Please upload Image only ");
    }
  } else {
    setContact(prev => ({ ...prev, [name]: value }));
  }
};
//all files types <10MB
export const handleFileChange = (e, setFile) => {
  const file = e.target.files[0];

  if (!file) {
    toast.error("No file selected");
    return;
  }
  const validFileTypes = [
    "application/pdf",
    "application/msword",
    "application/docx",
    "application/doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
  if (!validFileTypes.includes(file.type)) {
    setTimeout(() => {
      e.target.value = null;
    }, 1000);
    toast.error(
      "Invalid file type. Only PDF, DOC, and DOCX files are allowed."
    );
    return;
  }
  if (file.size > maxSizeInBytes) {
    toast.error("File size exceeds 10MB.");
    return;
  }
  // If all checks pass, set the file
  setFile({ [e.target?.name]: file });
  setTimeout(() => {
    e.target.value = null;
  }, 1000);
};
export const handlePhotoPdf = (e, setFile) => {
  const file = e.target.files[0];
  if (!file) {
    toast.error("No file selected");
    return;
  }
  const validFileTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/svg",
  ];
  const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
  if (!validFileTypes.includes(file.type)) {
    toast.error(
      "Invalid file type. Only PDF and image files (JPEG, PNG, JPG, SVG) are allowed."
    );
    return;
  }
  if (file.size > maxSizeInBytes) {
    toast.error("File size exceeds 5MB.");
    return;
  }
  setFile({ [e.target?.name]: file });
  setTimeout(() => {
    e.target.value = null;
  }, 1000);
};

export const emailValidation = email => {
  const res = /\S+@\S+\.\S+/;
  return res.test(email);
};
export const setItem = (name1, value) => {
  localStorage.setItem(name1, JSON.stringify(value));
};
export const getItem = name1 => {

  return JSON.parse(localStorage?.getItem(name1))
    ? JSON.parse(localStorage?.getItem(name1))
    : "";
};
export const removeItem = name1 => {
  localStorage.removeItem(name1);
};
export const headers = () => {
  const token = getItem("token") ? getItem("token") : "";
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
export const OnlyPublic = () => {
  let navigate = useNavigate();
  let path = window.location.pathname;
  if (path == login || path == forgetpassword) {
    getItem("token") != "" && navigate(home);
  }
};
export const Loading = (props) => {
  return (
    <>
      <div
        style={{ justifyContent: "center", width: "23px", marginLeft: "45%" }}
        className="flex align-center"
      >
        <div className="loader" style={{border:props.signup=="true"?'8px solid #FFDE59;':'8px solid #43b8f5'}}></div>
      </div>
    </>
  );
};
export default Loading;
export const ImaageDocPDF = ({ src }) => {
  if (
    src?.slice(-3) == "jpg" ||
    src?.slice(-4) == "jpeg" ||
    src?.slice(-3) == "png" ||
    src?.slice(-3) == "svg"
  ) {
    return <img src={src} alt="" className="docimage" />;
  } else if (src?.slice(-3) == "pdf") {
    return <iframe src={src} title="pdf" className="dociframe"/>;
  } else {
    return (
      <DocViewer
        style={{
          height: "90%",
          width: "100%",
          paddingBottom:"20px"
        }}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false,
          },
        }}
        documents={[
          {
            uri: src,
            fileType: "docx" || "doc",
          },
        ]}
        pluginRenderers={DocViewerRenderers}
      />
    );
  }
};
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
