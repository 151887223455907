/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import Header2 from "../../common/Header2";
import Footer2 from "../../common/Footer2";
import Profile from "../../common/getProfile";
import { getItem, HandleChange, Loading } from "../../common/reusable";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import Loader from "../../common/Loader";

function AccountSetting() {
  const [googlelogin, SetgoogleLogin] = useState("");
// const [originalpassword,Setoriginalpassword]=useState('')
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    picture: null,
    // phone_number:""
  });
  const [initialuser, setinitialUser] = useState({
    name: "",
    email: "",
    phone: "",
    picture: null,
  });
  const [loading, setLoading] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    new: "",
    confirmPassword: "",
  });
  const [ispasswordChange, setIspasswordChange] = useState(false);
  const [settings, setSettings] = useState({
    not_email: false,
    not_phone: false,
    status: false,
    InvestorLevel: false,
  });
  const navigate = useNavigate();
  const settingsChanges = e => {
    const { name, checked, value, type } = e.target;
    if (type == "checkbox") {
      setSettings({ ...settings, [name]: checked });
    } else if (type == "radio") {
      setSettings({ ...settings, [name]: value == "true" ? true : false });
    }
  };
  // const handlePasswordChange = () => {
  //   if (password?.new.length < 8) {
  //     toast.error("Password must be at least 8 characters long.");
  //     return;
  //   }
  //   if (password.new != password.confirmPassword) {
  //     toast.error("Password and confirm Password not Matched .");
  //   }
  //   if (password?.new.length > 8 && password.new == password.confirmPassword) {
  //     try {
  //       setLoading(true);

  //       post(`${apiurl}users/changePassword`, {
  //         userId: getItem("UserId"),
  //         password: password?.password,
  //         newPassword: password?.new,
  //       })
  //         .then(res => {
  //           if (res?.data?.status == false) {
  //             toast.error(res?.data?.message);
  //           } else if (res?.data?.status == true) {
  //             toast.success(res?.data?.message);
  //             setPassword({ password: "", new: "" });
  //           }
  //           setLoading(false);
  //         })
  //         .catch(err => {
  //           console.log(err);
  //           setLoading(false);
  //         });
  //     } catch (err) {
  //       setLoading(false);
  //       console.log(err);
  //     }
  //   }
  // };
  const fetchProfile = async () => {
    const profile = await Profile();

    if (profile) {
      if (getItem("userType") != "StartUp") {
        setUser({
          name: profile?.user_id?.name,
          email: profile?.user_id?.email,
          phone: profile?.phone_number,
          picture: profile?.profile_picture,
        });

        SetgoogleLogin(profile?.user_id?.googlelogin);
        setinitialUser({
          name: profile?.user_id?.name,
          email: profile?.user_id?.email,
          phone: profile?.phone_number,
          picture: profile?.profile_picture,
        });
      }
    }
    if (getItem("userType") == "StartUp") {
      setUser({
        name: profile?.name,
        email: profile?.email,
        phone: profile?.phone_number,
        picture: profile?.founderPhoto,
      });
      SetgoogleLogin(profile?.user_id?.googlelogin);
      setinitialUser({
        name: profile?.name,
        email: profile?.email,
        phone: profile?.phone_number,
        picture: profile?.founderPhoto,
      });
    }
  };

  const updateAccountSetting = () => {
    if (getItem("userType") == "StartUp") {
      if (user?.name == "" || user?.email == "") {
        toast.error("Pleas fill all required fields");
        return;
      }
    } else {
      if (user?.name == "" || user?.email == "" || user?.phone == "") {
        toast.error("Pleas fill all required fields");
        return;
      }
    }
    

    if(ispasswordChange&&password.password===''&&(password?.new!=""||password?.confirmPassword!='')){
      toast.error("Enter Your current password");
      return;
    }
    if(ispasswordChange &&password?.new==""){
      toast.error("Enter new password to change");
      return;
    }
    if(password?.new != "" && password?.confirmPassword==''&&password!=""){
      toast.error("Enter confirm password");
      return;
    }
    if (password?.new != "" && password?.confirmPassword != password?.new&&password!="") {
      toast.error("Password and Confirm password are not matching");
      return;
    }
    try {
      setLoading(true);
      const changedFields = [];
      Object.keys(user).forEach(key => {
        if (user[key] !== initialuser[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = (field) => {
        return field
          .replace(/([a-z])([A-Z])/g, '$1 $2') 
          .replace(/^./, str => str.toUpperCase()); 
      };
      
    
      const formatChangedFields = (changedFieldsString) => {
       
        const changedFieldsArray = changedFieldsString.split(',').map(field => field.trim());
        
       
        return changedFieldsArray
          .map(toHumanReadable)
          .join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
     const showtext=formatChangedFields(changedFieldsText)
      const formdata = new FormData();
      formdata?.append("name", user?.name);
      formdata?.append("email", user?.email);
      formdata?.append("profile_picture", user?.picture);
      formdata?.append("phone_number", user?.phone);
      formdata?.append("userId", getItem("UserId"));
      formdata?.append(
        "password",
        password?.password != "" ? password.password : ""
      );
      formdata?.append("newPassword", password?.new != "" ? password.new : "");
      formdata?.append(
        "text",
        `${initialuser.name} has changed ${showtext} in his profile`
      );

      post(
        `${apiurl}users/updateAccountSetting/${getItem(
          "UserId"
        )}?updateBy=user`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(res => {
      
          if (!res?.data?.status) {
            toast.error(res?.data?.message);
          } else {
            toast.success(res?.data?.message);
            fetchProfile();
          }

          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const getProfileSettings = () => {
    // try {
setLoading(true);
    get(`${apiurl}users/getprofileSetting/${getItem("UserId")}`)
      .then(response => {
        if (response?.data?.status == true) {
          setSettings({
            not_email:
              response?.data?.data?.notificationEmail == "false" ? false : true,
            not_phone:
              response?.data?.data?.notificationPhone == "false" ? false : true,
            status:
              response?.data?.data?.onlineStatus == "false" ? false : true,
            InvestorLevel:
              response?.data?.data?.investorLevel == "false"
                ? false
                : true || null,
          });
          setLoading(false);
        }
      })
      .catch(err => console.log(err));
    // } catch (err) {
    //   console.log(err);
    // }
  };
  const postProfileSettings = () => {
    try {
      post(`${apiurl}users/profileSetting`, {
        notificationEmail: settings?.not_email ? "true" : "false",
        notificationPhone: settings?.not_phone ? "true" : "false",
        onlineStatus: settings?.status ? "true" : "false",
        // investorLevel: settings?.InvestorLevel ? "true" : "false",
        userId: getItem("UserId"),
      })
        .then(res => {
          if (!res) {
          } else {
            if (res?.data?.status == true) {
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          }
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  const deleteAccount = e => {
    e.preventDefault();
    try {
      get(`${apiurl}admin/deleteUser/${getItem("UserId")}?deleteBy=user`)
        .then(res => {
          localStorage.clear();
          navigate("/");
          toast.success("Account deleted successfully");
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchProfile();
    getProfileSettings();
    // getAccountSettings();
  }, []);

  useEffect(()=>{
if(password.new==""&&password.confirmPassword==''&&ispasswordChange){
  setIspasswordChange(false)
  
}
  },[password.new,password.confirmPassword])

  return (
    <>
         <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 change={loading == false} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Account setting</title>
      </Helmet>
      <section className="dashboard-sec profile-setting">
        <div className="container">
          <div className="bredcrum-box flex align-center space-between">
            <div className="left-box">
              <h5>Account Setting</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Account Setting</li>
              </ul>
            </div>
          </div>
          <div className="box-wrapper">
            <div className="Profile-setting-title-box">
              <div className="shadow-box">
                <h5>Profile Setting</h5>

                <ul>
                  <li className="flex checkbox">
                    <p>Notifications</p>
                    <div className="checkbox-wrap">
                      <label className="flex">
                        Email
                        <input
                          type="checkbox"
                          checked={settings?.not_email}
                          name="not_email"
                          onChange={e => settingsChanges(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="checkbox-wrap">
                      <label className="flex">
                        Phone
                        <input
                          type="checkbox"
                          checked={settings?.not_phone}
                          onChange={e => settingsChanges(e)}
                          name="not_phone"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </li>
                  <li className="flex checkbox">
                    <p>Status</p>
                    <div className="checkbox-wrap">
                      <label className="flex">
                        Online
                        <input
                          type="radio"
                          checked={settings?.status == true}
                          onChange={e => settingsChanges(e)}
                          name="status"
                          value={true}
                        />
                        <span className="checkmark radio"></span>
                      </label>
                    </div>
                    <div className="checkbox-wrap">
                      <label className="flex">
                        Offline
                        <input
                          type="radio"
                          checked={settings?.status == false}
                          onChange={e => settingsChanges(e)}
                          name="status"
                          value={false}
                        />
                        <span className="checkmark radio"></span>
                      </label>
                    </div>
                  </li>
                  {/* <li
                    style={{
                      display: getItem("userType") == "Investor" ? "" : "none",
                    }}
                    className="flex checkbox"
                  >
                    <p>Display Investor Level</p>
                    <div className="checkbox-wrap">
                      <label className="flex">
                        Yes
                        <input
                          type="radio"
                          checked={settings?.InvestorLevel === true}
                          onChange={e => settingsChanges(e)}
                          name="InvestorLevel"
                          value={true}
                        />
                        <span className="checkmark radio"></span>
                      </label>
                    </div>
                    <div className="checkbox-wrap">
                      <label className="flex">
                        No
                        <input
                          type="radio"
                          checked={settings?.InvestorLevel === false}
                          onChange={e => settingsChanges(e)}
                          name="InvestorLevel"
                          value={false}
                        />
                        <span className="checkmark radio"></span>
                      </label>
                    </div>
                  </li> */}
                  <li className="flex checkbox btnWrap">
                    <button
                      onClick={() => getProfileSettings()}
                      className="cta-btn border grey-white-btn"
                    >
                      Discard
                    </button>
                    <button
                      className="cta-btn"
                      onClick={() => postProfileSettings()}
                    >
                      Save Changes
                    </button>
                  </li>
                </ul>
              </div>
              <div className="shadow-box">
                <h5>Accounts Setting</h5>
                <div className="img-row flex">
                  <form action="">
                    <div className="img-box">
                      <figure>
                        <img
                          style={{ objectFit: "cover" }}
                          src={
                            (!user?.picture?.type
                              ? user?.picture
                              : URL?.createObjectURL(user.picture)) || "src/images/deletedUser.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div className="icon">
                        <img src="src/images/camera.svg" alt="" />
                        {/* <input style={{ opacity: "0" }} type="file" /> */}
                      </div>
                      <div className="icon">
                        {/* <img src="src/images/camera.svg" alt="" /> */}
                        <input
                          style={{
                            opacity: "0",
                            height: "84px",
                            width: "84px",
                            cursor: "pointer",
                          }}
                          type="file"
                          name="picture"
                          onChange={e => HandleChange(e, setUser)}
                        />
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="form-group">
                        <label htmlFor="">Display Name</label>
                        <input
                          value={user?.name}
                          onChange={e => HandleChange(e, setUser)}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="John Doe"
                          style={
                            user?.name == ""
                              ? { borderBottom: "1px solid red" }
                              : {}
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">User Name</label>
                        <input
                          value={user?.name}
                          onChange={e => HandleChange(e, setUser)}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="John Doe"
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input
                          value={user?.email}
                          onChange={e => HandleChange(e, setUser)}
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="example@udharaa.com"
                          style={
                            user?.email == ""
                              ? { borderBottom: "1px solid red" }
                              : {}
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Phone</label>
                        <input
                          value={user?.phone}
                          onChange={e => HandleChange(e, setUser)}
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="(9999) 9999 999"
                          style={
                            user?.phone == ""
                              ? { borderBottom: "1px solid red" }
                              : {}
                          }
                        />
                      </div>
                    </div>
                    <div className="form-wrapper full">
                      <div className="form-group">
                        <label htmlFor="">Password</label>
                        <input
                          value={password?.password}
                          type="password"
                          className="form-control"
                          placeholder="************"
                          name="password"
                          onChange={e => {
                            HandleChange(e, setPassword);
                            
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">New Password</label>
                        <input
                          value={password?.new}
                          type="password"
                          className="form-control"
                          placeholder="************"
                          name="new"
                          onChange={e => {
                            HandleChange(e, setPassword);
                            setIspasswordChange(true);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Confirm Password</label>
                        <input
                          value={password?.confirmPassword}
                          type="password"
                          className="form-control"
                          placeholder="************"
                          name="confirmPassword"
                          onChange={e => {
                            HandleChange(e, setPassword);
                            setIspasswordChange(true);
                          }}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="google-account">
                    <h5>Linked Account</h5>
                    <p>
                      We use this to let you sign in and populate your profile
                      information
                    </p>
                    <li className="flex space-between">
                      <a href="#" className="flex align-center">
                        <img src="src/images/google-green.svg" alt="" /> Sign in
                        with Google
                      </a>
                      {googlelogin === null ? (
                        <>
                          <a href="#" className="cta-btn btn-orange">
                            Connect
                          </a>
                        </>
                      ) : (
                        <>
                          {" "}
                          <a href="#" className="cta-btn btn-orange">
                            Connected
                          </a>
                        </>
                      )}
                    </li>
                  </div>
                  <div className="google-account delete-account">
                    <h5>Delete account</h5>
                    <li className="flex space-between">
                      <p>
                        By deleting your account you will lose all your data
                      </p>
                      <a onClick={() => setDelete(true)} href="#">
                        Delete account...
                      </a>
                    </li>
                  </div>
                  <div className="flex btnWrap">
                    <button className="cta-btn border grey-white-btn"  onClick={() => getProfileSettings()}>
                      Discard
                    </button>
                    <button
                      style={
                        loading
                          ? {
                              backgroundColor: "#FFDE59",
                              maxWidth: "129px",
                              minWidth: "69px",
                            }
                          : {}
                      }
                      disabled={loading}
                      onClick={() => updateAccountSetting()}
                      className="cta-btn"
                    >
                      {loading ? (
                        <div style={{ marginLeft: "-30%" }}>
                          <Loading />{" "}
                        </div>
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Delete ? "modal-backdrop" : ""}>
          <div className="custom-modal">
            <div
              className={
                Delete ? "popup-wrapper pop-open deletepopup" : "popup-wrapper"
              }
            >
              <div onClick={() => setDelete(false)} className="pop-cross-btn accountcross">
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
              <div className="sorry-popup">
                <h1>Are you sure ?</h1>
                <form action="" className="sorryform">
                  <div className="form-wrapper-box flex">
                    <div className="form-group btngroup full-width flex">
                      <button
                        onClick={e => deleteAccount(e)}
                        className="cta-btn sorrycancelbtn"
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => setDelete(false)}
                        className="cta-btn sorrygotbtn"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default AccountSetting;
