/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Header2 from "../../common/Header2";
import Footer2 from "../../common/Footer2";
import axios from "axios";
import { apiurl } from "../Url";
import { toast } from "react-toastify";
import { Industries } from "../../common/localStates";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CountryList from "../../common/Country";
import { post, get } from "../../services/services";
import { ImaageDocPDF, isJsonString } from "../../common/reusable";
import Loader from "../../common/Loader";
import { investorprivacy, startupprofile } from "../../Routes/Routes";
import { headers } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function StartupProfile() {
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [Popup, setPopup] = useState(false);
  const [loading, Setloading] = useState(true);
  const [editPopup, setEditPopup] = useState(false);
  const [editType, setEditType] = useState("");
  const [documenttype, setDoucmenttype] = useState("");
  const [editImage, setEditImage] = useState({ imageFile: null });
  const [businessname, setBusinessname] = useState("");
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [initialFormDataState, setInitialFormDataState] = useState({});
  const [checkfirsttimepost, setcheckfirsttimepost] = useState(true);
  const [initialadditionalquestions, setInitialAdditionalquestions] = useState(
    {}
  );
  const [invalidpost, Setisinvalidpost] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    contract: "",
  });
  const [initialbusinessgrowth, setInitialBusinessgrowth] = useState({});
  const [initialaboutproduct, setInitialAboutproduct] = useState({});
  const [initialeditteammember, setInitialediTeammember] = useState({});
  const [initialaboutFounder, SetInitialaboutFounder] = useState({
    businessName: "",
    founderName: "",
    dob: "",
    email: "",
    phoneNumber: "",
    businessRegistrationNumber: "",
    country: "",
    city: "",
    state: "",
    postalCode: "",
    websiteURL: "",
    yearEstablished: "",
    industrySector: "",
    stageOfBusiness: "",
    picthDeck: "",
    fundingNeeds: "",
    minRaiseDescription: "",
    maxRaiseDescription: "",
    logo: "",
    lat: "",
    long: "",
  });
  const [formData, setFormData] = useState({
    businessName: "",
    founderName: "",
    dob: "",
    email: "",
    businessRegistrationNumber: "",
    country: "",
    city: "",
    state: "",
    postalCode: "",
    websiteURL: "",
    yearEstablished: "",
    industrySector: "",
    stageOfBusiness: "",
    picthDeck: "",
    fundingNeeds: "",
    minRaiseDescription: "",
    maxRaiseDescription: "",
    // intellectualProperty: "",
    // trademarkText: "",
    logo: "",
    lat: "",
    long: "",
    administrativeAreaLevel1: "",
  });
  const apiKey = process.env.REACT_APP_LOCATION_API_KEY;
  const getLonglat = async () => {
    if (formData.postalCode != "") {
      setLoadingbutton(true);
    }
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: formData.postalCode,
            key: apiKey,
          },
        }
      );

      if (response.data.status === "OK") {
        
        const administrativeAreaLevel1 =
          response.data.results[0].address_components.find(component =>
            component.types.includes("administrative_area_level_1")
          )?.long_name;
        
        setFormData(prevData => ({
          ...prevData,
          lat: response.data.results[0].geometry.location.lat,
          long: response.data.results[0].geometry.location.lng,
          administrativeAreaLevel1: administrativeAreaLevel1,
        }));
        // setError(null);

        setLoadingbutton(false);
        Setisinvalidpost(false);
      } else {
        setErrors({
          ...errors,
          postalCode: "Please enter valid Postal code",
        });

        setLoadingbutton(false);
        Setisinvalidpost(false);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (checkfirsttimepost && formData.postalCode != "") {
      getLonglat();
      setcheckfirsttimepost(false);
    }
  }, [formData.postalCode]);
  const [additionalQuestions, setAdditionalQuestions] = useState({
    revenueModel: "",
    marketAnalysis: "",
    equiltyallocation: "",
       fundingDetails: "",
    legalStructure: "",
      revenueModelother: "",
    legalStructureother: "",
    pendingLitigation: "",
    pendingLitigationnotes: "",
    gstin:'',
    // featuredInvestors: "",
    intellectualProperty: "",
  });
  const [businessGrowthPopup, setBusinessGrowthPopup] = useState({
    year1Revenue: "",
    year2Revenue: "",
    year3Revenue: "",
    year1GrossProfitMargin: "",
    year2GrossProfitMargin: "",
    year3GrossProfitMargin: "",
    year1EBIDTAMargin: "",
    year2EBIDTAMargin: "",
    year3EBIDTAMargin: "",
    tam:'',
    CAC: "",
    LTV: "",
    burnRate: "",
    activeUsers: "",
    fundingSought: "",
    year: "",
    minimumRaise: "",
    maximumRaise: "",
  });
  const [aboutProduct, setAboutProduct] = useState({
    productImage: "",
    // productDescription: "",
    productVideo: "",
    productLogo: "",
    website: "",
    twitter: "",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
    preview: "",
  });
  const [aboutFounder, setAboutFounder] = useState({
    founderName: "",
    founderPhoto: "",
    product_image: "",
    founderPhotoPreview: "",
    founderDesignation: "",
    founderDescription: "",
  });
  const [idProof, setIdProof] = useState("");
  const [addMember, setAddMember] = useState({
    name: "",
    photo: "",
    bio: "",
    designation: "",
  });
  const [teamMembers, setTeamMembers] = useState("");
  const [updateIdentityPopup, setUpdateIdentityPopup] = useState(false);
  const [updateMemberPopup, setUpdateMemberPopup] = useState(false);
  const [openFounderpopup, setOpenFounderpopup] = useState(false);
  const [additionalQuestionsPopup, setAdditionalQuestionsPopup] =
    useState(false);

  const [economicsandBusinessGrowthPopup, setEconomicsandBusinessGrowthPopup] =
    useState(false);

  const [aboutProductPopup, setAboutProductPopup] = useState(false);
  const [addMemberPopup, setAddMemberPopup] = useState(false);
  const [editTeamMembers, setEditTeamMembers] = useState({
    memberPhoto: "",
    memberDesignation: "",
    memberName: "",
    memberBio: "",
    memberPhotoPreview: "",
    memberid: "",
  });
  const getFileName = file => {
    return file && typeof file === "object" ? file.name : file;
  };
  const countries = CountryList();
  const [errors, setErrors] = useState({});
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openDeletePopup = () => {
    setIsPopupOpen(true);
  };

  const closeDeletePopup = () => {
    setIsPopupOpen(false);
  };

  const formatCurrency = value => {
    if (!value) return "";
    const number = parseInt(value, 10);
    const formattedNumber = number.toLocaleString("en-IN"); // Indian numbering format
    return `₹${formattedNumber}`;
  };

  const handleChange = e => {
    const { name, value, files, type, checked } = e.target;
    const patternnumber = /^[₹\d\s,.\-]*$/;
    if (
      name === "year1GrossProfitMargin" ||
      name === "year2GrossProfitMargin" ||
      name === "year3GrossProfitMargin" ||
      name === "year1EBIDTAMargin" ||
      name === "year2EBIDTAMargin" ||
      name === "year3EBIDTAMargin"
    ) {
      if (value < 0 || value > 100) {
        setErrors({ ...errors, [name]: "Value should lie between 0 to 100" });
        return;
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (
      name === "year1Revenue" ||
      name === "year2Revenue" ||
      name === "year3Revenue" ||
      name === "year1GrossProfitMargin" ||
      name === "year2GrossProfitMargin" ||
      name === "year3GrossProfitMargin" ||
      name === "year1EBIDTAMargin" ||
      name === "year2EBIDTAMargin" ||
      name === "year3EBIDTAMargin" ||
      name === "CAC" ||
      name === "LTV" ||
      name === "minimumRaise" ||
      name === "maximumRaise"
    ) {
      if (!patternnumber.test(value)) {
        setErrors({ ...errors, [name]: "Alphabets not allowed" });
        return;
      }
    }

    if (files && files.length > 0) {
      const file = files[0];
      const preview = URL.createObjectURL(file);

      switch (name) {
        case "productImage":
          setAboutProduct(prev => ({
            ...prev,
            [name]: files[0],
            preview: URL.createObjectURL(e.target.files[0]),
          }));
          break;
        case "photo":
          setAddMember(prev => ({
            ...prev,
            [name]: file,
          }));
          break;

        case "memberPhoto":
          setEditTeamMembers(prev => ({
            ...prev,
            memberPhoto: file,
            memberPhotoPreview: preview,
          }));
          break;
        case "memberPhoto1":
          setAboutFounder(prev => ({
            ...prev,
            founderPhoto: file,
            founderPhotoPreview: preview,
          }));
          break;
        default:
          break;
      }
    } else {
      const updatedValue = { [name]: value };

      if (
        name === "year1Revenue" ||
        name === "year2Revenue" ||
        name === "year3Revenue" ||
        name === "CAC" ||
        name === "LTV" ||
        name === "minimumRaise" ||
        name === "maximumRaise"
      ) {
        const rawValue = value.replace(/[^0-9]/g, "");
        const formattedValue = formatCurrency(rawValue);

        setBusinessGrowthPopup(prev => ({
          ...prev,
          [name]: formattedValue,
        }));
      } else {
        setBusinessGrowthPopup(prev => ({
          ...prev,
          ...updatedValue,
        }));
      }

      setFormData(prev => ({
        ...prev,
        ...updatedValue,
      }));
      setAboutFounder(prev => ({
        ...prev,
        ...updatedValue,
      }));
      if (type === "checkbox" && additionalQuestionsPopup) {
        setAdditionalQuestions(prevFormData => {
          const currentData = prevFormData[name];
          const currentArray = Array.isArray(currentData)
            ? currentData
            : currentData
            ? [currentData]
            : [];
          const newValues = checked
            ? [...currentArray, value]
            : currentArray.filter(v => v !== value);

          return {
            ...prevFormData,
            [name]: newValues,
          };
        });
      } else {
        setAdditionalQuestions(prev => ({
          ...prev,
          ...updatedValue,
        }));
      }

      // setBusinessGrowthPopup(prev => ({
      //   ...prev,
      //   ...updatedValue,
      // }));

      setAboutProduct(prev => ({
        ...prev,
        ...updatedValue,
      }));

      setAddMember(prev => ({
        ...prev,
        ...updatedValue,
      }));
      setEditTeamMembers(prev => ({
        ...prev,
        ...updatedValue,
      }));
    }

    setErrors(prev => ({
      ...prev,
      [name]: "",
    }));
  };

  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const currentYear = new Date().getFullYear();
    const newErrors = {};
    if (!formData.businessName) {
      newErrors.businessName = "Business Name is required.";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    } else if (formData.phoneNumber.length < 6) {
      newErrors.phoneNumber = "Phone Number is not Less then 6 Number.";
    }
    if (!formData.founderName) {
      newErrors.founderName = "Founder's Name is required.";
    }
    if (!formData.dob) {
      newErrors.dob = "Date of Birth is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.country) {
      newErrors.country = "Country is required.";
    }
    if (!formData.city) {
      newErrors.city = "City is required.";
    }
    if (!formData.state) {
      newErrors.state = "State is required.";
    }
 
    if (!formData.postalCode) {
      newErrors.postalCode = "Postal Code is required.";
    } else if (formData.postalCode.length < 4) {
      newErrors.postalCode = "Minimum 4 digit required for postalcode";
    } else if (formData.postalCode.length > 10) {
      newErrors.postalCode = "Maximum 10 digit allowed for postalcode";
    } else if (invalidpost && formData.postalCode) {
      newErrors.postalCode = "Invalid Post code";
    } else if (
      formData?.state?.toLowerCase() !==
      formData?.administrativeAreaLevel1?.toLowerCase()
    ) {
      newErrors.postalCode =
        "Please enter a valid postal code according to your State";
    }
    if (!formData.yearEstablished) {
      newErrors.yearEstablished = "Year Established is required.";
    } else if (formData.yearEstablished > currentYear) {
      newErrors.yearEstablished = "Year Established cannot be in Future";
    }
    if (!formData.industrySector) {
      newErrors.industrySector = "Industry Sector is required.";
    }
    if (!formData.stageOfBusiness) {
      newErrors.stageOfBusiness = "Stage of Business is required.";
    }
    if (!formData.picthDeck) {
      newErrors.picthDeck = "Business Description is required.";
    }
    if (!formData.fundingNeeds) {
      newErrors.fundingNeeds = "Funding Amount is required.";
    }
    if (!formData.minRaiseDescription) {
      newErrors.minRaiseDescription = "Minimum Raise Description is required.";
    }
    if (!formData.maxRaiseDescription) {
      newErrors.maxRaiseDescription = "Maximum Raise Description is required.";
    }
    if (
      parseFloat(formData.minRaiseDescription) >
      parseFloat(formData.maxRaiseDescription)
    ) {
      newErrors.maxRaiseDescription =
        "Maximum Raise cannot be less than Minimum Raise";
    }
    // if (!formData.intellectualProperty) {
    //   newErrors.intellectualProperty = "Intellectual Property is required.";
    // }
    // if (!formData.trademarkText && formData.intellectualProperty === "Yes") {
    //   newErrors.trademarkText = "Intellectual Property text is required.";
    // }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(formData).forEach(key => {
        if (formData[key] !== initialFormDataState[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = field => {
        return field
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, str => str.toUpperCase());
      };

      const formatChangedFields = changedFieldsString => {
        const changedFieldsArray = changedFieldsString
          .split(",")
          .map(field => field.trim());

        return changedFieldsArray.map(toHumanReadable).join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
      const showtext = formatChangedFields(changedFieldsText);
      const data = {
        business_name: formData.businessName,
        founder_name: formData.founderName,
        date_of_birth: formData.dob,
        business_email: formData.email,
        business_registration_number: formData.businessRegistrationNumber,
        city: formData.city,
        state: formData.state,
        pin_code: formData.postalCode,
        lat: formData.lat,
        long: formData.long,
        country: formData.country,
        phone_number: formData.phoneNumber,
        isFromUpdateApi: true,
        text: `${businessname} has changed ${showtext} in his profile`,
      };

      post(`${apiurl}startups/${Userid}?updateBy=user`, data)
        .then(response => {
          if (!response.data.status) {
            toast.error(response?.data?.message);
          } else {
            const data1 = {
              minimum_raise_description:
              cleanRevenue(formData.minRaiseDescription),
              maximum_raise_description:
              cleanRevenue(formData.maxRaiseDescription),
              website: formData.websiteURL,
              year_of_establish: formData.yearEstablished,
              industry_sector: formData.industrySector,
              stage_of_business: formData.stageOfBusiness,
              pitch_deck: formData.picthDeck,
              funding_need: formData.fundingNeeds,
              isFromUpdateApi: true,
              // intellectual_property: formData.intellectualProperty,
              // tradeMark: formData.trademarkText,
              // text:`${businessname} has changed ${changedFieldsText} in his profile`
            };

            post(`${apiurl}startups/createStartupone/${Userid}`, data1)
              .then(response => {
                if (!response.data.status) {
                  toast.error(response?.data?.message);
                } else {
                  toast.success("Profile Updated ");
                  setUpdateIdentityPopup(false);
                }
              })
              .catch(error => {
                console.log(error.response);
              });
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const additionalQuestionsSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (
      !additionalQuestions.revenueModel ||
      !additionalQuestions.revenueModel[0]
    ) {
      newErrors.revenueModel = "Revenue Model  is required.";
    }
    if (
      !additionalQuestions.revenueModelother &&
      additionalQuestions?.revenueModel?.includes("Other")
    ) {
      newErrors.revenueModelother = "Revenue Model Details are required.";
    }
    // if (!additionalQuestions.marketAnalysis) {
    //   newErrors.marketAnalysis = "Market Analysis is required.";
    // }
    // if (!additionalQuestions.teamBackground) {
    //   newErrors.teamBackground = "Team Background is required.";
    // }

 
    if (!additionalQuestions.legalStructure) {
      newErrors.legalStructure = "Legal Structure is required.";
    }
    if (
      !additionalQuestions.legalStructureother &&
      additionalQuestions.legalStructure === "Other"
    ) {
      newErrors.legalStructureother = "Legal Structure is required.";
    }
 
    if (
      !additionalQuestions.pendingLitigationnotes &&
      additionalQuestions.pendingLitigation === "Yes"
    ) {
      newErrors.pendingLitigationnotes = "Pending Litigation Text is required.";
    }
    if (!additionalQuestions.intellectualProperty) {
      newErrors.intellectualProperty = "Intellectual Property is required.";
    }
    if (
      !additionalQuestions.trademarkText &&
      additionalQuestions.intellectualProperty === "Yes"
    ) {
      newErrors.trademarkText = "Intellectual Property text is required.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(additionalQuestions).forEach(key => {
        if (additionalQuestions[key] !== initialadditionalquestions[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = field => {
        return field
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, str => str.toUpperCase());
      };

      const formatChangedFields = changedFieldsString => {
        const changedFieldsArray = changedFieldsString
          .split(",")
          .map(field => field.trim());

        return changedFieldsArray.map(toHumanReadable).join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
      const showtext = formatChangedFields(changedFieldsText);

      const data = {
        revenue_model: JSON.stringify(additionalQuestions.revenueModel),
        market_analist: additionalQuestions.marketAnalysis
          ? additionalQuestions.marketAnalysis
          : "",
        // previous_funding: additionalQuestions.previousFunding,
        // team_backGround: additionalQuestions.teamBackground,
        equity_allocation: additionalQuestions.equiltyallocation,
        legal_structure: additionalQuestions.legalStructure,
        // support_needs: additionalQuestions.supportNeeds,
        note: additionalQuestions.fundingDetails,
        other: additionalQuestions.supportNeedOthers,
        otherrevnue: additionalQuestions.revenueModelother,
        otherlegal: additionalQuestions.legalStructureother,
        text: `${businessname} has changed ${showtext} in his profile`,
        isFromUpdateApi: true,
        pending_litigations: additionalQuestions.pendingLitigation,
        pending_litigations_notes:
          additionalQuestions.pendingLitigation === "Yes"
            ? additionalQuestions.pendingLitigationnotes
            : "",
        // featured_investor_description: additionalQuestions.featuredInvestors
        //   ? additionalQuestions.featuredInvestors
        //   : "",
        intellectual_property: additionalQuestions.intellectualProperty,
        tradeMark: additionalQuestions.trademarkText,
      };

      post(
        `${apiurl}startups/additionalQuestions/${Userid}?updateBy=user`,
        data
      )
        .then(response => {
          if (!response?.data?.status) {
            toast.error(response?.data?.message);
          } else {
            // const updatemodal = document.getElementById("update-additional");
            // updatemodal.classList.remove("pop-open");
            toast.success("Profile Updated ");
            setAdditionalQuestionsPopup(false);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const businessGrowthPopupSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!businessGrowthPopup.year1Revenue) {
      newErrors.year1Revenue = "Year 1 Revenue is required.";
    }

   if (
      parseFloat(businessGrowthPopup.year1GrossProfitMargin) < 0 ||
      parseFloat(businessGrowthPopup.year1GrossProfitMargin) > 100
    ) {
      newErrors.year1GrossProfitMargin =
        "Year 1 Gross Profit Margin value should be 0 to 100";
    }
  if (
      parseFloat(businessGrowthPopup.year2GrossProfitMargin) < 0 ||
      parseFloat(businessGrowthPopup.year2GrossProfitMargin) > 100
    ) {
      newErrors.year1GrossProfitMargin =
        "Year 2 Gross Profit Margin value should be 0 to 100";
    }
    if (
      parseFloat(businessGrowthPopup.year3GrossProfitMargin) < 0 ||
      parseFloat(businessGrowthPopup.year3GrossProfitMargin) > 100
    ) {
      newErrors.year3GrossProfitMargin =
        "Year 3 Gross Profit Margin value should be 0 to 100";
    }
    if (!businessGrowthPopup.year1EBIDTAMargin) {
      newErrors.year1EBIDTAMargin = "Year 1 EBIDTA Margin is required.";
    } else if (
      parseFloat(businessGrowthPopup.year1EBIDTAMargin) < 0 ||
      parseFloat(businessGrowthPopup.year1EBIDTAMargin > 100)
    ) {
      newErrors.year1EBIDTAMargin =
        "Year 1 EBIDTA Margint value should be 0 to 100";
    }
 if (
      parseFloat(businessGrowthPopup.year2EBIDTAMargin) < 0 ||
      parseFloat(businessGrowthPopup.year2EBIDTAMargin > 100)
    ) {
      newErrors.year2EBIDTAMargin =
        "Year 2 EBIDTA Margint value should be 0 to 100";
    }
 if (
      parseFloat(businessGrowthPopup.year3EBIDTAMargin) < 0 ||
      parseFloat(businessGrowthPopup.year3EBIDTAMargin > 100)
    ) {
      newErrors.year3EBIDTAMargin =
        "Year 3 EBIDTA Margint value should be 0 to 100";
    }
    if (!businessGrowthPopup.CAC) {
      newErrors.CAC = "Customer Acquisition Cost (CAC) is required.";
    }
    if (!businessGrowthPopup.LTV) {
      newErrors.LTV = "Lifetime Value of a Customer (LTV) is required.";
    }
    if (!businessGrowthPopup.burnRate) {
      newErrors.burnRate = "Burn Rate is required.";
    }
    if (!businessGrowthPopup.tam) {
      newErrors.tam = "Total addressable Market is required.";
    }
    if (!businessGrowthPopup.activeUsers) {
      newErrors.activeUsers = "Number of Active Users/Clients is required.";
    }
    if (!businessGrowthPopup.fundingSought) {
      newErrors.fundingSought = "Funding Allocation is required.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(businessGrowthPopup).forEach(key => {
        if (businessGrowthPopup[key] !== initialbusinessgrowth[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = field => {
        return field
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, str => str.toUpperCase());
      };

      const formatChangedFields = changedFieldsString => {
        const changedFieldsArray = changedFieldsString
          .split(",")
          .map(field => field.trim());

        return changedFieldsArray.map(toHumanReadable).join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
      const showtext = formatChangedFields(changedFieldsText);
      const data = {
        annual_revenue_year1: cleanRevenue(businessGrowthPopup.year1Revenue),
        annual_revenue_year2: cleanRevenue(businessGrowthPopup.year2Revenue),
        annual_revenue_year3: cleanRevenue(businessGrowthPopup.year3Revenue),
        gross_profit_margin_year1: businessGrowthPopup.year1GrossProfitMargin,
        gross_profit_margin_year2: businessGrowthPopup.year2GrossProfitMargin,
        gross_profit_margin_year3: businessGrowthPopup.year3GrossProfitMargin,
        ebidta_marginyear1: businessGrowthPopup.year1EBIDTAMargin,
        ebidta_marginyear2: businessGrowthPopup.year2EBIDTAMargin,
        ebidta_marginyear3: businessGrowthPopup.year3EBIDTAMargin,
        cac: cleanRevenue(businessGrowthPopup.CAC),
        liv: cleanRevenue(businessGrowthPopup.LTV),
        burn_rate: businessGrowthPopup.burnRate,
        funding_sougth: businessGrowthPopup.fundingSought,
        noOfActiceUser: businessGrowthPopup.activeUsers,
        tam:businessGrowthPopup.tam,
        text: `${businessname} has changed ${showtext} in his profile`,
        isFromUpdateApi: true
      };

      post(`${apiurl}startups/businessGrowth/${Userid}?updateBy=user`, data)
        .then(response => {
          if (!response.data.status) {
            toast.error(response.data.message);
          } else {
            toast.success("Profile Updated ");
            setEconomicsandBusinessGrowthPopup(false);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const aboutProductSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!aboutProduct.productImage) {
      newErrors.productImage = "Product Image is required.";
    }
    // if (!aboutProduct.productDescription) {
    //   newErrors.productDescription = "Product Description is required.";
    // }
    if (aboutProduct.facebook) {
      if (!/^https?:\/\/.*/i.test(aboutProduct.facebook)) {
        newErrors.facebook =
          "Facebook URL must start with https:// or http:// ";
      }
    }
    if (aboutProduct.twitter) {
      if (!/^https?:\/\/.*/i.test(aboutProduct.twitter)) {
        newErrors.twitter = "Twitter URL must start with https:// or http:// ";
      }
    }
    if (aboutProduct.linkedin) {
      if (!/^https?:\/\/.*/i.test(aboutProduct.linkedin)) {
        newErrors.linkedin =
          "Linkedin URL must start with https:// or http:// ";
      }
    }
    if (aboutProduct.youtube) {
      if (!/^https?:\/\/.*/i.test(aboutProduct.youtube)) {
        newErrors.youtube = "Youtube URL must start with https:// or http:// ";
      }
    }
    if (aboutProduct.website) {
      if (!/^https?:\/\/.*/i.test(aboutProduct.website)) {
        newErrors.website = "Website URL must start with https:// or http:// ";
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(aboutProduct).forEach(key => {
        if (aboutProduct[key] !== initialaboutproduct[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = field => {
        return field
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, str => str.toUpperCase());
      };

      const formatChangedFields = changedFieldsString => {
        const changedFieldsArray = changedFieldsString
          .split(",")
          .map(field => field.trim());

        return changedFieldsArray.map(toHumanReadable).join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
      const showtext = formatChangedFields(changedFieldsText);
      const formdata = new FormData();
      formdata.append("product_image", aboutProduct.productImage);
      // formdata.append("product_details", aboutProduct.productDescription);
      formdata.append("video", aboutProduct.productVideo);
      formdata.append(
        "twitter",
        aboutProduct.twitter != "" ? aboutProduct.twitter : ""
      );
      formdata.append(
        "facebook",
        aboutProduct.facebook != "" ? aboutProduct.facebook : ""
      );
      formdata.append(
        "linkedin",
        aboutProduct.linkedin != "" ? aboutProduct.linkedin : ""
      );
      formdata.append(
        "youtube",
        aboutProduct.youtube != "" ? aboutProduct.youtube : ""
      );
      formdata.append(
        "logo",
        aboutProduct.productLogo != "" ? aboutProduct.productLogo : ""
      );
      formdata.append(
        "website",
        aboutProduct.website != "" ? aboutProduct.website : ""
      );
      formdata.append(
        "instagram",
        aboutProduct.instagram != "" ? aboutProduct.instagram : ""
      );
      formdata.append(
        "text",
        `${businessname} has changed ${showtext} in his profile`
      );
      formdata.append("isFromUpdateApi",true)
      post(
        `${apiurl}startups/aboutBusiness/${Userid}?updateBy=user`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(response => {
          if (!response.data.status) {
            toast.error(response.data.message);
          } else {
            toast.success("Profile Updated ");
            getStartupData();
            setAboutProductPopup(false);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const getTeamMembers = () => {
    get(`${apiurl}startups/viewMember/${Userid}`)
      .then(response => {
        setTeamMembers(response?.data?.data);
        setEditTeamMembers({
          memberPhoto:
            response.data?.data?.photo != null
              ? response.data?.data?.photo
              : "",
          memberDesignation:
            response.data?.data?.designation != null
              ? response.data?.data?.designation
              : "",
          memberName:
            response.data?.data?.name != null ? response.data?.data?.name : "",
          memberBio:
            response.data?.data?.bio != null ? response.data?.data?.bio : "",
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getStartupData = () => {
    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        Setloading(false);
        const startupData = response?.data?.startups;

        function formatDate(dateString) {
          if (!dateString) return "";
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        }
        // const date =
        //   startupData.year_of_establish != null
        //     ? new Date(startupData.year_of_establish)
        //     : "";
        // const formattedDate =
        //   date != "" ? date.toISOString().split("T")[0] : "";
        const yearString = startupData.year_of_establish;
        const year = parseInt(yearString);

        const date2 = new Date(year, 0);

        const formattedYear = date2.getFullYear();
        setBusinessname(startupData.name);
        setStatus({
          ...status,
          status: startupData?.user_id?.status,
          contract: startupData?.user_id?.contractStatus,
        });
        // console.log(startupData,startupData.phone_number)
        setInitialFormDataState({
          businessName: startupData.business_name,
          founderName: startupData.founder_name,
          dob: formatDate(startupData?.date_of_birth),
          email: startupData.business_email,
          phoneNumber: startupData.phone_number,
          businessRegistrationNumber: startupData.business_registration_number,
          city: startupData.city,
          state: startupData.state,
          postalCode: startupData.pin_code,
          lat: startupData.lat,
          long: startupData.long,
          country: startupData.country,
          websiteURL: startupData?.website,
          yearEstablished: formattedYear,
          industrySector: startupData?.industry_sector,
          stageOfBusiness: startupData?.stage_of_business,
          picthDeck: startupData?.pitch_deck,
          fundingNeeds: startupData?.funding_need,
          intellectualProperty: startupData?.intellectual_property,
          trademarkText: startupData?.tradeMark,
          logo: startupData?.logo,
          minRaiseDescription: startupData?.minimum_raise_description!=null?formatCurrency(startupData?.minimum_raise_description):"",
          maxRaiseDescription: startupData?.maximum_raise_description!=null?formatCurrency(startupData?.maximum_raise_description):"",
        });

        setFormData({
          businessName: startupData.business_name,
          founderName: startupData.founder_name,
          dob: formatDate(startupData?.date_of_birth),
          email: startupData.business_email,
          phoneNumber: startupData.phone_number,
          businessRegistrationNumber: startupData.business_registration_number,
          city: startupData.city,
          state: startupData.state,
          postalCode: startupData.pin_code,
          lat: startupData.lat,
          long: startupData.long,
          country: startupData.country,
          websiteURL: startupData?.website,
          yearEstablished: formattedYear,
          industrySector: startupData?.industry_sector,
          stageOfBusiness: startupData?.stage_of_business,
          picthDeck: startupData?.pitch_deck,
          fundingNeeds: startupData?.funding_need,
          intellectualProperty: startupData?.intellectual_property,
          trademarkText: startupData?.tradeMark,
          logo: startupData?.logo,
          minRaiseDescription: startupData?.minimum_raise_description!=null?formatCurrency(startupData?.minimum_raise_description):"",
          maxRaiseDescription: startupData?.maximum_raise_description!=null?formatCurrency(startupData?.maximum_raise_description):"",
        });
        setAdditionalQuestions({
          revenueModel: isJsonString(startupData?.revenue_model)
            ? JSON.parse(startupData?.revenue_model)
            : startupData?.revenue_model,
          marketAnalysis: startupData?.market_analist,
          equiltyallocation: startupData?.equity_allocation,
          gstin: startupData?.gstin,
          // previousFunding: startupData?.previous_funding,
          // businessGoals: startupData?.business_goal,
          legalStructure: startupData?.legal_structure,
          // supportNeeds: startupData?.support_needs,
          fundingDetails: startupData?.note,
          // supportNeedOthers: startupData?.other,
          revenueModelother: startupData?.otherrevnue,
          legalStructureother: startupData?.otherlegal,
          pendingLitigation: startupData?.pending_litigations,
          pendingLitigationnotes: startupData?.pending_litigations_notes,
          // teamBackground:startupData?.team_backGround,
          // featuredInvestors: startupData?.featured_investor_description,
          intellectualProperty: startupData?.intellectual_property,
          trademarkText: startupData?.tradeMark,
        });
        setBusinessGrowthPopup({
          year1Revenue: formatCurrency(startupData?.annual_revenue_year1),
          year2Revenue: formatCurrency(startupData?.annual_revenue_year2),
          year3Revenue: formatCurrency(startupData?.annual_revenue_year3),
          year1GrossProfitMargin: startupData?.gross_profit_margin_year1,
          year2GrossProfitMargin: startupData?.gross_profit_margin_year2,
          year3GrossProfitMargin: startupData?.gross_profit_margin_year3,
          year1EBIDTAMargin: startupData?.ebidta_marginyear1,
          year2EBIDTAMargin: startupData?.ebidta_marginyear2,
          year3EBIDTAMargin: startupData?.ebidta_marginyear3,
          CAC: formatCurrency(startupData?.cac),
          LTV: formatCurrency(startupData?.liv),
          tam:startupData?.tam,
          burnRate: startupData?.burn_rate,
          fundingSought: startupData?.funding_sougth,
          year: startupData?.createdAt,
          activeUsers: startupData?.noOfActiceUser,
          minimumRaise: formatCurrency(startupData?.minimum_raise_description),
          maximumRaise: formatCurrency(startupData?.maximum_raise_description),
        });
        setAboutProduct({
          productImage: startupData?.product_image,
          // productDescription: startupData?.product_details,
          productVideo: startupData?.video,
          productLogo: startupData?.logo,
          website: startupData?.website,
          twitter: startupData?.twitter,
          facebook: startupData?.facebook,
          linkedin: startupData?.linkedin,
          youtube: startupData?.youtube,
          instagram: startupData?.instagram,
        });
        setAboutFounder({
          founderPhoto: startupData?.founderPhoto,
          founderDesignation:
            startupData?.founderDesignation != "undefined"
              ? startupData?.founderDesignation
              : "",
          founderDescription:
            startupData?.founderDescription != "undefined"
              ? startupData?.founderDescription
              : "",
          founderName: startupData?.founder_name,
          product_image: startupData?.product_image,
        });

        setIdProof(startupData);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [closeEditpopup, SetCloseEditpopup] = useState(false);
  useEffect(() => {
    if (closeEditpopup) {
      setInitialFormDataState(formData);
    } else {
      setInitialFormDataState();
    }
  }, [closeEditpopup]);
  useEffect(() => {
    if (additionalQuestionsPopup) {
      setInitialAdditionalquestions(additionalQuestions);
    } else {
      setInitialAdditionalquestions();
    }
  }, [additionalQuestionsPopup]);
  useEffect(() => {
    if (economicsandBusinessGrowthPopup) {
      setInitialBusinessgrowth(businessGrowthPopup);
    } else {
      setInitialBusinessgrowth();
    }
  }, [economicsandBusinessGrowthPopup]);
  useEffect(() => {
    if (aboutProductPopup) {
      setInitialAboutproduct(aboutProduct);
    } else {
      setInitialAboutproduct();
    }
  }, [aboutProductPopup]);
  useEffect(() => {
    if (updateMemberPopup) {
      setInitialediTeammember(editTeamMembers);
    } else {
      setInitialediTeammember();
    }
  }, [updateMemberPopup]);
  useEffect(() => {
    if (openFounderpopup) {
      SetInitialaboutFounder(aboutFounder);
    } else {
      SetInitialaboutFounder();
    }
  }, [openFounderpopup]);
  const postAddMembers = e => {
    e.preventDefault();

    const newErrors = {};
    if (!addMember.photo) {
      newErrors.photo = "Member photo is required.";
    }
    if (!addMember.bio) {
      newErrors.bio = "Bio is required.";
    }
    if (!addMember.name) {
      newErrors.name = "Member Name is required.";
    }
    if (!addMember.designation) {
      newErrors.designation = "Member designation is required.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const filedata = new FormData();
      filedata.append("photo", addMember.photo);
      filedata.append("bio", addMember.bio);
      filedata.append("name", addMember.name);
      filedata.append("designation", addMember.designation);
      filedata.append(
        "text",
        `${businessname} has addnewMember to his team in his profile`
      );

      post(`${apiurl}startups/addMember/${Userid}?updateBy=user`, filedata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response.data.status) {
            toast.error(response.data.message);
          } else {
            toast.success("Member Added Successfully ");
            getTeamMembers();
            setAddMember({
              photo: "",
              bio: "",
              name: "",
              designation: "",
            });
            setAddMemberPopup(false);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const editFounder = e => {
    e.preventDefault();
    const newErrors = {};
    if (!aboutFounder.founderPhoto) {
      newErrors.founderPhoto = "Founder photo is required.";
    }
    if (!aboutFounder.founderDescription) {
      newErrors.founderDescription = "Founder Description is required";
    }
    if (!aboutFounder.founderName) {
      newErrors.founderName = "Founder Name is required.";
    }
    if (!aboutFounder.founderDesignation) {
      newErrors.founderDesignation = "Founder designation is required.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(aboutFounder).forEach(key => {
        if (aboutFounder[key] !== initialaboutFounder[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = field => {
        return field
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, str => str.toUpperCase());
      };

      const formatChangedFields = changedFieldsString => {
        const changedFieldsArray = changedFieldsString
          .split(",")
          .map(field => field.trim());

        return changedFieldsArray.map(toHumanReadable).join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
      const showtext = formatChangedFields(changedFieldsText);
      const filedata = new FormData();
      filedata.append("founderPhoto", aboutFounder.founderPhoto);
      filedata.append("founderDescription", aboutFounder.founderDescription);
      filedata.append("founder_name", aboutFounder.founderName);
      filedata.append("founderDesignation", aboutFounder.founderDesignation);
      filedata.append(
        "text",
        `${businessname} has changed ${showtext} in his profile`
      );
      post(
        `${apiurl}startups/updateFounders/${Userid}?updateBy=user`,
        filedata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(response => {
          if (!response.data.status) {
            toast.error(response.data.message);
          } else {
            toast.success("Update Successfully ");
            getStartupData();
            setOpenFounderpopup(false);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const postEditMembers = e => {
    e.preventDefault();
    const newErrors = {};
    if (!editTeamMembers.memberPhoto) {
      newErrors.memberPhoto = "Member photo is required.";
    }
    if (!editTeamMembers.memberBio) {
      newErrors.memberBio = " Member Bio is required.";
    }
    if (!editTeamMembers.memberName) {
      newErrors.memberName = "Member Name is required.";
    }
    if (!editTeamMembers.memberDesignation) {
      newErrors.memberDesignation = "Member designation is required.";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(editTeamMembers).forEach(key => {
        if (editTeamMembers[key] !== initialeditteammember[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = field => {
        return field
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, str => str.toUpperCase());
      };

      const formatChangedFields = changedFieldsString => {
        const changedFieldsArray = changedFieldsString
          .split(",")
          .map(field => field.trim());

        return changedFieldsArray.map(toHumanReadable).join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
      const showtext = formatChangedFields(changedFieldsText);
      const filedata = new FormData();
      filedata.append("photo", editTeamMembers.memberPhoto);
      filedata.append("bio", editTeamMembers.memberBio);
      filedata.append("name", editTeamMembers.memberName);
      filedata.append("designations", editTeamMembers.memberDesignation);
      filedata.append(
        "text",
        `${businessname} has changed ${showtext} in his profile`
      );

      post(
        `${apiurl}startups/editMember/${editTeamMembers.memberid}?updateBy=user`,
        filedata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(response => {
          if (!response?.data?.status) {
            toast.error(response?.data.message);
          } else {
            toast.success("Member Updated ");
            getTeamMembers();
            setUpdateMemberPopup(false);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const deleteMembers = _id => {
    get(`${apiurl}startups/deleteMember/${_id}`)
      .then(response => {
        setTeamMembers(teamMembers.filter(member => member._id !== _id));
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Member deleted");
          setIsPopupOpen(false);
        }
        // if (response.status === 200) {

        // }
      })
      .catch(error => {
        console.error("There was an error deleting the item!", error);
      });
  };
  useEffect(() => {
    getTeamMembers();
    getStartupData();
  }, [additionalQuestionsPopup]);
  const date = new Date(businessGrowthPopup.year);
  const revenueYear1 = date.getFullYear();
  const revenueYear2 = revenueYear1 - 1;
  const revenueYear3 = revenueYear2 - 1;
  const cleanRevenue = revenueString => {
    // Remove ₹ symbol and commas
    return Number(revenueString?.toString()?.replace(/₹|,/g, ""));
  };
  const yearRevenue1 = cleanRevenue(businessGrowthPopup.year1Revenue);
  const yearRevenue2 = cleanRevenue(businessGrowthPopup.year2Revenue);
  const yearRevenue3 = cleanRevenue(businessGrowthPopup.year3Revenue);

  // Calculate the total revenue
  const totalYearRevenue = yearRevenue1 + yearRevenue2 + yearRevenue3;
  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (userType != null) {
      if (userType === "Investor") {
        navigate("/");
      }
    }
  }, []);
  // Utility function to extract src

  function extractSrcFromIframe(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const iframe = doc.querySelector("iframe");
    return iframe ? iframe.src : html;
  }
  const iframeHtml = aboutProduct.productVideo;
  // console.log(iframeHtml)
  const videoSrc = extractSrcFromIframe(iframeHtml);
  function formatDate(dateString) {
    if (!dateString) return "";

    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit",
      // year: "numeric",
    });
  }

  const deleteproduct = () => {
    // console.log("delete");
    post(`${apiurl}startups/deleteProductImage/${Userid}`)
      .then(response => {
        // console.log(response);
        if (!response?.data?.status) {
          toast.error(response?.data.message);
        } else {
          setAboutProduct(prev => ({
            ...prev,

            productImage: "",
          }));
          // setAboutProduct({
          //   productImage:""})
          toast.success(response?.data.message);
        }
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const profilereSubmit = () => {
    post(`${apiurl}admin/giveresubmitToInvestor/${Userid}`)
      .then(res => {
        if (!res.data.status) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(err => {});
  };

  const UpdateImage = e => {
    e.preventDefault();
    let url = "";
    let key = "";
    if (editType == "idproof") {
      url = "startups/changeIdproof";
      key = "id_proof";
    } else if (editType == "addressproof") {
      url = "startups/changeAddressproof";
      key = "address_proof";
    } else if (editType == "companyverification") {
      url = "startups/changeCompanyVerification";
      key = "company_verification";
    } else if (editType == "taxinformation") {
      url = "startups/changeTaxInformation";
      key = "bank_statement";
    }
    let updatePersonalData = new FormData();
    updatePersonalData.append(key, editImage?.imageFile);
    try {
      axios
        .post(`${apiurl}${url}/${Userid}`, updatePersonalData, headers())
        .then(res => {
          if (res.data.status) {
            setEditPopup(false);
            setEditImage({ imageFile: null });
            toast.success(res.data.message);
            window.location.reload();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch(err => {
          setEditPopup(false);
          setEditImage({ imageFile: null });

          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangedocument = (e, setFile) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSizeInBytes) {
      toast.error("File size exceeds 5MB.");
      return;
    }
    setFile({ [e.target?.name]: file });
    setTimeout(() => {
      e.target.value = null;
    }, 1000);
  };

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Startup profile</title>
      </Helmet>
      {editPopup && (
        <div class="popup-container-custom">
          <div
            className={editPopup ? "popup-wrapper pop-open" : "popup-wrapper"}
            id="document-update"
          >
            <div className="top-head-box flex space-between align-center">
              <h5>
                Update Your{" "}
                {documenttype === "1"
                  ? "Id proof's"
                  : documenttype === "2"
                  ? "Address proof's"
                  : documenttype === "3"
                  ? "Company verification"
                  : documenttype === "4"
                  ? "Tax information"
                  : ""}
              </h5>
              <div
                onClick={() => {
                  setEditPopup(false);
                  setImage("");
                  setEditImage({ imageFile: null });
                  setEditType("");
                }}
                className="pop-cross-btn"
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <div className="dotted-box">
                {image && <ImaageDocPDF src={image} />}
                {editImage &&
                  editImage?.imageFile &&
                  (editImage?.imageFile?.type == "image/jpeg" ||
                  editImage?.imageFile?.type == "image/jpg" ||
                  editImage?.imageFile?.type == "image/png" ? (
                    <img
                      alt="img"
                      src={URL.createObjectURL(editImage?.imageFile)}
                    ></img>
                  ) : (
                    <iframe
                      title="iframe"
                      src={URL.createObjectURL(editImage?.imageFile)}
                    />
                  ))}
                <div className="icon flex">
                  <div className="edit-pen startupdocumentedit">
                    <img src="src/images/edit-pen-img.svg" alt="" />
                    <label class="fileImage">
                      <input
                        onChange={e => {
                          handleChangedocument(e, setEditImage);
                        }}
                        name="imageFile"
                        className="fileImage"
                        type="file"
                      />
                      <span>Choose File</span>
                    </label>
                  </div>
                  {/* <div className="delete-pen">
                  <img src="src/images/delete-pen-img.svg" alt="" />
                </div> */}
                </div>
              </div>
              <div className="form-group btngroup space-end full-width flex mt-20">
                <button
                  onClick={() => {
                    setEditPopup(false);
                    setImage("");
                    setEditType("");
                    setEditImage({ imageFile: null });
                  }}
                  className="cta-btn border"
                >
                  Cancel
                </button>
                <button onClick={e => UpdateImage(e)} className="cta-btn">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {Popup && (
        <div class="popup-container-custom">
          <div
            className={Popup ? "popup-wrapper pop-open" : "popup-wrapper"}
            id="document-view"
          >
            <div className="top-head-box flex space-between align-center">
              <h5>
                Your{" "}
                {documenttype === "1"
                  ? "Id proof's"
                  : documenttype === "2"
                  ? "Address proof's"
                  : documenttype === "3"
                  ? "Company verification"
                  : documenttype === "4"
                  ? "Tax information"
                  : ""}
              </h5>
              <div onClick={() => setPopup(false)} className="pop-cross-btn">
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <ImaageDocPDF src={image} />
            </div>
          </div>
        </div>
      )}

      {updateIdentityPopup && (
        <div className="popup-container-custom">
          {/* <div className="popup-wrapper-sec"> */}
          <div className="popup-wrapper business-verification pop-open">
            <div className="top-head-box flex space-between align-center">
              <h5>Update Identity and Business Verification</h5>
              <div
                className="pop-cross-btn "
                onClick={() => {
                  setUpdateIdentityPopup(false);
                  SetCloseEditpopup(true);
                }}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup inner-popup_2">
              <form action="">
                <div className="form-wrapper flex space-between">
                  <div className="form-group half">
                    <label htmlFor="">Business Name</label>
                    <input
                      type="text"
                      name="businessName"
                      placeholder="Online Shopping"
                      className="form-control"
                      value={formData.businessName}
                      onChange={handleChange}
                    />
                    {errors.businessName && (
                      <p className="error-msg">{errors.businessName}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Founder's Name</label>
                    <input
                      type="text"
                      name="founderName"
                      placeholder="John Doe"
                      className="form-control"
                      value={formData.founderName}
                      onChange={handleChange}
                    />
                    {errors.founderName && (
                      <p className="error-msg">{errors.founderName}</p>
                    )}
                  </div>
                  <div className="form-group half readonlyinput">
                    <label htmlFor="">
                      Date of Birth (for age verification)
                    </label>
                    <input
                      type="date"
                      name="dob"
                      placeholder="mm/dd/yyyy"
                      className="form-control"
                      value={formData.dob}
                      onChange={handleChange}
                      readOnly
                    />

                    {errors.dob && <p className="error-msg">{errors.dob}</p>}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Business Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="example@udharaa.com"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Phone Number<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="(9999) 99999999"
                      className="form-control"
                      name="phoneNumber"
                      maxLength={16}
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {errors.phoneNumber && (
                      <p className="error-msg">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Business Registration Number (if applicable)
                    </label>
                    <input
                      type="text"
                      placeholder="00UD0"
                      className="form-control"
                      name="businessRegistrationNumber"
                      value={formData.businessRegistrationNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group full">
                    <h5>Physical Business Address</h5>
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Country</label>

                    <select
                      name="country"
                      className="form-control"
                      value={formData.country}
                      onChange={handleChange}
                    >
                      <option>
                        {formData.country ? formData.country : ""}
                      </option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <p className="error-msg">{errors.country}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {errors.city && <p className="error-msg">{errors.city}</p>}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                    />
                    {errors.state && (
                      <p className="error-msg">{errors.state}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Postal Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                      onMouseLeave={getLonglat}
                    />
                    {errors.postalCode && (
                      <p className="error-msg">{errors.postalCode}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Website URL (if available)</label>
                    <input
                      type="text"
                      placeholder="https://"
                      className="form-control"
                      name="websiteURL"
                      value={formData.websiteURL}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group half readonlyinput">
                    <label htmlFor="">Year Established</label>
                    <input
                      type="text"
                      name="yearEstablished"
                      placeholder="yyyy"
                      className="form-control"
                      value={formData.yearEstablished}
                      onChange={handleChange}
                      readOnly
                    />
                    {errors.yearEstablished && (
                      <p className="error-msg">{errors.yearEstablished}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Industry Sector</label>
                    <select
                      name="industrySector"
                      className="form-control"
                      value={formData.industrySector}
                      onChange={handleChange}
                    >
                      <option value="--- Select ---">--- Select ---</option>
                      {Industries?.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                    {errors.industrySector && (
                      <p className="error-msg">{errors.industrySector}</p>
                    )}
                  </div>
                  <div className="form-group full">
                    <h5>Stage of Business</h5>
                  </div>
                  <div className="form-group custom-radio-btn half">
                    <p>What stage is your business currently in?</p>
                    <ul>
                      <li>
                        <label className="flex">
                          Idea
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Idea"
                            checked={
                              formData.stageOfBusiness == "Idea" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Pre-revenue
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Pre-revenue"
                            checked={
                              formData.stageOfBusiness == "Pre-revenue"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Revenue-generating
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Revenue-generating"
                            checked={
                              formData.stageOfBusiness == "Revenue-generating"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Scaling
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Scaling"
                            checked={
                              formData.stageOfBusiness == "Scaling"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.stageOfBusiness && (
                      <p className="error-msg">{errors.stageOfBusiness}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Business Description</label>
                    <textarea
                      name="picthDeck"
                      className="form-control"
                      placeholder="Enter your text here (maximum 1500 characters)"
                      value={formData.picthDeck}
                      onChange={handleChange}
                    ></textarea>
                    {errors.picthDeck && (
                      <p className="error-msg">{errors.picthDeck}</p>
                    )}
                  </div>
                  <div className="form-group full">
                    <h5>
                      Funding Needs
                      <span className="startupprofilespan">
                        (Mention amount and equity dilution)
                      </span>
                      <span className="required-sign">*</span>
                    </h5>
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      What is the funding amount you are seeking?
                    </label>

                    <input
                      type="text"
                      name="fundingNeeds"
                      placeholder="exp: INR 50 lakhs for a 10% equity stake."
                      className="form-control"
                      value={formData.fundingNeeds}
                      onChange={handleChange}
                    />
                    {errors.fundingNeeds && (
                      <p className="error-msg">{errors.fundingNeeds}</p>
                    )}
                  </div>
                  <div className="form-group bordertop">
                    <h5 className="mb-12">Minimum Raise <span className="required-sign">*</span></h5> 
                
                    <input
                      type="text"
                      name="minRaiseDescription"
                      id="minRaiseDescription"
                      placeholder="Amount Only"
                      className="form-control"
                      value={formData.minRaiseDescription}
                      onChange={handleChange}
                      readOnly
                    ></input>
                    {errors.minRaiseDescription && (
                      <p className="error-msg">{errors.minRaiseDescription}</p>
                    )}
                    <p>
                      *Specify the minimum amount willing to be accepted in this
                      raise.
                    </p>
                  </div>
                  <div className="form-group bordertop">
                    <h5 className="mb-12">Maximum Raise <span className="required-sign">*</span></h5>
                
                    <input
                      type="text"
                      name="maxRaiseDescription"
                      id="maxRaiseDescription"
                      placeholder="Amount Only"
                      className="form-control"
                      value={formData.maxRaiseDescription}
                      onChange={handleChange}
                      readOnly
                    ></input>
                    {errors.maxRaiseDescription && (
                      <p className="error-msg">{errors.maxRaiseDescription}</p>
                    )}
                    <p>
                      *Specify the Maximum amount willing to be accepted in this
                      raise.
                    </p>
                  </div>

                  {/* <div className="form-group custom-radio-btn">
                    <h5>
                      Intellectual Property{" "}
                      <span className="required-sign">*</span>
                    </h5>
                    <p>Do you have any patents or trademarks?</p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="intellectualProperty"
                            value="Yes"
                            checked={
                              formData.intellectualProperty == "Yes"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="intellectualProperty"
                            value="No"
                            checked={
                              formData.intellectualProperty == "No"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.intellectualProperty && (
                      <p className="error-msg">{errors.intellectualProperty}</p>
                    )}
                  </div>
                  {formData.intellectualProperty == "Yes" && (
                    <div className="form-group half">
                      <input
                        type="text"
                        name="trademarkText"
                        placeholder="Enter Trademarks"
                        className="form-control"
                        value={formData.trademarkText}
                        onChange={handleChange}
                      />
                      {errors.trademarkText && (
                        <p className="error-msg">{errors.trademarkText}</p>
                      )}
                    </div>
                  )} */}
                </div>
                <div className="btn-box flex space-between">
                  <div
                    className="pop-cross-btn custom-cross "
                    onClick={() => {
                      setUpdateIdentityPopup(false);
                      SetCloseEditpopup(true);
                    }}
                  >
                    <a className="cta-btn cancel-btn">Cancel</a>
                  </div>
                  {loadingbutton ? (
                    <span
                      className="cta-btn upate-btn"
                      style={{ cursor: "not-allowed" }}
                    >
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    </span>
                  ) : (
                    <a
                      href="#"
                      onClick={handleSubmit}
                      className="cta-btn upate-btn"
                    >
                      Update
                    </a>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* UPDATE ADDITIONAL QUESTION POPUP */}
      {additionalQuestionsPopup && (
        <div className="popup-container-custom">
          <div className="popup-wrapper pop-open custom-popup-width">
            <div className="top-head-box flex space-between align-center">
              <h5>Update Additional Questions</h5>
              <div
                className="pop-cross-btn"
                onClick={() => {
                  setAdditionalQuestionsPopup(false);
                }}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <form action="">
                <div className="form-wrapper-box flex space-between">
                  <div className="form-group">
                    <span style={{ fontWeight: 800 }}>Revenue Model</span>
                    <label htmlFor="">
                      What is your business’s revenue model?
                    </label>
                    <div className="custom-check-box">
                      <ul>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Subscription-based"
                                checked={
                                  additionalQuestions.revenueModel?.includes(
                                    "Subscription-based"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/Subscription-based.svg"
                                alt="img"
                              />
                              Subscription-based
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Transaction fee-based"
                                checked={
                                  additionalQuestions?.revenueModel?.includes(
                                    "Transaction fee-based"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/transaction-fee-based.svg"
                                alt="img"
                              />
                              Transaction fee-based
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Freemium"
                                checked={
                                  additionalQuestions?.revenueModel?.includes(
                                    "Freemium"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/Freemium.svg" alt="img" />
                              Freemium
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Advertising"
                                checked={
                                  additionalQuestions?.revenueModel?.includes(
                                    "Advertising"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/advertising.svg" alt="img" />
                              Advertising
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Direct sales"
                                checked={
                                  additionalQuestions?.revenueModel?.includes(
                                    "Direct sales"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/direct-sales.svg"
                                alt="img"
                              />
                              Direct sales
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Other"
                                checked={
                                  additionalQuestions?.revenueModel?.includes(
                                    "Other"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/other.svg" alt="img" />
                              <p>
                                Other <small>(Please specify)</small>
                              </p>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      {errors.revenueModel && (
                        <p className="error-msg">{errors.revenueModel}</p>
                      )}
                    </div>
                    {additionalQuestions?.revenueModel?.includes("Other") && (
                      <div className="form-group">
                        <input
                          type="text"
                          name="revenueModelother"
                          placeholder="Please specify"
                          className="form-control"
                          value={additionalQuestions.revenueModelother}
                          onChange={handleChange}
                        />
                        {errors.revenueModelother && (
                          <p className="error-msg">
                            {errors.revenueModelother}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <span style={{ fontWeight: 800 }}>Market Analysis</span>
                    <label htmlFor="">
                      Who are your main competitors, and what is your
                      competitive advantage?
                    </label>
                    <textarea
                      type="text"
                      name="marketAnalysis"
                      placeholder=""
                      className="form-control"
                      value={additionalQuestions.marketAnalysis}
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    />
                    {errors.marketAnalysis && (
                      <p className="error-msg">{errors.marketAnalysis}</p>
                    )}
                  </div>
                  {/* <div className="form-group half">
                    <span style={{ fontWeight: 800 }}>Team Background</span>
                    <span className="required-sign">*</span>
                    <label htmlFor="">
                      Please provide information about the key members of your
                      team and their roles.
                    </label>
                    <textarea
                      type="text"
                      name="teamBackground"
                      placeholder=""
                      className="form-control"
                      value={additionalQuestions.teamBackground}
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    />
                    {errors.teamBackground && (
                      <p className="error-msg">{errors.teamBackground}</p>
                    )}
                  </div> */}
             
                  <div className="form-group">
                    <h5 style={{ fontWeight: 800 }}>Equilty Allocation</h5>
                    <label htmlFor="">
                   Outline the disturbution of equity among Founders,Co-founders (if applicable), and potential investors (if applicable)
                      <span className="required-sign">*</span>
                    </label>
                    <textarea
                      name="equiltyallocation"
                      id="equiltyallocation"
                      placeholder=""
                      className="form-control"
                      value={additionalQuestions.equiltyallocation}
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    />
                    {errors.equiltyallocation && (
                      <p className="error-msg">{additionalQuestions.equiltyallocation}</p>
                    )}
                  </div>

              
                  <div className="form-group">
                    <span style={{ fontWeight: 800 }}>Legal Structure</span>
                    <p>What is the legal structure of your business?</p>
                    <div className="custom-check-box">
                      <ul>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Sole Proprietorship"
                                checked={
                                  additionalQuestions.legalStructure ==
                                  "Sole Proprietorship"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/sole-proprietorship.svg"
                                alt="img"
                              />
                              Sole Proprietorship
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Proprietorship"
                                checked={
                                  additionalQuestions.legalStructure ==
                                  "Proprietorship"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/partnership.svg" alt="img" />
                              Partnership
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Corporation"
                                checked={
                                  additionalQuestions.legalStructure ==
                                  "Corporation"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/corporation.svg" alt="img" />
                              Corporation
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Limited Liability Company (LLC)"
                                checked={
                                  additionalQuestions.legalStructure ==
                                  "Limited Liability Company (LLC)"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/llc.svg" alt="" />
                              Limited Liability Company (LLC)
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Other"
                                checked={
                                  additionalQuestions.legalStructure == "Other"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/other.svg" alt="img" />
                              Other
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      {errors.legalStructure && (
                        <p className="error-msg">{errors.legalStructure}</p>
                      )}
                    </div>
                    {additionalQuestions.legalStructure === "Other" && (
                      <div className="form-group">
                        <input
                          type="text"
                          name="legalStructureother"
                          placeholder="Please specify"
                          className="form-control"
                          value={additionalQuestions.legalStructureother}
                          onChange={handleChange}
                        />
                        {errors.legalStructureother && (
                          <p className="error-msg">
                            {errors.legalStructureother}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
               
                  <div className="form-group custom-radio-btn">
                    <p>
                      Any Pending Litigations?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="pendingLitigation"
                            value="Yes"
                            onChange={handleChange}
                            checked={
                              additionalQuestions.pendingLitigation == "Yes"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="pendingLitigation"
                            value="No"
                            onChange={handleChange}
                            checked={
                              additionalQuestions.pendingLitigation == "No"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.pendingLitigation && (
                      <p className="error-msg">{errors.pendingLitigation}</p>
                    )}
                  </div>
                  {additionalQuestions.pendingLitigation == "Yes" && (
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Pending Litigation"
                        className="form-control"
                        name="pendingLitigationnotes"
                        value={additionalQuestions.pendingLitigationnotes}
                        onChange={handleChange}
                      />
                      {errors.pendingLitigationnotes && (
                        <p className="error-msg">
                          {errors.pendingLitigationnotes}
                        </p>
                      )}
                    </div>
                  )}
                       <div className="form-group">
                    <label htmlFor="">
                      GST Identification Number (GSTIN) <b className="if-text">(If Applicable)</b>
                    </label>
                    <input
                      type="text"
                      placeholder="GST Identification Number (GSTIN)"
                      className="form-control"
                      name="gstin"
                      id="gstin"
                      value={additionalQuestions.gstin}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="form-group bordertop">
                    <h5 className="mb-12">Featured Investors</h5>
                    <label htmlFor="">
                      Description <b>(If applicable)</b>
                    </label>
                    <textarea
                      name="featuredInvestors"
                      placeholder="..."
                      className="form-control"
                      value={additionalQuestions.featuredInvestors}
                      onChange={handleChange}
                    ></textarea>
                    <p>
                      *List any angel investors, VC firms, and other noteworthy
                      investors.
                    </p>
                  </div> */}
                  <div className="form-group custom-radio-btn">
                    <h5>
                      Intellectual Property{" "}
                      <span className="required-sign">*</span>
                    </h5>
                    <p>Do you have any patents or trademarks?</p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="intellectualProperty"
                            value="Yes"
                            checked={
                              additionalQuestions.intellectualProperty == "Yes"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="intellectualProperty"
                            value="No"
                            checked={
                              additionalQuestions.intellectualProperty == "No"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.intellectualProperty && (
                      <p className="error-msg">{errors.intellectualProperty}</p>
                    )}
                  </div>
                  {formData.intellectualProperty == "Yes" && (
                    <div className="form-group half">
                      <input
                        type="text"
                        name="trademarkText"
                        placeholder="Enter Trademarks"
                        className="form-control"
                        value={additionalQuestions.trademarkText}
                        onChange={handleChange}
                      />
                      {errors.trademarkText && (
                        <p className="error-msg">{errors.trademarkText}</p>
                      )}
                    </div>
                  )}
                </div>
                <div className="btn-box flex space-between custom-flex">
                  <div className="pop-cross-btn custom-cross">
                    <a
                      className="cta-btn cancel-btn canceladdition"
                      onClick={() => {
                        setAdditionalQuestionsPopup(false);
                      }}
                    >
                      Cancel
                    </a>
                  </div>
                  <a
                    onClick={additionalQuestionsSubmit}
                    className="cta-btn upate-btn"
                  >
                    Update
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="popup-wrapper" id="id_proof">
        <div className="top-head-box flex space-between align-center">
          <h5>Update Your ID proof</h5>
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            {/* <img src={addharCard.preview} alt="" /> */}
            <input
              type="file"
              className="hideinput-file"
              id="idproof"
              name="postData"
              onChange={handleChange}
              accept=".svg, .png, .jpg, .jpeg, .pdf"
            />
            <img src={idProof.id_proof} alt="" />
            <div className="icon flex">
              <label className="custom-file-label" htmlFor="idproof">
                <div className="edit-pen">
                  <img src="src/images/edit-pen-img.svg" alt="" />
                </div>
              </label>
              <div className="delete-pen">
                <img src="src/images/delete-pen-img.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="form-group btngroup space-end full-width flex mt-20">
            <button className="cta-btn border border-redius">Cancel</button>
            <button className="cta-btn  border-redius">Update</button>
          </div>
        </div>
      </div>
      <div className="popup-wrapper custom-popup-hight" id="id_proof-view">
        <div className="top-head-box flex space-end">
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            <img src={idProof.id_proof} alt="addhar-img01" />
          </div>
        </div>
      </div>
      <div className="popup-wrapper" id="address_proof">
        <div className="top-head-box flex space-between align-center">
          <h5>Update Your ID proof</h5>
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            {/* <img
                src={addressProofPopUp.addressProofPreview}
                alt=""
              /> */}
            <input
              type="file"
              className="hideinput-file"
              id="addressProof"
              name="addressProofPost"
              onChange={handleChange}
              accept=".svg, .png, .jpg, .jpeg, .pdf"
            />
            <img src={idProof.address_proof} alt="" />
            <div className="icon flex">
              <label className="custom-file-label" htmlFor="addressProof">
                <div className="edit-pen">
                  <img src="src/images/edit-pen-img.svg" alt="" />
                </div>
              </label>
              <div className="delete-pen">
                <img src="src/images/delete-pen-img.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="form-group btngroup space-end full-width flex mt-20">
            <button className="cta-btn border border-redius">Cancel</button>
            <button className="cta-btn border-redius">Update</button>
          </div>
        </div>
      </div>
      <div className="popup-wrapper custom-popup-hight" id="address_proof-view">
        <div className="top-head-box flex space-end">
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            <img src={idProof.address_proof} alt="" />
          </div>
        </div>
      </div>
      <div className="popup-wrapper" id="company_verification">
        <div className="top-head-box flex space-between align-center">
          <h5>Update Your ID proof</h5>
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            {/* <img
                src={companyVerification.companyVerificationPreview}
                alt=""
              /> */}
            <input
              type="file"
              className="hideinput-file"
              id="companyVerification"
              name="companyVerificationPost"
              onChange={handleChange}
              accept=".svg, .png, .jpg, .jpeg, .pdf"
            />
            <img src={idProof.company_verification} alt="" />
            <div className="icon flex">
              <label
                className="custom-file-label"
                htmlFor="companyVerification"
              >
                <div className="edit-pen">
                  <img src="src/images/edit-pen-img.svg" alt="" />
                </div>
              </label>
              <div className="delete-pen">
                <img src="src/images/delete-pen-img.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="form-group btngroup space-end full-width flex mt-20">
            <button className="cta-btn border border-redius">Cancel</button>
            <button className="cta-btn border-redius">Update</button>
          </div>
        </div>
      </div>
      <div
        className="popup-wrapper custom-popup-hight"
        id="company_verification-view"
      >
        <div className="top-head-box flex space-end">
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            <img src={idProof.company_verification} alt="" />
          </div>
        </div>
      </div>
      <div className="popup-wrapper" id="tax_information">
        <div className="top-head-box flex space-between align-center">
          <h5>Update Your ID proof</h5>
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            {/* <img src={taxInformation.taxInformationPreview} alt=""/> */}
            <input
              type="file"
              className="hideinput-file"
              id="taxInformation"
              name="taxInformationPost"
              onChange={handleChange}
              accept=".svg, .png, .jpg, .jpeg, .pdf"
            />
            <img src={idProof.bank_statement} alt="" />
            <div className="icon flex">
              <label className="custom-file-label" htmlFor="taxInformation">
                <div className="edit-pen">
                  <img src="src/images/edit-pen-img.svg" alt="" />
                </div>
              </label>
              <div className="delete-pen">
                <img src="src/images/delete-pen-img.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="form-group btngroup space-end full-width flex mt-20">
            <button className="cta-btn border border-redius">Cancel</button>
            <button className="cta-btn border-redius">Update</button>
          </div>
        </div>
      </div>
      <div
        className="popup-wrapper custom-popup-hight"
        id="tax_information-view"
      >
        <div className="top-head-box flex space-end">
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
          <div className="dotted-box">
            <img src={idProof.bank_statement} alt="addhar-img01" />
          </div>
        </div>
      </div>
      {aboutProductPopup && (
        <div className="popup-container-custom">
          <div className="popup-wrapper pop-open custom-popup-width">
            <div className="top-head-box flex space-between align-center">
              <h5>Update Additional Questions</h5>
              <div
                className="pop-cross-btn"
                onClick={() => {
                  setAboutProductPopup(false);
                  window.location.reload();
                }}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <h5 className="product-margin">
                <b>Product Image</b>
              </h5>
              <div className="dotted-box">
                <img src={aboutProduct.productImage} alt="" />
                <input
                  type="file"
                  className="hideinput-file"
                  id="uplodimage"
                  name="productImage"
                  onChange={handleChange}
                  accept=".svg, .png, .jpg, .jpeg"
                />
                <img src={aboutProduct.preview} alt="" />
                <div className="icon flex">
                  <div className="edit-pen">
                    <label className="custom-file-label" htmlFor="uplodimage">
                      <img src="src/images/edit-pen-img.svg" alt="" />
                    </label>
                  </div>
                  <div className="delete-pen" onClick={deleteproduct}>
                    <img src="src/images/delete-pen-img.svg" alt="" />
                  </div>
                </div>
                {errors.productImage && (
                  <p className="error-msg">{errors.productImage}</p>
                )}
              </div>
              <form action="">
                <div className="form-wrapper-box flex space-between">
                  {/* <div className="form-group full-width">
                    <label htmlFor="">Description of Product</label>
                    <textarea
                      name="productDescription"
                      className="form-control"
                      placeholder="TechGenius offers a cutting-edge software development platform that empowers businesses to create innovative mobile applications and web solutions. Our platform leverages the latest technologies to deliver seamless user experiences and robust functionality. Whether you're a startup looking to disrupt the market or an established enterprise seeking to enhance your digital presence, TechGenius provides the tools and expertise to bring your vision to life. With a focus on user-centric design and scalable architecture, our platform enables businesses to stay ahead of the curve in today's rapidly evolving digital landscape."
                      value={aboutProduct.productDescription}
                      onChange={handleChange}
                    ></textarea>
                    {errors.productDescription && (
                      <p className="error-msg">{errors.productDescription}</p>
                    )}
                    <p>
                      *Recommend displaying the product along with users or
                      founders.
                    </p>
                  </div> */}
                  <h5 className="full-width">
                    <b>Links (If applicable)</b>
                  </h5>
                  <div className="form-group half">
                    <input
                      type="url"
                      name="website"
                      placeholder="https://websites.com"
                      className="form-control"
                      value={aboutProduct.website}
                      onChange={handleChange}
                    />
                    {errors.website && (
                      <p className="error-msg">{errors.website}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <input
                      type="url"
                      placeholder="https://twitter.com"
                      className="form-control"
                      name="twitter"
                      value={aboutProduct.twitter}
                      onChange={handleChange}
                    />
                    {errors.twitter && (
                      <p className="error-msg">{errors.twitter}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <input
                      type="url"
                      placeholder="https://facebook.com"
                      className="form-control"
                      name="facebook"
                      value={aboutProduct.facebook}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group half">
                    <input
                      type="url"
                      placeholder="https://instagram.com"
                      className="form-control"
                      name="instagram"
                      value={aboutProduct.instagram}
                      onChange={handleChange}
                    />
                    {errors.instagram && (
                      <p className="error-msg">{errors.instagram}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <input
                      type="url"
                      placeholder="https://linkedIn.com"
                      className="form-control"
                      name="linkedin"
                      value={aboutProduct.linkedin}
                      onChange={handleChange}
                    />
                    {errors.linkedin && (
                      <p className="error-msg">{errors.linkedin}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <input
                      type="url"
                      placeholder="https://youtube.com"
                      className="form-control"
                      name="youtube"
                      value={aboutProduct.youtube}
                      onChange={handleChange}
                    />
                    {errors.youtube && (
                      <p className="error-msg">{errors.youtube}</p>
                    )}
                  </div>
                  <div className="form-group btngroup space-end full-width flex">
                    <button
                      className="cta-btn border border-redius"
                      onClick={() => {
                        setAboutProductPopup(false);
                        window.location.reload();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="cta-btn border-redius"
                      onClick={aboutProductSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {updateMemberPopup && (
        <div className="popup-container-custom">
          <div className="popup-wrapper pop-open" id="update-team-member">
            <div className="top-head-box flex space-between">
              <h5>Update Team Member</h5>
              <div
                className="pop-cross-btn"
                onClick={() => setUpdateMemberPopup(false)}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <form action="">
                <div className="form-wrapper-box flex space-between	">
                  <div className="form-group full-width">
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="registration1"
                            name="memberPhoto"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg"
                          />
                          {editTeamMembers.memberPhotoPreview ? (
                            <img
                              className="custom-memberimg"
                              src={editTeamMembers.memberPhotoPreview}
                              alt="Member Preview"
                            />
                          ) : (
                            <img
                              className="custom-memberimg"
                              src={editTeamMembers.memberPhoto}
                              alt="Member"
                            />
                          )}
                          {/* <img
                          className="custom-memberimg"
                          src={editTeamMembers.memberPhoto}
                          alt=""
                        />
                        <img src={editTeamMembers.memberPhotoPreview} alt="" /> */}
                          {/* <label
                          className="custom-file-label"
                         htmlFor="registration1"
                        >
                          {editTeamMembers.memberPhoto
                            ? editTeamMembers.memberPhoto.name
                            : "Upload photo"}
                          <br/>
                          Upload photo
                        </label> */}
                          <div className="icon flex">
                            <label
                              className="custom-file-label"
                              htmlFor="registration1"
                            >
                              <div className="edit-pen">
                                <img src="src/images/editicon.png" alt="" />
                              </div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {errors.memberPhoto && (
                      <p className="error-msg">{errors.memberPhoto}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      placeholder="John Doe"
                      className="form-control"
                      name="memberName"
                      value={editTeamMembers.memberName}
                      onChange={handleChange}
                    />
                    {errors.memberName && (
                      <p className="error-msg">{errors.memberName}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Designation</label>
                    <input
                      type="text"
                      placeholder="Founder & CEO"
                      className="form-control"
                      name="memberDesignation"
                      value={editTeamMembers.memberDesignation}
                      onChange={handleChange}
                    />
                    {errors.memberDesignation && (
                      <p className="error-msg">{errors.memberDesignation}</p>
                    )}
                  </div>
                  <div className="form-group full-width">
                    <label htmlFor="">Bio</label>
                    <textarea
                      name="memberBio"
                      placeholder="John Doe is a visionary leader with a strong background in technology and business development. He founded TechGenius with the mission of revolutionizing the software development industry. John's strategic vision and commitment to excellence have been instrumental in driving the company's growth and success."
                      className="form-control"
                      id=""
                      value={editTeamMembers.memberBio}
                      onChange={handleChange}
                    ></textarea>
                    {errors.memberBio && (
                      <p className="error-msg">{errors.memberBio}</p>
                    )}
                  </div>
                  <div className="form-group btngroup space-end full-width flex">
                    <button
                      className="cta-btn border border-redius"
                      onClick={() => setUpdateMemberPopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="cta-btn border-redius"
                      onClick={postEditMembers}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {openFounderpopup && (
        <div className="popup-container-custom">
          <div className="popup-wrapper pop-open" id="update-team-member">
            <div className="top-head-box flex space-between">
              <h5>Update Team Member</h5>
              <div
                className="pop-cross-btn"
                onClick={() => setOpenFounderpopup(false)}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <form action="">
                <div className="form-wrapper-box flex space-between	">
                  <div className="form-group full-width">
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="registration2"
                            name="memberPhoto1"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg,"
                          />
                          {aboutFounder.founderPhotoPreview ? (
                            <img
                              className="custom-memberimg"
                              src={aboutFounder.founderPhotoPreview}
                              alt="Member Preview"
                            />
                          ) : (
                            <img
                              className="custom-memberimg"
                              src={aboutFounder?.founderPhoto}
                              alt="Member"
                            />
                          )}
                          <div className="icon flex">
                            <label
                              className="custom-file-label"
                              htmlFor="registration2"
                            >
                              <div className="edit-pen">
                                <img src="src/images/editicon.png" alt="" />
                              </div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {errors.founderPhoto && (
                      <p className="error-msg">{errors.founderPhoto}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      placeholder="John Doe"
                      className="form-control"
                      name="founderName"
                      value={aboutFounder.founderName}
                      onChange={handleChange}
                    />
                    {errors.founderName && (
                      <p className="error-msg">{errors.founderName}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Designation</label>
                    <input
                      type="text"
                      placeholder="Founder & CEO"
                      className="form-control"
                      name="founderDesignation"
                      value={
                        aboutFounder?.founderDesignation != "null"
                          ? aboutFounder?.founderDesignation
                          : ""
                      }
                      onChange={handleChange}
                    />
                    {errors.founderDesignation && (
                      <p className="error-msg">{errors.founderDesignation}</p>
                    )}
                  </div>
                  <div className="form-group full-width">
                    <label htmlFor="">Bio</label>
                    <textarea
                      name="founderDescription"
                      placeholder="John Doe is a visionary leader with a strong background in technology and business development. He founded TechGenius with the mission of revolutionizing the software development industry. John's strategic vision and commitment to excellence have been instrumental in driving the company's growth and success."
                      className="form-control"
                      id=""
                      value={
                        aboutFounder.founderDescription != "null"
                          ? aboutFounder.founderDescription
                          : ""
                      }
                      onChange={handleChange}
                    ></textarea>
                    {errors.founderDescription && (
                      <p className="error-msg">{errors.founderDescription}</p>
                    )}
                  </div>
                  <div className="form-group btngroup space-end full-width flex">
                    <button
                      className="cta-btn border border-redius"
                      onClick={() => setOpenFounderpopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="cta-btn border-redius"
                      onClick={editFounder}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {addMemberPopup && (
        <div className="popup-container-custom">
          <div className="popup-wrapper pop-open " id="update-team-img-member">
            <div className="top-head-box flex space-between">
              <h5>Add Team Member</h5>
              <div
                className="pop-cross-btn"
                onClick={() => {
                  setAddMemberPopup(false);
                }}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <form action="">
                <div className="form-wrapper-box flex space-between">
                  <div className="form-group full-width">
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="registration"
                            name="photo"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg,.jpeg"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="registration"
                            style={{ color: "#43B8F5" }}
                          >
                            {getFileName(addMember.photo)
                              ? getFileName(addMember.photo).length > 20
                                ? `${getFileName(addMember.photo).slice(
                                    0,
                                    20
                                  )}...`
                                : getFileName(addMember.photo)
                              : "Upload photo"}
                          </label>
                        </li>
                      </ul>
                      {errors.photo && (
                        <p className="error-msg">{errors.photo}</p>
                      )}
                    </div>
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      placeholder="Exp: John"
                      className="form-control"
                      name="name"
                      onChange={handleChange}
                      value={addMember.name}
                    />
                    {errors.name && <p className="error-msg">{errors.name}</p>}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Designation</label>
                    <input
                      type="text"
                      placeholder="Exp: CEO, Founder"
                      className="form-control"
                      name="designation"
                      onChange={handleChange}
                      value={addMember.designation}
                    />
                    {errors.designation && (
                      <p className="error-msg">{errors.designation}</p>
                    )}
                  </div>
                  <div className="form-group full-width">
                    <label htmlFor="">Bio</label>
                    <textarea
                      name="bio"
                      placeholder="..."
                      className="form-control"
                      id=""
                      onChange={handleChange}
                      value={addMember.bio}
                    ></textarea>
                    {errors.bio && <p className="error-msg">{errors.bio}</p>}
                  </div>
                  <div className="form-group btngroup space-end full-width flex">
                    <button
                      className="cta-btn border border-redius"
                      onClick={() => {
                        setAddMemberPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="cta-btn border-redius"
                      onClick={postAddMembers}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {economicsandBusinessGrowthPopup && (
        <div className="popup-container-custom">
          <div
            className="popup-wrapper pop-open custom-popup-hight2"
            id="business-growth"
          >
            <div className="top-head-box flex space-between">
              <h5>Update Unit Economics and Business Growth</h5>
              <div
                className="pop-cross-btn"
                onClick={() => {
                  setEconomicsandBusinessGrowthPopup(false);
                }}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <form action="">
                <div className="form-wrapper flex space-between">
                  <h5>
                    Annual Revenue for the Past 3 Years
                    <strong />
                  </h5>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 1 <span class="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="current year projection"
                      className="form-control"
                      name="year1Revenue"
                      value={businessGrowthPopup.year1Revenue}
                      onChange={handleChange}
                    />
                    {errors.year1Revenue && (
                      <p className="error-msg">{errors.year1Revenue}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 2 
                    </label>
                    <input
                      type="text"
                      placeholder="Last Year"
                      className="form-control"
                      name="year2Revenue"
                      value={businessGrowthPopup.year2Revenue}
                      onChange={handleChange}
                    />
                    {errors.year2Revenue && (
                      <p className="error-msg">{errors.year2Revenue}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 3 
                    </label>
                    <input
                      type="text"
                      placeholder="Year Before"
                      className="form-control"
                      name="year3Revenue"
                      value={businessGrowthPopup.year3Revenue}
                      onChange={handleChange}
                    />
                    {errors.year3Revenue && (
                      <p className="error-msg">{errors.year3Revenue}</p>
                    )}
                  </div>
                  <h5>
                    Gross Profit Margins for the Past 3 Years{" "}
                    <strong>(in %)</strong>
                  </h5>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 1 <span class="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="current year projection"
                      className="form-control"
                      name="year1GrossProfitMargin"
                      value={businessGrowthPopup.year1GrossProfitMargin}
                      onChange={handleChange}
                    />
                    {errors.year1GrossProfitMargin && (
                      <p className="error-msg">
                        {errors.year1GrossProfitMargin}
                      </p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 2 
                    </label>
                    <input
                      type="text"
                      placeholder="Last Year"
                      className="form-control"
                      name="year2GrossProfitMargin"
                      value={businessGrowthPopup.year2GrossProfitMargin}
                      onChange={handleChange}
                    />
                    {errors.year2GrossProfitMargin && (
                      <p className="error-msg">
                        {errors.year2GrossProfitMargin}
                      </p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 3 
                    </label>
                    <input
                      type="text"
                      placeholder="Year Before"
                      className="form-control"
                      name="year3GrossProfitMargin"
                      value={businessGrowthPopup.year3GrossProfitMargin}
                      onChange={handleChange}
                    />
                    {errors.year3GrossProfitMargin && (
                      <p className="error-msg">
                        {errors.year3GrossProfitMargin}
                      </p>
                    )}
                  </div>
                  <h5>
                    EBIDTA margin for the past 3 years <strong>(in %)</strong>
                  </h5>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 1 <span class="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="current year projection"
                      className="form-control"
                      name="year1EBIDTAMargin"
                      value={businessGrowthPopup.year1EBIDTAMargin}
                      onChange={handleChange}
                    />
                    {errors.year1EBIDTAMargin && (
                      <p className="error-msg">{errors.year1EBIDTAMargin}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 2 
                    </label>
                    <input
                      type="text"
                      placeholder="Last Year"
                      className="form-control"
                      name="year2EBIDTAMargin"
                      value={businessGrowthPopup.year2EBIDTAMargin}
                      onChange={handleChange}
                    />
                    {errors.year2EBIDTAMargin && (
                      <p className="error-msg">{errors.year2EBIDTAMargin}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Year 3 
                    </label>
                    <input
                      type="text"
                      placeholder="Year Before"
                      className="form-control"
                      name="year3EBIDTAMargin"
                      value={businessGrowthPopup.year3EBIDTAMargin}
                      onChange={handleChange}
                    />
                    {errors.year2EBIDTAMargin && (
                      <p className="error-msg">{errors.year2EBIDTAMargin}</p>
                    )}
                  </div>
                  <div className="bordertop full-width"></div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Customer Acquisition Cost (CAC){" "}
                      <span class="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="CAC"
                      className="form-control"
                      name="CAC"
                      value={businessGrowthPopup.CAC}
                      onChange={handleChange}
                    />
                    {errors.CAC && <p className="error-msg">{errors.CAC}</p>}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Lifetime Value of a Customer (LTV){" "}
                      <span class="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="LTV"
                      className="form-control"
                      name="LTV"
                      value={businessGrowthPopup.LTV}
                      onChange={handleChange}
                    />
                    {errors.LTV && <p className="error-msg">{errors.LTV}</p>}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Burn Rate Per Month (please write not applicable. If there
                      is no burn rate.) <span class="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Burn Rate"
                      className="form-control"
                      name="burnRate"
                      value={businessGrowthPopup.burnRate}
                      onChange={handleChange}
                    />
                    {errors.burnRate && (
                      <p className="error-msg">{errors.burnRate}</p>
                    )}
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">
                      Number of Active Users/Clients{" "}
                      <span class="required-sign">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="0"
                      className="form-control"
                      name="activeUsers"
                      value={businessGrowthPopup.activeUsers}
                      onChange={handleChange}
                    />
                    {errors.activeUsers && (
                      <p className="error-msg">{errors.activeUsers}</p>
                    )}
                  </div>
                  <div className="form-group full-width">
                    <label htmlFor="">
                      Funding Allocation <span class="required-sign">*</span>
                    </label>
                    <textarea
                      type="text"
                      placeholder="I.e We'll allocate 25 lakhs for new inventory and another 25 lakhs for research and development."
                      className="form-control"
                      name="fundingSought"
                      value={businessGrowthPopup.fundingSought}
                      onChange={handleChange}
                    />
                    {errors.fundingSought && (
                      <p className="error-msg">{errors.fundingSought}</p>
                    )}
                  </div>
                  <div className="form-group full-width">
                  <label htmlFor="">
                      Total addressable Market(TAM)
                      <span className="required-sign">*</span>
                    </label>
                    <input
                     type="text"
                     placeholder="TAM"
                     className="form-control"
                     name="tam"
                     id="tam"
                      value={businessGrowthPopup.tam}
                      onChange={handleChange}
                    />
                    {errors.tam && (
                      <p className="error-msg">{errors.tam}</p>
                    )}
                  </div>
                  <div className="form-group btngroup space-end full-width flex">
                    <button
                      className="cta-btn border border-redius"
                      onClick={() => {
                        setEconomicsandBusinessGrowthPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="cta-btn border-redius"
                      onClick={businessGrowthPopupSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}

      <section className="profile-Sec dashboard-sec startupmyprofile">
        <div className="container">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>{formData.businessName}</h5>
              <ul className="flex">
                <li>
                  <a href="#">Home</a>
                </li>
                {/* <li>
                  <a href="#">Startups business List </a>
                </li>
                <li>
                  <a href="#">Main</a>
                </li> */}
                <li>
                  <a href="/startup-profile">StartUp-Profile</a>
                </li>
              </ul>
            
              {(status?.status == "Active" || status?.status == "Activated") &&
              status?.contract == "Pending" ? (
                <p className="contracttextsign">
                  Last Step: Sign the{" "}
                  <a
                    href={investorprivacy}
                    style={{ cursor: "pointer", color: "#43B8F5" }}
                  >
                    Contract
                  </a>{" "}
                  to make your profile visible to investors.
                </p>
              ) : status?.status == "Pending" ? (
                <p className="contracttextsign">
                  Your Profile is under review. Once approved, the final step will be to 
                  {" "}
                  <a
                    href={investorprivacy}
                    style={{ cursor: "pointer", color: "#43B8F5" }}
                  >
                  sign a Contract
                  </a>{" "}
                  before your listing is made live to investors
                </p>
              ) : status?.status == "Deactive" ? (
                <>
                  <p style={{ marginBottom: "0px" }}>
                    Your Profile has been disapproved
                  </p>
                  <p>
                    Please check your email for detailed reasons and{" "}
                    <a
                      href={startupprofile}
                      style={{ cursor: "pointer", color: "#43B8F5" }}
                    >
                      Resubmit
                    </a>{" "}
                    {""} the profile
                  </p>
                </>
              ) : status?.status == "Resubmit" ? (
                <>
                  <p style={{ marginBottom: "0px" }}>
                    Your profile has been resubmitted and it would take 2
                    business days to approve or disapprove.
                  </p>
                </>
              ) :(status?.status == "Active" || status?.status == "Activated") &&
              status?.contract == "Activated" &&(
                <p style={{ marginBottom: "0px" }}>
                Your profile is now live and available for investors to view.
              </p>
              )}
            </div>
            {status?.status == "Deactive" ? (
              <>
                <button className="cta-btn" onClick={profilereSubmit}>
                  Resubmit profile
                </button>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="row flex space-between">
            <div className="big-text-box">
              <div className="media-box video custom-video">
                <iframe src={videoSrc} allow="fullscreen" title="iframe" />
              </div>
              <div className="shadow-box edit-box">
                <div className="name-box-wrapper">
                  <div className="row-box flex">
                    <div className="name-box user-photo">
                      <img src={formData.logo} alt="" />
                    </div>
                    <div className="col-right">
                      {formData?.businessRegistrationNumber != "" && (
                        <div className="stage-icon flex">
                          {" "}
                          <p>
                            Business Registration No1 #{" "}
                            {formData?.businessRegistrationNumber != ""
                              ? formData?.businessRegistrationNumber
                              : ""}
                          </p>
                        </div>
                      )}

                      <div className="name-url">
                        <h3>
                          {/* John Doe{" "} */} {formData.founderName}
                          {formData.websiteURL != "null" &&
                            formData.websiteURL != "" && (
                              <a href={formData.websiteURL} target="_blank">
                                <img src="src/images/white-globe.svg" alt="" />{" "}
                                {formData.websiteURL}
                              </a>
                            )}
                        </h3>
                      </div>
                      <div className="contact-btn-wrapper">
                        <ul className="flex">
                          <li className="flex">
                            <img
                              src="src/images/mail-icon.svg"
                              alt=""
                              className="iconimage"
                            />
                            <a href="mailto: john.doe@techgenius.com">
                              {" "}
                              {formData.email}
                            </a>
                          </li>
                          <li className="flex">
                            <img
                              src="src/images/birthday-date.svg"
                              alt=""
                              className="iconimage"
                            />
                            <p>{formatDate(formData.dob)}</p>
                          </li>
                          <li className="flex">
                            <img
                              src="src/images/location-blue.svg"
                              alt=""
                              className="iconimage"
                            />
                            <p>
                              {formData.city + ","} {formData.state + ","}{" "}
                              {formData.country}
                            </p>
                          </li>
                          <li className="flex">
                            <img
                              src="src/images/calender.svg"
                              alt=""
                              className="iconimage"
                            />
                            <p>
                              YE:{" "}
                              {formData.yearEstablished != NaN
                                ? formData.yearEstablished
                                : ""}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="dotted-box-wrapper flex">
                        <div className="dotted-box">
                          <small>Industry Sector</small>
                          <h6>{formData.industrySector}</h6>
                        </div>
                        <div className="dotted-box">
                          <small>Stage of Business</small>
                          <h6>{formData.stageOfBusiness}</h6>
                        </div>
                        <div className="dotted-box">
                          <small>Funding Needs</small>
                          <h6>{formData.fundingNeeds} </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="full-detail-box">
                    <h3>{formData.businessName}</h3>
                    <p>{formData.pitch_deck}</p>
                  </div>
                </div>
                <div
                  className="edit-btn"
                  onClick={() => {
                    setUpdateIdentityPopup(true);
                  }}
                >
                  <img src="src/images/edit-pen.svg" alt="" />
                </div>
              </div>
              <div className="shadow-box edit-box">
                <div
                  className="edit-btn"
                  onClick={() => {
                    setAdditionalQuestionsPopup(true);
                  }}
                >
                  <img src="src/images/edit-pen.svg" alt="" />
                </div>
                <div className="model-box">
                  <ul className="flex" style={{ alignItems: "baseline" }}>
                    <li>
                      <img src="src/images/checkbox-icon.svg" alt="" />
                    </li>
                    <li>
                      <h6>Revenue Model</h6>
                      <p>
                        {Array?.isArray(additionalQuestions?.revenueModel)
                          ? additionalQuestions?.revenueModel?.join(", ")
                          : additionalQuestions?.revenueModel}
                        {additionalQuestions?.revenueModel?.includes("Other") &&
                          ` (${additionalQuestions?.revenueModelother})`}
                      </p>
                    </li>
                  </ul>
               
                  {additionalQuestions.marketAnalysis && additionalQuestions.marketAnalysis!=""&&
                    additionalQuestions.marketAnalysis != null && (
                      <ul className="flex" style={{ alignItems: "baseline" }}>
                        <li>
                          <img src="src/images/checkbox-icon.svg" alt="" />
                        </li>
                        <li>
                          <h6>Market Analysis</h6>
                          <p>{additionalQuestions.marketAnalysis}</p>
                        </li>
                      </ul>
                    )}
                  <ul className="flex" style={{ alignItems: "baseline" }}>
                    <li>
                      <img src="src/images/checkbox-icon.svg" alt="" />
                    </li>
                    <li>
                      <h6>Equlity Allocation</h6>
                      <p>{additionalQuestions.equiltyallocation}</p>
                    </li>
                  </ul>
             
             
                  <ul className="flex" style={{ alignItems: "baseline" }}>
                    <li>
                      <img src="src/images/checkbox-icon.svg" alt="" />
                    </li>
                    <li>
                      <h6>Legal Structure</h6>
                      <p>
                        {additionalQuestions.legalStructure === "Other"
                          ? additionalQuestions?.legalStructureother
                          : additionalQuestions?.legalStructure}
                      </p>
                    </li>
                  </ul>
             
                  <ul className="flex" style={{ alignItems: "baseline" }}>
                    <li>
                      <img src="src/images/checkbox-icon.svg" alt="" />
                    </li>
                    <li>
                      <h6>Pending Litigation </h6>
                      <p>
                        {additionalQuestions?.pendingLitigation === "Yes"
                          ? additionalQuestions?.pendingLitigationnotes
                          : additionalQuestions?.pendingLitigation}
                      </p>
                    </li>
                  </ul>
                  <ul className="flex" style={{ alignItems: "baseline" }}>
                    <li>
                      <img src="src/images/checkbox-icon.svg" alt="" />
                    </li>
                    <li>
                      <h6>  GST Identification Number (GSTIN)</h6>
                      <p>
                        {additionalQuestions?.gstin
                          ? additionalQuestions?.gstin
                          : additionalQuestions?.gstin}
                      </p>
                    </li>
                  </ul>
                
                  {/* {additionalQuestions?.featuredInvestors != "" && (
                    <ul className="flex" style={{ alignItems: "baseline" }}>
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6>featured Investors </h6>
                        <p>{additionalQuestions?.featuredInvestors}</p>
                      </li>
                    </ul>
                  )} */}
                  {/* intellectualProperty */}
                  <ul className="flex" style={{ alignItems: "baseline" }}>
                    <li>
                      <img src="src/images/checkbox-icon.svg" alt="" />
                    </li>
                    <li>
                      <h6>Patents or trademarks</h6>
                      <p>
                        {additionalQuestions?.intellectualProperty === "Yes"
                          ? additionalQuestions?.trademarkText
                          : additionalQuestions?.intellectualProperty}
                      </p>
                    </li>
                  </ul>
                  {/* pendingLitigation  */}
                </div>
              </div>
              <div className="doucment-box-wrapper">
                <div className="h4">Legal Compliance and Documents</div>
                <div className="document-box-row flex space-between">
                  <div className="document-col shadow-box">
                    {idProof.id_proof == "" || idProof.id_proof == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(idProof.company_verification);
                              setEditType("idproof");
                              setDoucmenttype("1");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>ID proof</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>ID proof</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(idProof.id_proof);
                                  if (status?.status == "Deactive") {
                                    setEditPopup(true);
                                  } else {
                                    setPopup(true);
                                  }
                                  setEditType("idproof");
                                  setDoucmenttype("1");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                            </div>
                          </li>
                        </ul>
                        {idProof.id_proof && (
                          <ImaageDocPDF src={idProof.id_proof} alt="" />
                        )}
                      </>
                    )}
                  </div>

                  <div className="document-col shadow-box">
                    {idProof.address_proof == "" ||
                    idProof.address_proof == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(idProof.address_proof);
                              setEditType("addressproof");
                              setDoucmenttype("2");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>address proof</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>address proof</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(idProof.address_proof);
                                  if (status?.status == "Deactive") {
                                    setEditPopup(true);
                                  } else {
                                    setPopup(true);
                                  }
                                  setEditType("addressproof");
                                  setDoucmenttype("2");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                            </div>
                          </li>
                        </ul>
                        {idProof.address_proof && (
                          <ImaageDocPDF src={idProof.address_proof} alt="" />
                        )}
                      </>
                    )}
                  </div>

                  <div className="document-col shadow-box">
                    {idProof.company_verification == "" ||
                    idProof.company_verification == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(idProof.company_verification);
                              setEditType("companyverification");
                              setDoucmenttype("3");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Company Verification</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Company Verification</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(idProof.company_verification);
                                  if (status?.status == "Deactive") {
                                    setEditPopup(true);
                                  } else {
                                    setPopup(true);
                                  }
                                  setEditType("companyverification");
                                  setDoucmenttype("3");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                            onClick={() => {
                              setEditPopup(true);
                              setImage(User?.passport);
                              setEditType("passport");
                            }}
                            className="open-document-popup"
                          >
                            <img src="src/images/white-editpen.svg" alt="" />
                          </a> */}
                            </div>
                          </li>
                        </ul>
                        {idProof.company_verification && (
                          <ImaageDocPDF
                            src={idProof.company_verification}
                            alt=""
                          />
                        )}
                      </>
                    )}
                  </div>

                  <div className="document-col shadow-box">
                    {idProof.bank_statement == "" ||
                    idProof.bank_statement == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(idProof.bank_statement);
                              setEditType("taxinformation");
                              setDoucmenttype("4");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Tax Information</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Tax Information</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(idProof.bank_statement);
                                  if (status?.status == "Deactive") {
                                    setEditPopup(true);
                                  } else {
                                    setPopup(true);
                                  }
                                  setEditType("taxinformation");
                                  setDoucmenttype("4");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                            onClick={() => {
                              setEditPopup(true);
                              setImage(User?.passport);
                              setEditType("passport");
                            }}
                            className="open-document-popup"
                          >
                            <img src="src/images/white-editpen.svg" alt="" />
                          </a> */}
                            </div>
                          </li>
                        </ul>
                        {idProof.bank_statement && (
                          <ImaageDocPDF src={idProof.bank_statement} alt="" />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="shadow-box edit-box">
                <div
                  className="edit-btn"
                  onClick={() => {
                    setAboutProductPopup(true);
                  }}
                >
                  <img src="src/images/edit-pen.svg" alt="" />
                </div>
                <div className="about-product">
                  <div className="h4">About Product</div>
                  <div className="img-box">
                    <img
                      className="imgborder"
                      src={aboutProduct.productImage}
                      alt="about-product"
                    />
                  </div>
                  <p>{aboutProduct.productDescription}</p>

                  <div className="social-icon">
                    <ul className="flex">
                      <li>Social Links:</li>
                      <li
                        className={
                          !aboutProduct?.website &&
                          !aboutProduct?.website != "undefined" &&
                          !aboutProduct?.facebook &&
                          !aboutProduct?.facebook != "undefined" &&
                          !aboutProduct?.twitter &&
                          !aboutProduct?.twitter != "undefined" &&
                          !aboutProduct?.instagram &&
                          !aboutProduct?.instagram != "undefined" &&
                          !aboutProduct?.instagram != "null" &&
                          !aboutProduct?.linkedin &&
                          !aboutProduct?.linkedin != "undefined" &&
                          !aboutProduct?.youtube &&
                          !aboutProduct?.youtube != "undefined"
                            ? "flex align-center mobileviewsocialno"
                            : "flex align-center mobileviewsocial"
                        }
                      >
                        {aboutProduct?.website &&
                          aboutProduct?.website != null &&
                          aboutProduct?.website != "undefined" &&
                          aboutProduct?.website != undefined && (
                            <a href={aboutProduct.website} target="_blank">
                              <img
                                src="src/images/globe.svg"
                                alt="socialicon"
                              />
                            </a>
                          )}
                        {aboutProduct?.facebook &&
                          aboutProduct?.facebook != "undefined" &&
                          aboutProduct?.facebook != null &&
                          aboutProduct?.facebook != undefined && (
                            <a href={aboutProduct.facebook} target="_blank">
                              <img
                                src="src/images/Facebook.svg"
                                alt="socialicon"
                              />
                            </a>
                          )}
                        {aboutProduct?.twitter &&
                          aboutProduct?.twitter != "undefined" &&
                          aboutProduct?.twitter != null &&
                          aboutProduct?.twitter != undefined && (
                            <a href={aboutProduct.twitter} target="_blank">
                              <img
                                src="src/images/Twitter.svg"
                                alt="socialicon"
                              />
                            </a>
                          )}
                        {aboutProduct?.instagram &&
                          aboutProduct?.instagram != "undefined" &&
                          aboutProduct?.instagram != "null" &&
                          aboutProduct?.instagram != undefined && (
                            <a href={aboutProduct.instagram} target="_blank">
                              <img
                                src="src/images/inst-new.svg"
                                alt="socialicon"
                              />
                            </a>
                          )}
                        {aboutProduct?.linkedin &&
                          aboutProduct?.linkedin != "undefined" &&
                          aboutProduct?.linkedin != null &&
                          aboutProduct?.linkedin != undefined && (
                            <a href={aboutProduct.linkedin} target="_blank">
                              <img
                                src="src/images/Linkedin1.png"
                                alt="socialicon"
                              />
                            </a>
                          )}
                        {aboutProduct?.youtube &&
                          aboutProduct?.youtube != "undefined" &&
                          aboutProduct?.youtube != undefined &&
                          aboutProduct?.youtube != null && (
                            <a href={aboutProduct.youtube} target="_blank">
                              <img
                                src="src/images/Youtube.svg"
                                alt="socialicon"
                              />
                            </a>
                          )}
                        {!aboutProduct?.website &&
                        !aboutProduct?.website != "undefined" &&
                        !aboutProduct?.facebook &&
                        !aboutProduct?.facebook != "undefined" &&
                        !aboutProduct?.twitter &&
                        !aboutProduct?.twitter != "undefined" &&
                        !aboutProduct?.instagram &&
                        !aboutProduct?.instagram != "undefined" &&
                        !aboutProduct?.instagram != "null" &&
                        !aboutProduct?.linkedin &&
                        !aboutProduct?.linkedin != "undefined" &&
                        !aboutProduct?.youtube &&
                        !aboutProduct?.youtube != "undefined" ? (
                          <>No Social media links added</>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                style={{ justifyContent: "space-between" }}
                className="h4 flex"
              >
                About Team
                <a
                  style={{
                    fontWeight: "200",
                    fontFamily: "Darker Grotesque",
                    cursor: "pointer",
                  }}
                  className="cta-btn"
                  onClick={() => {
                    setAddMemberPopup(true);
                  }}
                >
                  Add new member
                </a>
              </div>
              <div className="team-wrapper flex space-between">
                {/* <div className="shadow-box edit-box full">
                  <div
                    className="edit-btn"
                    onClick={() => {
                      setOpenFounderpopup(true);
                    }}
                  >
                    <img src="src/images/edit-pen.svg" alt="" />
                  </div>
                  <div className="about-team">
                    <ul className="flex">
                      <li>
                        {(aboutFounder.founderPhotoPreview ||
                          aboutFounder.founderPhoto) && (
                          <div className="team-img">
                            <img src={aboutFounder.founderPhoto} alt="" />
                            {aboutFounder.founderPhotoPreview ? (
                              <img
                                className="custom-memberimg"
                                src={aboutFounder.founderPhotoPreview}
                                alt="Member Preview"
                              />
                            ) : (
                              <img
                                className="custom-memberimg"
                                src={aboutFounder.founderPhoto}
                                alt="Member"
                              />
                            )}
                          </div>
                        )}
                      </li>
                      <li>
                        <div className="team-name">
                          <h6>{aboutFounder.founderName}</h6>

                          <span>
                            {aboutFounder?.founderDesignation != "null" &&
                            aboutFounder?.founderDesignation != "undefined"
                              ? aboutFounder?.founderDesignation
                              : ""}
                          </span>
                        </div>
                      </li>
                    </ul>
                    <p>
                      {aboutFounder.founderDescription != "null" &&
                      aboutFounder.founderDescription != "undefined"
                        ? aboutFounder.founderDescription
                        : ""}
                    </p>
                  </div>
                </div> */}
                {teamMembers &&
                  teamMembers.map((member, index) => {
                    return (
                      <div className="shadow-box edit-box" key={index}>
                        {isPopupOpen && (
                          <div className="popup-container">
                            <div className="popup-content">
                              <h5>Are you sure ?</h5>
                              <div className="button-flex">
                                <button
                                  className="cta-btn border border-redius"
                                  onClick={() => deleteMembers(member._id)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="cta-btn border border-redius"
                                  onClick={closeDeletePopup}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="delete-pen" onClick={openDeletePopup}>
                          <img src="src/images/deleteicon.png" alt="" />
                        </div>
                        <div
                          className="edit-btn team_member-popupbtn"
                          onClick={() => {
                            setUpdateMemberPopup(true);
                            setEditTeamMembers({
                              memberPhoto: member.photo,
                              memberDesignation: member?.designation
                                ? member.designation
                                : "",
                              memberName: member.name,
                              memberBio: member.bio,
                              memberid: member._id,
                              // memberPhotoPreview: member.photo,
                            });
                          }}
                        >
                          <img src="src/images/edit-pen.svg" alt="" />
                        </div>
                        <div className="about-team">
                          <ul className="flex flex-colmun">
                            <li>
                              <div className="team-img">
                                <img src={member?.photo} alt="" />
                              </div>
                            </li>
                            <li>
                              <div className="team-name">
                                <h6>{member.name}</h6>
                                <p style={{ textAlign: "center" }}>
                                  {member.designation}
                                </p>
                              </div>
                            </li>
                          </ul>
                          <p>{member.bio}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="small-text-box">
              <div className="shadow-box">
                <h3>{formatCurrency(totalYearRevenue)}</h3>
                <p>Sum of the annual revenue for the past three years</p>
                <div className="dotted-box-wrapper flex space-between">
                  <div className="dotted-box full">
                    <small>Year 1 ({revenueYear1})</small>
                    <h6>{businessGrowthPopup.year1Revenue}</h6>
                  </div>
                  <div className="dotted-box">
                    <small>Year 2 ({revenueYear2})</small>
                    <h6>{businessGrowthPopup.year2Revenue}</h6>
                  </div>
                  <div className="dotted-box">
                    <small>Year 3 ({revenueYear3})</small>
                    <h6>{businessGrowthPopup.year3Revenue}</h6>
                  </div>
                </div>
                <div className="heading-text">
                  <h5>
                    Gross Profit Margins for the Past 3 Years{" "}
                    <strong>(in %)</strong>
                  </h5>
                  <p>
                    <b>Year 1 ({revenueYear1}):</b>{" "}
                    {businessGrowthPopup.year1GrossProfitMargin}%
                  </p>
                  <p>
                    <b>Year 2 ({revenueYear2}):</b>{" "}
                    {businessGrowthPopup.year2GrossProfitMargin?`${businessGrowthPopup.year2GrossProfitMargin}%`:""}
                  </p>
                  <p>
                    <b>Year 3 ({revenueYear3}):</b>{" "}
                    {businessGrowthPopup.year3GrossProfitMargin?`${businessGrowthPopup.year3GrossProfitMargin}%`:''}
                  </p>
                </div>
                <div className="heading-text">
                  <h5>
                    EBITDA Margin for the Past 3 Years <strong>(in %)</strong>
                  </h5>
                  <p>
                    <b>Year 1 ({revenueYear1}):</b>{" "}
                    {businessGrowthPopup.year1EBIDTAMargin}%
                  </p>
                  <p>
                    <b>Year 2 ({revenueYear2}):</b>{" "}
                    {businessGrowthPopup.year2EBIDTAMargin?`${businessGrowthPopup.year2EBIDTAMargin}%`:""}
                  </p>
                  <p>
                    <b>Year 3 ({revenueYear3}):</b>{" "}
                    {businessGrowthPopup.year3EBIDTAMargin?`${businessGrowthPopup.year3EBIDTAMargin}%`:""}
                  </p>
                </div>
                <ul className="table-box">
                  <li className="flex">
                    <p>
                      Customer Acquisition Cost <small>(CAC)</small>
                    </p>
                    <span>{businessGrowthPopup.CAC} per customer</span>
                  </li>
                  <li className="flex">
                    <p>
                      Lifetime Value of a Customer <small>(LTV)</small>
                    </p>
                    <span>{businessGrowthPopup.LTV}</span>
                  </li>
                  <li className="flex">
                    <p>Burn Rate</p>
                    <span>₹{businessGrowthPopup.burnRate} per month</span>
                  </li>
                  <li className="flex">
                    <p>Number of Active Users/Clients</p>
                    <span>{businessGrowthPopup.activeUsers}</span>
                  </li>
                  <li className="flex">
                    <p>Funding Allocation</p>
                    <span>{businessGrowthPopup.fundingSought}</span>
                  </li>
                  <li className="flex">
                    <p> Total addressable Market(TAM)</p>
                    <span>{businessGrowthPopup.tam}</span>
                  </li>
                </ul>
                <div className="btn-box">
                  <a className="cta-btn btn-orange minmaxrise">
                    {businessGrowthPopup.minimumRaise != ""
                      ? `${businessGrowthPopup.minimumRaise}`
                      : ""}
                    <span>(Minimum Raise)</span>
                  </a>
                  <a className="cta-btn btn-orange minmaxrise">
                    {businessGrowthPopup.maximumRaise != ""
                      ? `${businessGrowthPopup.maximumRaise}`
                      : ""}
                    <span>(Maximum Raise)</span>
                  </a>
                  <a
                    // href="javascript:void(0)"
                    className="cta-btn"
                    // id="popup_open"
                    onClick={() => {
                      setEconomicsandBusinessGrowthPopup(true);
                    }}
                  >
                    Edit Profile
                    <span />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default StartupProfile;
