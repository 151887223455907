/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import axios from "axios";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function SignupStep9() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingsave, setLoadingsave] = useState(false);
  const [formData, setFormData] = useState({
    // teamDescription: "",
    pitchDescription: "",
    // tipsLink: "",
    // featuredInvestors: "",

    // founderphoto: "",
    // founderDesignation: "",
    // founderDescription: "",
    agreeToTerms: false,
    generalQuestions: "",
    generalQuestionsother: "",
  });
  // console.log(formData)
  const [isdatasaved, Setisdatasaved] = useState(false);
  const [isdatasavedonly, Setisdatasavedonly] = useState(false);
  const initialValue = [{ photo: null, bio: "", name: "", designation: "" }];
  const [inputFields, setInputFields] = useState(initialValue);
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [errors, setErrors] = useState({});
  // const [addmember, setAddmember] = useState(false);
  const cleanRevenue = revenueString => {
    return Number(revenueString.replace(/₹|,/g, ""));
  };
  const formatCurrency = value => {
    if (!value) return "";
    const number = parseInt(value, 10);
    const formattedNumber = number.toLocaleString("en-IN"); // Indian numbering format
    return `₹${formattedNumber}`;
  };
  const handleChange = (e, index) => {
    const { name, value, files } = e.target;

    const maxSize = 5 * 1024 * 1024;
    // if (name === "tipsLink") {
    //   const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;

    //   if (value && !urlRegex.test(value)) {
    //     setErrors({
    //       ...errors,
    //       [name]: "Please enter a valid URL starting with http:// or https://",
    //     });
    //   } else {
    //     setErrors({
    //       ...errors,
    //       [name]: "",
    //     });
    //   }
    // }

    if (index !== undefined) {
      const updatedFields = [...inputFields];

      if (files && files.length > 0) {
        const file = files[0];

        if (file.size > maxSize) {
          toast.warn("File size should not exceed 5MB", {
            position: "top-center",
          });
        } else {
          updatedFields[index][name] = file;
        }
      } else {
        updatedFields[index][name] = value;
      }

      setInputFields(updatedFields);
      // setAddmember(true);
    } else {
      if (files && files.length > 0) {
        const file = files[0];

        if (file.size > maxSize) {
          toast.warn("File size should not exceed 5MB", {
            position: "top-center",
          });
        } else {
          setFormData({
            ...formData,
            [name]: file,
          });
        }
      } else {
        if (name === "minRaiseDescription" || name === "maxRaiseDescription") {
          const rawValue = value.replace(/[^0-9]/g, "");
          const formattedValue = formatCurrency(rawValue);
          setFormData(prevData => ({
            ...prevData,
            [name]: formattedValue,
          }));
        } else {
          setFormData({
            ...formData,
            [name]: value,
          });
        }
      }
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const getMembersData = () => {
    axios
      .get(`${apiurl}startups/viewMember/${Userid}`)
      .then(response => {
        if (response.data.data.length == 0) {
          setInputFields(initialValue);
        } else {
          setInputFields(response.data.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getFileName = file => {
    return file && typeof file === "object" ? file.name : file;
  };

  const postAddMembers = async () => {
    try {
      const promises = inputFields.map(field => {
        const filedata = new FormData();
        filedata.append("photo", field.photo);
        filedata.append("bio", field.bio);
        filedata.append("name", field.name);
        filedata.append("designation", field.designation);

        return post(`${apiurl}startups/addMember/${Userid}`, filedata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      });
      const responses = await Promise.all(promises);
      responses.forEach(response => console.log(response));
      if (!isdatasavedonly) {
        navigate("/welcome");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    // if (
    //   formData.teamDescription != null &&
    //   formData.teamDescription.length > 1000
    // ) {
    //   newErrors.teamDescription =
    //     "Team Description cannot exceed 1000 characters.";
    // }
    // if (
    //   formData.founderDescription != null &&
    //   formData.founderDescription != "" &&
    //   formData.founderDescription.length > 1000
    // ) {
    //   newErrors.founderDescription =
    //     "Founder Description cannot exceed 1000 characters.";
    // }
    inputFields.forEach((field, index) => {
      if (field.name != "") {
        if (!field.photo) {
          newErrors[`photo${index}`] = `Team Member ${
            index + 1
          } Photo is required.`;
        }
        if (!field.bio) {
          newErrors[`bio${index}`] = `Team Member ${
            index + 1
          } Bio is required.`;
        }
        if (!field.designation) {
          newErrors[`Designation${index}`] = `Team Member ${
            index + 1
          } Designation is required.`;
        }
      }
    });
    // if (!formData.pitchDescription) {
    //   newErrors.pitchDescription = "Pitch Description is required.";
    // } else if (formData.pitchDescription.length > 1500) {
    //   newErrors.pitchDescription =
    //     "Pitch Description cannot exceed 1500 characters.";
    // }
    // if (!formData.tipsLink) {
    //   newErrors.tipsLink = "Tips Link is required.";
    // }
    // if (formData.tipsLink) {
    //   if (!/^https?:\/\/.*/i.test(formData.tipsLink)) {
    //     newErrors.tipsLink =
    //       "Please enter a valid URL starting with http:// or https://";
    //   }
    // }

    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = " Agree to Terms and Policy is required.";
    }

    if (!formData.generalQuestions) {
      newErrors.generalQuestions = "General Questions is required.";
    } else if (
      formData.generalQuestions === "other" &&
      !formData.generalQuestionsother
    ) {
      newErrors.generalQuestionsother = "Please specificy other option ";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const formdata = new FormData();
      // formdata.append(
      //   "description",
      //   formData.teamDescription != null ? formData.teamDescription : ""
      // );
      // formdata.append("pitch_description", formData.pitchDescription);
      // formdata.append("pitch_tips_links", formData.tipsLink);
      // formdata.append(
      //   "featured_investor_description",
      //   formData.featuredInvestors ? formData.featuredInvestors : ""
      // );

      // formdata.append(
      //   "founderPhoto",
      //   formData.founderphoto != "" ? formData.founderphoto : ""
      // );
      // formdata.append(
      //   "founderDesignation",
      //   formData.founderDesignation != "" &&
      //     formData.founderDesignation != "undefined"
      //     ? formData.founderDesignation
      //     : ""
      // );
      // formdata.append(
      //   "founderDescription",
      //   formData.founderDescription != "" &&
      //     formData.founderDescription != "undefined"
      //     ? formData.founderDescription
      //     : ""
      // );
      formdata.append("hear_about_us", formData.generalQuestions);
      formdata.append(
        "hear_about_us_other",
        formData.generalQuestionsother != null
          ? formData.generalQuestionsother
          : ""
      );
      formdata.append("is_submit", true);

      post(`${apiurl}startups/aboutBusinessScreen2/${Userid}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
            } else {
              Setisdatasaved(true);
              Setisdatasavedonly(false);
              setItem("url", "/startup-profile");
              // navigate("/welcome");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
          // toast("process failed try again");
        });
      // postAddMembers();
    } else {
      const firstErrorKey = Object.keys(newErrors)[0];
      const errorElement = document.getElementById(firstErrorKey);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus();
      }
      return;
    }
  };
  const Savesubmit = e => {
    e.preventDefault();

    console.log("saved clicked");

    setLoadingsave(true);
    const formdata = new FormData();
    // formdata.append(
    //   "description",
    //   formData.teamDescription != null ? formData.teamDescription : ""
    // );
    // formdata.append("pitch_description", formData.pitchDescription);
    // formdata.append("pitch_tips_links", formData.tipsLink);
    // formdata.append(
    //   "featured_investor_description",
    //   formData.featuredInvestors ? formData.featuredInvestors : ""
    // );

    // formdata.append(
    //   "founderPhoto",
    //   formData.founderphoto != "" ? formData.founderphoto : ""
    // );
    // formdata.append(
    //   "founderDesignation",
    //   formData.founderDesignation != "" &&
    //     formData.founderDesignation != "undefined"
    //     ? formData.founderDesignation
    //     : ""
    // );
    // formdata.append(
    //   "founderDescription",
    //   formData.founderDescription != "" &&
    //     formData.founderDescription != "undefined"
    //     ? formData.founderDescription
    //     : ""
    // );
    formdata.append("hear_about_us", formData.generalQuestions);
    formdata.append(
      "hear_about_us_other",
      formData.generalQuestionsother != null
        ? formData.generalQuestionsother
        : ""
    );
    formdata.append("is_submit", false);

    post(`${apiurl}startups/aboutBusinessScreen2/${Userid}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(response => {
        if (!response) {
        } else {
          if (!response?.data?.status) {
            toast.error(response?.data?.message);
          } else {
            Setisdatasavedonly(true);
            toast.success("Data saved succesfully");
            setLoadingsave(false);
            Setisdatasaved(true);
            // setItem("url", "/startup-profile");
            // navigate("/welcome");
          }
        }
      })
      .catch(error => {
        console.log(error.response);
        // toast("process failed try again");
      });
    // postAddMembers();
  };

  useEffect(() => {
    if (isdatasaved) {
      postAddMembers();
    }
  }, [isdatasaved]);
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          const startupData = response?.data?.startups;
          setFormData({
            // teamDescription: startupData?.description,
            // pitchDescription: startupData?.pitch_description!=null?startupData?.pitch_description:"",
            // tipsLink: startupData?.pitch_tips_links,
            // featuredInvestors: startupData?.featured_investor_description!=null?startupData?.featured_investor_description:"",

            // founderphoto: startupData?.founderPhoto,
            founderDesignation:
              startupData?.founderDesignation != "undefined"
                ? startupData?.founderDesignation
                : "",
            // founderDescription:
            //   startupData?.founderDescription != "undefined"
            //     ? startupData?.founderDesignation
            //     : "",
            generalQuestionsother:
              startupData?.hear_about_usother != null
                ? startupData?.hear_about_usother
                : "",
            generalQuestions:
              startupData?.hear_about_us != null
                ? startupData?.hear_about_us
                : "",
            agreeToTerms: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    getMembersData();
  }, []);
  // console.log(formData)
  const addFields = e => {
    e.preventDefault();
    let newfield = { photo: null, bio: "", name: "", designation: "" };
    setInputFields([...inputFields, newfield]);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || About business 2</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Identity and Business Verification
                  </h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4 className="blurred-text">Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Unit Economics and Business Growth
                  </h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Legal Compliance and Declarations
                  </h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4 className="blurred-text">About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">About Business</h5>
                <form>
                  {/* <div className="form-group">
                    <h5 className="mb-12">Team (if applicable)</h5>
               
                  </div> */}

                  {/* <div className="form-group full ">
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>File Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="productLogo"
                            name="founderphoto"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="productLogo"
                          >
                            {getFileName(formData.founderphoto)
                              ? getFileName(formData.founderphoto).slice(-20)
                              : "Founder photo"}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className="form-group">
                    <label htmlFor="">Founder Designation</label>
                    <input
                      type="text"
                      name="founderDesignation"
                      placeholder="Exe: CEO"
                      className="form-control"
                      value={formData.founderDesignation}
                      onChange={handleChange}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <label htmlFor="">Founder Description</label>
                    <textarea
                      name="founderDescription"
                      placeholder="Enter your text here (maximum 1000 characters)"
                      className="form-control"
                      value={formData.founderDescription}
                      onChange={handleChange}
                    ></textarea>
                    {formData.founderDescription ? (
                      <>
                        {" "}
                        {1000 - formData.founderDescription.length} characters
                        remaining
                      </>
                    ) : (
                      ""
                    )}
                  </div> */}
                  {/* {errors.founderDescription && (
                    <p className="error-msg" id="founderDescription">{errors.founderDescription}</p>
                  )} */}
                  <div className="form-group bordertop">
                    <div className="requirement">
                      <div className="requirement-flex">
                        <div className="requirement-text">
                          <p>Requirements</p>
                        </div>
                        <div className="add-btn">
                          <button onClick={addFields}>Add New</button>
                        </div>
                      </div>
                      <div className="custom-file">
                        {inputFields.map((input, index) => (
                          <div key={index}>
                            <h5 className="mb-12">Member {index + 1}</h5>
                            <input
                              type="text"
                              name="name"
                              placeholder="Founder Name"
                              className="form-control"
                              value={input.name}
                              id={`teamMember${index + 1}Name`}
                              onChange={e => handleChange(e, index)}
                            />
                            <input
                              type="text"
                              name="designation"
                              placeholder="Member Designation"
                              className="form-control"
                              value={input.designation}
                              id={`teamMember${index + 1}Designation`}
                              onChange={e => handleChange(e, index)}
                            />
                            <ul className="member-photo">
                              <li>
                                <h6>Upload</h6>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id={`teamMember${index + 1}Photo`}
                                  name="photo"
                                  accept=".svg, .png, .jpg, .jpeg"
                                  onChange={e => handleChange(e, index)}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor={`teamMember${index + 1}Photo`}
                                >
                                  {getFileName(input.photo)
                                    ? getFileName(input.photo).length > 10
                                      ? `${getFileName(input.photo).slice(
                                          0,
                                          10
                                        )}...`
                                      : getFileName(input.photo)
                                    : "Upload File"}
                                </label>
                              </li>
                              <li>
                                <textarea
                                  name="bio"
                                  className="form-control"
                                  placeholder="Bio"
                                  value={input.bio}
                                  onChange={e => handleChange(e, index)}
                                ></textarea>
                              </li>
                            </ul>
                            {errors[`photo${index}`] && (
                              <p className="error-msg" id={`photo${index}`}>
                                {errors[`photo${index}`]}
                              </p>
                            )}
                            {errors[`bio${index}`] && (
                              <p className="error-msg" id={`bio${index}`}>
                                {errors[`bio${index}`]}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="bottomborder"></div>
                  {/* <div className="form-group">
                    <h5 className="mb-12">Pitch</h5>
                    <label htmlFor="">
                      Description<span className="required-sign">*</span>
                    </label>
                    <textarea
                      name="pitchDescription"
                      id="pitchDescription"
                      className="form-control"
                      placeholder="Enter your text here (maximum 1500 characters)"
                      value={formData.pitchDescription}
                      onChange={handleChange}
                    ></textarea>
                    {errors.pitchDescription && (
                      <p className="error-msg">{errors.pitchDescription}</p>
                    )}
                    <p>
                      *Engage investors with your story, growth, team, and
                      vision.
                    </p>
                    <div className="character-counter">
                      {formData.pitchDescription ? (
                        <>
                          {" "}
                          {1500 - formData.pitchDescription.length} characters
                          remaining
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> */}
                  {/* <div className="form-group">
                    <label htmlFor="">
                      Tips Link<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="tipsLink"
                      placeholder="https://"
                      className="form-control"
                      value={formData.tipsLink}
                      onChange={handleChange}
                    />
                    {errors.tipsLink && (
                      <p className="error-msg">{errors.tipsLink}</p>
                    )}
                    <p>*Provide a link to our pitch tips and examples.</p>
                  </div> */}
                  {/* <div className="form-group bordertop">
                    <h5 className="mb-12">Featured Investors</h5>
                    <label htmlFor="">
                      Description <b>(If applicable)</b>
                    </label>
                    <textarea
                      name="featuredInvestors"
                      id="featuredInvestors"
                      placeholder="..."
                      className="form-control"
                      value={formData.featuredInvestors}
                      onChange={handleChange}
                    ></textarea>
                    <p>
                      *List any angel investors, VC firms, and other noteworthy
                      investors.
                    </p>
                  </div> */}

                  <div className="form-group bordertop">
                    <h5>General Questions</h5>
                    <label htmlFor="">
                      How did you hear about us?
                      <span className="required-sign">*</span>
                    </label>
                    <select
                      name="generalQuestions"
                      id="generalQuestions"
                      className="form-control"
                      value={formData.generalQuestions}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        ---Select---
                      </option>
                      <option value="search-engine">
                        Search Engine (e.g., Google, Bing)
                      </option>
                      <option value="social-media">
                        Social Media (e.g., Facebook, Instagram)
                      </option>
                      <option value="friend-family">Friend or Family</option>
                      <option value="online-advertisement">
                        Online Advertisement
                      </option>
                      <option value="news-article-blog">
                        News Article or Blog
                      </option>
                      <option value="email-campaign">Email Campaign</option>
                      <option value="event-webinar">Event or Webinar</option>
                      <option value="youtube">YouTube</option>
                      <option value="podcast">Podcast</option>
                      <option value="other">Other (please specify)</option>
                    </select>
                    {errors.generalQuestions && (
                      <p className="error-msg">{errors.generalQuestions}</p>
                    )}
                  </div>
                  {formData.generalQuestions == "other" && (
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Specify other option"
                        className="form-control"
                        name="generalQuestionsother"
                        id="generalQuestionsother"
                        value={formData.generalQuestionsother}
                        onChange={handleChange}
                      />
                      {errors.generalQuestionsother && (
                        <p className="error-msg">
                          {errors.generalQuestionsother}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="form-group checkbox">
                    <label className="flex">
                      I agree to the{" "}
                      <a
                        href="/terms&conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service{" "}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                      />
                      {errors.agreeToTerms && (
                        <p className="error-msg">{errors.agreeToTerms}</p>
                      )}
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="btn-box flex space-between">
                    <Link to="/about-business1" className="cta-btn back-btn">
                      Back
                    </Link>
                    <button
                      type="submit"
                      className="cta-btn blue-btn"
                      onClick={e => Savesubmit(e)}
                    >
                      {loadingsave ? (
                        <div style={{ marginLeft: "-30%" }}>
                          <Loaderbutton />{" "}
                        </div>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      type="submit"
                      className="cta-btn blue-btn"
                      onClick={e => handleSubmit(e)}
                    >
                      {loading ? (
                        <div style={{ marginLeft: "-30%" }}>
                          <Loaderbutton />{" "}
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep9;
