/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState,useRef } from "react";
import Footer2 from "../../common/Footer2";
import Header2 from "../../common/Header2";
import { apiurl } from "../Url";
import { Link } from "react-router-dom";
// import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { investordashboard } from "../../Routes/Routes";
import { get } from "../../services/services";
import { getItem } from "../../common/reusable";
import { PDFDocument } from "pdf-lib";
import Loaderbutton from "../../common/loaderbutton";
function InvestorPrivacydownload() {
  const [privacy, setPrivacy] = useState("");
  const [privacylocation, setPrivacylocation] = useState({});
  const [userid, setUserid] = useState(localStorage.getItem("UserId"));
  const [loading, Setloading] = useState(true);
  const userType = getItem("userType");
  const [pdfData, setPdfData] = useState(null);
  const [loadingbutton,SetLoadingbutton]=useState(false);
  useEffect(() => {
    const cleanUserid = String(userid).replace(/['"]+/g, "");
    // let apifetchurl;

    // if (userType != "StartUp") {
    //   apifetchurl = `${apiurl}investors/getContractPdf/${cleanUserid}`;
    // } else {
    //   apifetchurl = `${apiurl}startups/getContractPdf/${cleanUserid}`;
    // }
  
      get(`${apiurl}investors/getContractPdf/${cleanUserid}`)
      .then((response) => {
        if (!response) {
          
        } else {
          const locationData = JSON.parse(response.data.data.location);
          setPrivacylocation(locationData);
          setPrivacy(response.data.data);
         
        }
      
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  const pdfRef = useRef(null);

  const generatePDF = async () => {
    Setloading(true);
    const pdfElement = pdfRef.current;
  
    const canvas = await html2canvas(pdfElement, {
      scale: 1.5,
      backgroundColor: "#ffffff",
    });
  
    const imgData = canvas.toDataURL("image/png");
    const pdfDoc = await PDFDocument.create();
    const pngImage = await pdfDoc.embedPng(imgData);
    const imgDims = pngImage.scale(1);
  
    const page = pdfDoc.addPage([imgDims.width, imgDims.height]);
    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: imgDims.width,
      height: imgDims.height,
    });
  
 
  
    const pdfBytes = await pdfDoc.save();
    setPdfData(pdfBytes);
    setTimeout(() => {
      Setloading(false);
    }, 2000);
  };
  
  

  const downloadPDF = () => {
    // console.log(pdfData)
    if (!pdfData) return;
SetLoadingbutton(true)
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'privacy_contractual_agreement.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); 
    SetLoadingbutton(false)
  };

  
  useEffect(() => {
    generatePDF();
  }, [privacy]);



  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Privacy contract</title>
      </Helmet>
      <div className="bredcrum-sec">
        <div className="container-sm">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Non Circumvention Contract</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={investordashboard}>Startups business List</Link>
                </li>
                <li>Non Circumvention Contract</li>
              </ul>
            </div>
            <button className="cta-btn" onClick={downloadPDF}>
            {loadingbutton ? (
                      <div style={{ marginLeft: "-30%" }}>
                      <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Download as PDF"
                    )}
              
            </button>
          </div>
        </div>
      </div>
      <section className="privacy-Sec" ref={pdfRef}  id="privacy-content">
        <div className="container-sm">
          <div className="content-box">
            <div className="content-inner-box">
            <div className="logopctop">
              <img src="src/images/pctop.png" alt="" />
              </div>
              <div className="logopcbottom">
              <img src="src/images/pcbottom.png" alt="" />
              </div>
              <div className="logo">
                <a href="/">
                  <img src="src/images/site-logo.svg" alt="" />
                </a>
              </div>
              <h2>{privacy?.title}</h2>

              <p
                dangerouslySetInnerHTML={{
                  __html: privacy?.message,
                }}
              ></p>
                 {
                  userType != "StartUp"?(""):(<>    <h4>Signature </h4>
                    <div
                      style={{
                        borderBottom: "none",
                        paddingBottom: "10px",
                        fontSize: "20px",
                      }}
                      className="sign-box"
                    >
                      <img className="retry downloadsign" 
                        src={`data:image/png;base64,${privacy?.base64}`} alt="" />
                    </div></>)}
          
              <div className="privacycontract-ip">
                <h4>Ip address:</h4>
                <p>{privacy?.ip}</p>
              </div>
              <div className="privacycontract-ip">
                <h4>User location:</h4>
                <p>{privacylocation?.region}</p>
              </div>
              <div className="privacycontract-ip">
                <h4>Date and Time:</h4>
                <p>{privacy?.timestamp}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default InvestorPrivacydownload;
