import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer2 from "../../common/Footer2";
import Header from "../../common/Header";
import Header2 from "../../common/Header2";
import Loader from "../../common/Loader";
import { getItem } from "../../common/reusable";
import { apiurl } from "../Url";
import "ckeditor5/ckeditor5.css";

function Privacy() {
  const [getPrivacy, setGetPrivacy] = useState("");
  const [loading, Setloading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${apiurl}visitor/getPrivacy`)
      .then(response => {
        setGetPrivacy(response.data.data);
        Setloading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" ,backgroundColor:'#fff'}}
      >
        <Loader />
      </div>
      {getItem("token") !== "" ? <Header2 /> : <Header />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Privacy policy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="dashboard-sec term-condition-page">
        <div className="container">
          <div className="bredcrum-box flex align-center space-between">
            <div className="left-box">
              <h5>Privacy Policy</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>
          <div className="row-box">
            <div className="shadow-box">
              <h3>Privacy Policy</h3>
              <div className="ck-content">
                <p dangerouslySetInnerHTML={{ __html: getPrivacy.message }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default Privacy;
