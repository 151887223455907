import axios from "axios";
import "ckeditor5/ckeditor5.css";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer2 from "../../common/Footer2";
import Header from "../../common/Header";
import Header2 from "../../common/Header2";
import Loader from "../../common/Loader";
import { getItem } from "../../common/reusable";
import { apiurl } from "../Url";

function Terms() {
  const [termsConditions, setTermsConditions] = useState("");
  const [loading, Setloading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${apiurl}visitor/getTerms`)
      .then(response => {
        setTermsConditions(response.data.data);
        Setloading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader background="white"/>
      </div>
      {getItem("token") !== "" ? <Header2 /> : <Header />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Terms & conditions</title>
      </Helmet>
      <section className="dashboard-sec term-condition-page">
        <div className="container">
          <div className="bredcrum-box flex align-center space-between">
            <div className="left-box">
              <h5>Terms & Conditions</h5>
              <ul className="flex">
                <li className="tremsbreada">
                  <Link to="/">Home</Link>
                </li>
                <li className="tremsbread">Terms & Conditions</li>
              </ul>
            </div>
          </div>
          <div className="row-box">
            <div className="shadow-box">
              <h3>Terms & Conditions</h3>
              <div className="ck-content">
                <p
                  dangerouslySetInnerHTML={{ __html: termsConditions.message }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default Terms;
