/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function PasswordChange() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  // console.log(token)
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [errors, setErrors] = useState("");
  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "changePassword") {
      setChangePassword(value);
    }
    setErrors(value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    const newErrors = {};
    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.length < 8) {
      newErrors.password = "Please enter more then 8 character.";
    }
    if (!changePassword) {
      newErrors.changePassword = "Confirm Password is required.";
    } else if (password !== changePassword) {
      newErrors.changePassword = "Passwords do not match.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        axios
          .post(`${apiurl}users/resetPassword/?token=${token}`, {
            password: password,
            confirmPassword: changePassword,
          })
          .then(res => {
            if (!res.data.status) {
              toast.error(res.data.message);
            } else {
              toast.success(res?.data?.message);
              navigate("/password-change-success");
            }
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
  };
  const passWordConditions = type => {
    if (type == 1) {
      return password.length >= 8;
    }
    if (type == 2) {
      return (
        (password.length >= 8 && /[A-Z]/.test(password)) ||
        (password.length >= 8 && /[!@#$%^&*(),.?":{}|<>]/.test(password))
      );
    }
    if (type == 3) {
      return (
        password.length >= 8 &&
        /[A-Z]/.test(password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(password)
      );
    }
    if (type == 4) {
      return (
        password.length >= 10 &&
        /[A-Z]/.test(password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(password)
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Change password</title>
      </Helmet>
      <section className="create-account-sec password-change">
        <div className="container">
          <div className="row flex">
            <div className="form-box w-100 m-auto">
              <div className="form-wrapper">
                <div className="top-comman-text text-center">
                  <a href="/">
                    <img src="src/images/site-logo.svg" alt="" />
                  </a>
                  <h4>Change Password</h4>
                  {/* <p>
                    Password Should contain Min 8 characters atleast one
                    Uppercase and Symbol{" "}
                  </p> */}
                </div>
                <form action="">
                  <div className="form-group">
                    <label htmlFor=""> New Password</label>
                    <input
                      type="password"
                      placeholder="************"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="error-msg">{errors.password}</p>
                    )}
                    <div className="progress-bar flex align-center space-between">
                      <div
                        style={
                          passWordConditions(1) &&
                          !passWordConditions(2) &&
                          !passWordConditions(3) &&
                          !passWordConditions(4)
                            ? { backgroundColor: "red" }
                            : passWordConditions(2) &&
                              !passWordConditions(3) &&
                              !passWordConditions(4)
                            ? { backgroundColor: "orange" }
                            : passWordConditions(3) && !passWordConditions(4)
                            ? {}
                            : {}
                        }
                        className={`progress-bar-line ${
                          password.length >= 8 ? "active" : ""
                        }`}
                      ></div>

                      <div
                        style={
                          passWordConditions(2) &&
                          !passWordConditions(3) &&
                          !passWordConditions(4)
                            ? { backgroundColor: "orange" }
                            : {}
                        }
                        className={`progress-bar-line ${
                          (password.length >= 8 && /[A-Z]/.test(password)) ||
                          (password.length >= 8 &&
                            /[!@#$%^&*(),.?":{}|<>]/.test(password))
                            ? "active"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`progress-bar-line ${
                          password.length >= 8 &&
                          /[A-Z]/.test(password) &&
                          /[!@#$%^&*(),.?":{}|<>]/.test(password)
                            ? "active"
                            : ""
                        }`}
                      ></div>
                      <div
                        className={`progress-bar-line ${
                          password.length >= 10 &&
                          /[A-Z]/.test(password) &&
                          /[!@#$%^&*(),.?":{}|<>]/.test(password)
                            ? "active"
                            : ""
                        }`}
                      ></div>
                    </div>
                    <h5 style={{ display: password?.length > 0 ? "" : "none" }}>
                      {passWordConditions(1) &&
                      !passWordConditions(2) &&
                      !passWordConditions(3) &&
                      !passWordConditions(4)
                        ? "Weak"
                        : passWordConditions(2) &&
                          !passWordConditions(3) &&
                          !passWordConditions(4)
                        ? "Moderate"
                        : passWordConditions(3) && !passWordConditions(4)
                        ? "Stong"
                        : passWordConditions(4)
                        ? "Very Strong"
                        : ""}
                    </h5>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="password"
                      placeholder="************"
                      className="form-control"
                      name="changePassword"
                      value={changePassword}
                      onChange={handleChange}
                    />
                    {errors.changePassword && (
                      <p className="error-msg">{errors.changePassword}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <button onClick={handleSubmit} className="cta-btn">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default PasswordChange;
