/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function InvestorSignupStep3() {
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [toottipbank, SetTooltipbank] = useState(false);
  const [toottipincome, SetTooltipincome] = useState(false);
  const [toottipassest, SetTooltipassest] = useState(false);
  const [toottipcertificate, SetTooltipcertificate] = useState(false);
  const [optionselectedpervious, setOptionslectedprevious] = useState(false);
  const [loadingsave, setLoadingsave] = useState(false);

  const [formData, setFormData] = useState({
    bankStatements: "",
    incomeTaxReturns: "",
    proofOfAssets: "",
    accreditationCertificate: "",
    previousInvestment: "",
    previousInvestmentlink: "",
    previousinvestmentFile: "",
  });
  const [fileNames, setFileNames] = useState({
    bankStatements: "",
    incomeTaxReturns: "",
    proofOfAssets: "",
    accreditationCertificate: "",
    previousinvestmentFile: "",
  });
  const [errors, setErrors] = useState({});
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const handleChangelink = e => {
    const { name, value } = e.target;
    setFormData(previousData => ({
      ...previousData,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleChange = e => {
    // console.log("file");
    const { name, files } = e.target;

    const file = files[0];
    const maxSize = 5 * 1024 * 1024;

    if (file.size > maxSize) {
      toast.warn("File size should not exceed 5MB", {
        position: "top-center",
      });
    } else {
      setFormData({
        ...formData,
        [name]: file,
      });
      setFileNames({
        ...fileNames,
        [name]: file.name,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = (e, isFromSave) => {
    e.preventDefault();

    const newErrors = {};
    if (!isFromSave) {
      if (!formData.previousInvestment) {
        newErrors.previousInvestment = "Please choose one of them";
      }

      if (
        !formData.bankStatements &&
        !fileNames.bankStatements &&
        !formData.incomeTaxReturns &&
        !fileNames.incomeTaxReturns &&
        !formData.proofOfAssets &&
        !fileNames.proofOfAssets &&
        !formData.accreditationCertificate &&
        !fileNames.accreditationCertificate &&
        formData.previousInvestment === "No"
      ) {
        newErrors.bankStatements =
          "Upload at least one of the required documents";
      }
      if (
        formData.previousInvestment != "No" &&
        formData.previousInvestment != "" &&
        !formData.previousInvestmentlink &&
        !fileNames.previousinvestmentFile
      ) {
        newErrors.previousInvestmentlink =
          "Please provide either a previous investment link or file.";
      }

      if (formData.previousInvestmentlink) {
        if (!/^https?:\/\/.*/i.test(formData.previousInvestmentlink)) {
          newErrors.previousInvestmentlink =
            "Previous Investment link must start with https:// or http:// ";
        }
      }
      setErrors(newErrors);
    }

    if (Object.keys(newErrors).length === 0 || isFromSave) {
      isFromSave ? setLoadingsave(true) : setLoading(true);
      const data = new FormData();
      data.append(
        "bank_statement",
        formData.bankStatements ? formData.bankStatements : ""
      );
      data.append(
        "income_tax_return",
        formData.incomeTaxReturns ? formData.incomeTaxReturns : ""
      );
      data.append(
        "proof_of_assets",
        formData.proofOfAssets ? formData.proofOfAssets : ""
      );
      data.append(
        "accreditation_certificate",
        formData.accreditationCertificate
          ? formData.accreditationCertificate
          : ""
      );
      data.append(
        "investedinfiveyears",
        formData.previousInvestment ? formData.previousInvestment : ""
      );
      data.append(
        "previous_prooflink",
        formData.previousInvestmentlink ? formData.previousInvestmentlink : ""
      );
      data.append(
        "previous_prooffile",
        formData.previousinvestmentFile ? formData.previousinvestmentFile : ""
      );
      data.append("is_submit", isFromSave ? "false" : "true");

      //

      post(`${apiurl}investors/accreditations/${Userid}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
            } else {
              if (isFromSave) {
                toast.success("Data saved succesfully");
              } else {
                setItem("url", "/additional-information");
                navigate("/additional-information");
              }
            }
          }
          isFromSave ? setLoadingsave(false) : setLoading(false);
        })
        .catch(error => {
          console.log(error.response);
          isFromSave ? setLoadingsave(false) : setLoading(false);
        });
    } else {
      const firstErrorKey = Object.keys(newErrors)[0];
      const errorElement = document.getElementById(firstErrorKey);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus();
      }
      return;
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}users/getInvestorProfile/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          setFileNames({
            bankStatements: response?.data?.user?.bank_statement,
            incomeTaxReturns: response?.data?.user?.income_tax_return,
            proofOfAssets: response?.data?.user?.proof_of_assets,
            accreditationCertificate:
              response?.data?.user?.accreditation_certificate,
            previousinvestmentFile: response?.data?.user?.previous_prooffile,
            //
          });
          setFormData(previousData => ({
            ...previousData,
            previousInvestment: response?.data?.user?.investedinfiveyears,
            previousInvestmentlink: response?.data?.user?.previous_prooflink,
            //
          }));
          setOptionslectedprevious(
            response?.data?.user?.investedinfiveyears !== "" ? true : false
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const openTooltipbank = () => {
    timerRef.current = setTimeout(() => SetTooltipbank(true), 300);
  };

  const closeTooltipbank = () => {
    clearTimeout(timerRef.current);
    SetTooltipbank(false);
  };

  const openTooltipincome = () => {
    timerRef.current = setTimeout(() => SetTooltipincome(true), 300);
  };

  const closeTooltipincome = () => {
    clearTimeout(timerRef.current);
    SetTooltipincome(false);
  };

  const openTooltipassest = () => {
    timerRef.current = setTimeout(() => SetTooltipassest(true), 300);
  };

  const closeTooltipassest = () => {
    clearTimeout(timerRef.current);
    SetTooltipassest(false);
  };

  const openTooltipcertificate = () => {
    timerRef.current = setTimeout(() => SetTooltipcertificate(true), 300);
  };

  const closeTooltipcertificate = () => {
    clearTimeout(timerRef.current);
    SetTooltipcertificate(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Financial standing accreditation</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Personal and Investment Profile
                  </h4>
                  <p>Build Your Investment Profile</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4 className="blurred-text">Identity Verification</h4>
                  <p>Verify Your Identity</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Financial Standing and Accreditation</h4>
                  <p>Confirm Your Financial Status</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Additional Information</h4>
                  <p>Enter Additional Information</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">
                  Financial Standing and Accreditation
                </h5>
                <div className="form-group custom-radio-btn">
                  <h5 className="signupquestionh5">
                    Have you invested in any startups within the last 5 years?{" "}
                    <span className="required-sign">*</span>
                  </h5>

                  <ul>
                    <li>
                      <label className="flex">
                        Yes
                        <input
                          type="radio"
                          name="roleInInvestments"
                          value="Yes"
                          checked={
                            formData.previousInvestment === "Yes" ? true : false
                          }
                          onChange={() => {
                            setFormData(previousData => ({
                              ...previousData,
                              previousInvestment: "Yes",
                            }));
                            setOptionslectedprevious(true);
                            setErrors(previousData => ({
                              ...previousData,
                              previousInvestment: "",
                            }));
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="flex">
                        No
                        <input
                          type="radio"
                          name="roleInInvestments"
                          value="No"
                          checked={
                            formData.previousInvestment === "No" ? true : false
                          }
                          onChange={() => {
                            setFormData(previousData => ({
                              ...previousData,
                              previousInvestment: "No",
                            }));
                            setOptionslectedprevious(true);
                            setErrors(previousData => ({
                              ...previousData,
                              previousInvestment: "",
                            }));
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  {errors.previousInvestment && (
                    <p className="error-msg" id="roleInInvestments">
                      {errors.previousInvestment}
                    </p>
                  )}
                </div>

                {optionselectedpervious &&
                  (formData.previousInvestment === "No" ? (
                    <>
                      <form action="">
                        <div className="Eligibility-section">
                          <div className="Eligibility-icon">
                            <img src="src/images/vectors.png" alt="" />
                          </div>
                          <div className="Eligibility-text">
                            <p>
                              Eligibility requires a minimum of 50 lakhs in a
                              bank account, 2 crores in gross income, or 3
                              crores in net assets.{" "}
                              <strong>
                                Please submit any one of the document below.
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div className="form-group full">
                          <h5 className="mb-0">Bank Statements</h5>
                          <p className="mb-12">
                            Recent bank statements (last 3-6 months) can help
                            verify the liquidity and financial health of the
                            investor.
                          </p>
                          <div className="custom-file">
                            <ul>
                              <li
                                onMouseEnter={() => openTooltipbank()}
                                onMouseLeave={() => closeTooltipbank()}
                              >
                                <h6>
                                  bank statements{" "}
                                  <small>(last 3-6 months)</small>
                                </h6>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="passport"
                                  name="bankStatements"
                                  onChange={handleChange}
                                  accept=".xlsx,.pdf"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="passport"
                                >
                                  {fileNames.bankStatements
                                    ? fileNames.bankStatements.length > 20
                                      ? `${fileNames.bankStatements.slice(
                                          0,
                                          20
                                        )}...`
                                      : fileNames.bankStatements
                                    : "Upload File"}
                                </label>
                              </li>
                            </ul>
                            {toottipbank && (
                              <div className="tooltip-container fileadvicetootltip bankadvancetooltip">
                                <div className="tooltiptext">
                                  <div className="tooltip-arrow"></div>
                                  File type allowed-Excel and Pdf
                                  <br />
                                  Maximum Size File 5Mb
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group full">
                          <h5 className="mb-0">Income Tax Returns </h5>
                          <p className="mb-12">
                            Latest income tax returns to verify annual income,
                            which is useful in assessing the investor's
                            financial capacity.
                          </p>
                          <div className="custom-file">
                            <ul>
                              <li
                                onMouseEnter={() => openTooltipincome()}
                                onMouseLeave={() => closeTooltipincome()}
                              >
                                <h6>Latest Income Tax Returns</h6>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="latestincome"
                                  name="incomeTaxReturns"
                                  onChange={handleChange}
                                  accept=".svg, .png, .jpg, .jpeg, .pdf"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="latestincome"
                                >
                                  {fileNames.incomeTaxReturns
                                    ? fileNames.incomeTaxReturns.length > 20
                                      ? `${fileNames.incomeTaxReturns.slice(
                                          0,
                                          20
                                        )}...`
                                      : fileNames.incomeTaxReturns
                                    : "Upload File"}
                                </label>
                              </li>
                            </ul>
                            {toottipincome && (
                              <div className="tooltip-container fileadvicetootltip">
                                <div className="tooltiptext">
                                  <div className="tooltip-arrow"></div>
                                  File type allowed- Png , Jpg , Jpeg , Svg ,
                                  Pdf
                                  <br />
                                  Maximum Size File 5Mb
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group full">
                          <h5 className="mb-0">Proof of Assets </h5>
                          <p className="mb-12">
                            Documents such as real estate ownership
                            certificates, investment portfolio statements, etc.,
                            can help in assessing the net worth of the investor.
                          </p>
                          <div className="custom-file">
                            <ul>
                              <li
                                onMouseEnter={() => openTooltipassest()}
                                onMouseLeave={() => closeTooltipassest()}
                              >
                                <h6>ownership certificates</h6>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="ownership"
                                  name="proofOfAssets"
                                  onChange={handleChange}
                                  accept=".svg, .png, .jpg, .jpeg, .pdf"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="ownership"
                                >
                                  {fileNames.proofOfAssets
                                    ? fileNames.proofOfAssets.length > 20
                                      ? `${fileNames.proofOfAssets.slice(
                                          0,
                                          20
                                        )}...`
                                      : fileNames.proofOfAssets
                                    : "Upload File"}
                                </label>
                              </li>
                            </ul>
                            {toottipassest && (
                              <div className="tooltip-container fileadvicetootltip">
                                <div className="tooltiptext">
                                  <div className="tooltip-arrow"></div>
                                  File type allowed - Png , Jpg , Jpeg , Svg ,
                                  Pdf
                                  <br />
                                  Maximum Size File 5Mb
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group full">
                          <h5 className="mb-0">Accreditation Certificate</h5>
                          <p className="mb-12">
                            In countries like the USA, certain investments are
                            restricted to accredited investors only. While India
                            does not have a formal accreditation process like
                            the U.S., documents that prove high net worth or
                            significant income can serve a similar purpose.
                          </p>
                          <div className="custom-file">
                            <ul>
                              <li
                                onMouseEnter={() => openTooltipcertificate()}
                                onMouseLeave={() => closeTooltipcertificate()}
                              >
                                <h6>Accreditation Certificate</h6>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="accreditation"
                                  name="accreditationCertificate"
                                  onChange={handleChange}
                                  accept=".svg, .png, .jpg, .jpeg, .pdf"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="accreditation"
                                >
                                  {fileNames.accreditationCertificate
                                    ? fileNames.accreditationCertificate
                                        .length > 20
                                      ? `${fileNames.accreditationCertificate.slice(
                                          0,
                                          20
                                        )}...`
                                      : fileNames.accreditationCertificate
                                    : "Upload File"}
                                </label>
                              </li>
                            </ul>
                            {toottipcertificate && (
                              <div className="tooltip-container fileadvicetootltip">
                                <div className="tooltiptext">
                                  <div className="tooltip-arrow"></div>
                                  File type allowed- Png , Jpg , Jpeg , Svg ,
                                  Pdf
                                  <br />
                                  Maximum Size File 5Mb
                                </div>
                              </div>
                            )}
                            {errors.bankStatements && (
                              <p className="error-msg" id="bankStatements">
                                {errors.bankStatements}
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <form>
                        <div className="form-group full">
                          <h5 className="mb-0">
                            Previous Investment Proof{" "}
                            <span className="required-sign">*</span>{" "}
                          </h5>
                          <p className="mb-12">
                            Please provide a link to a press release, article,
                            or investment document that verifies your
                            investment.
                          </p>
                          <div className="custom-file">
                            <ul>
                              <li
                                onMouseEnter={() => openTooltipcertificate()}
                                onMouseLeave={() => closeTooltipcertificate()}
                              >
                                <h6>Previous Investment Proof file</h6>
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="previousinvestment"
                                  name="previousinvestmentFile"
                                  onChange={handleChange}
                                  accept=".svg, .png, .jpg, .jpeg, .pdf"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="previousinvestment"
                                >
                                  {fileNames.previousinvestmentFile
                                    ? fileNames.previousinvestmentFile.length >
                                      20
                                      ? `${fileNames.previousinvestmentFile.slice(
                                          0,
                                          20
                                        )}...`
                                      : fileNames.previousinvestmentFile
                                    : "Upload File"}
                                </label>
                              </li>
                            </ul>
                            {toottipcertificate && (
                              <div className="tooltip-container fileadvicetootltip">
                                <div className="tooltiptext">
                                  <div className="tooltip-arrow"></div>
                                  File type allowed- Png , Jpg , Jpeg , Svg ,
                                  Pdf
                                  <br />
                                  Maximum Size File 5Mb
                                </div>
                              </div>
                            )}
                            {errors.previousinvestmentFile && (
                              <p
                                className="error-msg"
                                id="previousinvestmentFile"
                              >
                                {errors.previousinvestmentFile}
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Previous Investment Link</label>
                            <input
                              type="text"
                              placeholder="https://"
                              className="form-control"
                              name="previousInvestmentlink"
                              id="previousInvestmentlink"
                              value={formData.previousInvestmentlink}
                              onChange={handleChangelink}
                            />
                            {errors.previousInvestmentlink && (
                              <p className="error-msg">
                                {errors.previousInvestmentlink}
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </>
                  ))}

                <div className="btn-box flex space-between">
                  <Link
                    to="/identity-verification"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link
                    onClick={e => handleSubmit(e, true)}
                    className="cta-btn blue-btn"
                  >
                    {loadingsave ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Link>
                  <Link
                    onClick={e => handleSubmit(e, false)}
                    className="cta-btn blue-btn"
                  >
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default InvestorSignupStep3;
