/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

import { apiurl } from "../maincomponent/Url";
import { post } from "../services/services";

const Checkout = props => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      card: cardExpiryElement,
      card: cardCvcElement,
    });

    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = {
          stripeId: paymentMethod?.id,
          amount: props?.amount,
          user_id: props?.investorid,
        };

        // const requestOptions = {

        //   body: raw,

        // };

        let response;
        try {
          response = await post(`${apiurl}users/pay`, raw);
          if (response?.message) {
            setErrorMessage(response?.message);
          }
        } catch (error) {
          if (error.response && error.response.status === 500) {
            setErrorMessage('Internal Server Error. / Amount must be at least $0.50 usd.');
          } else if (response?.message) {
            setErrorMessage(response?.message);
          } else if (error.response && error.response.data && error.response.data.message) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage('An unexpected error occurred.');
          }
        }

        const result = response;
        const client_secret = result?.data?.clientSecret;

        const { error, paymentIntent } = await stripe.confirmCardPayment(
          client_secret,
          {
            payment_method: paymentMethod.id,
          }
        );

        if (error) {
          setErrorMessage("Payment failed. Please try again.");
        } else {
          try {
            post(`${apiurl}investors/diligence`, {
              name: props?.name,
              email: props?.email,
              phoneNo: props?.phoneNo,
              note: props?.note,
              user_id: props?.user_id,
              investor_id: props?.investorid,
              startup_id: props?.startupId
            })
              .then(response => {
                if (!response) {
                } else {
                  if (!response.data.status) {
                    toast.error(response.data.message);
                  } else {
                    toast.success("Payment successful.");
                    props?.onFormSubmit();
                  }

                }
              })
              .catch(error => {

              });
          } catch (error) { }
        }
      } catch (error) {
        // props?.onFormSubmit();
      }

      setLoading(false);
    }
  };

  const canclebutton = e => {
    e.preventDefault();
    props?.onFormSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="group">
        <div className="form-group">
          <label>
            Card Number <span className="error">*</span>
          </label>
          <CardNumberElement
            className="form-control"
            onChange={event => {
              setTimeout(() => {
                document
                  .querySelectorAll(".form-control.payformd")
                  .forEach(function (i) {
                    if (i.classList.contains("StripeElement--complete")) {
                      i.classList.remove("error-active");
                    }
                  });
              }, 500);
            }}
          />
        </div>

        <div className="form-group">
          <label>
            Expiry <span className="error">*</span>
          </label>
          <CardExpiryElement
            className="form-control payformd"
            onChange={() => {
              setTimeout(() => {
                document
                  .querySelectorAll(".form-control.payformd")
                  .forEach(function (i) {
                    if (i.classList.contains("StripeElement--complete")) {
                      i.classList.remove("error-active");
                    }
                  });
              }, 500);
            }}
          />
        </div>

        <div className="form-group">
          <label>
            CVC <span className="error">*</span>
          </label>
          <CardCvcElement
            className="form-control"
            onChange={() => {
              setTimeout(() => {
                document
                  .querySelectorAll(".form-control.payformd")
                  .forEach(function (i) {
                    if (i.classList.contains("StripeElement--complete")) {
                      i.classList.remove("error-active");
                    }
                  });
              }, 500);
            }}
          />
        </div>
        {errorMessage && <div className="error-msg">{errorMessage}</div>}
        <div className="btn-group flex">
          <button className="cta-btn" disabled={!stripe || loading}>
            {loading ? "Processing..." : "Pay"}
          </button>
          <a className="cta-btn border" onClick={e => canclebutton(e)}>
            Cancel
          </a>
        </div>
      </div>
    </form>
  );
};

export default Checkout;
