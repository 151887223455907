/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header2 from "../../common/Header2";
import Footer2 from "../../common/Footer2";
import { apiurl } from "../Url";
import { states, Industries } from "../../common/localStates";
import { HandleChange, getItem } from "../../common/reusable";
import { investorprivacy, investorprofile } from "../../Routes/Routes";
import { post, get } from "../../services/services";
import Loader from "../../common/Loader";
import Profile from "../../common/getProfile";

function InvestorDashboard() {
  const navigate = useNavigate();
  const [showstartup, Setshowstartup] = useState(12);
  const [activeTab, setActiveTab] = useState(1);
  const [businessList, setBusinessList] = useState("");
  const [gsearch, setgsearch] = useState("");
  const [loading, Setloading] = useState(false);
  const [clearsearch, Setclearsearch] = useState(true);
  // const [issearch, SetIssearch] = useState(true);
  // const [historyData, sethistoryData] = useState([]);
  const [checkwishlist, setcheckWishlist] = useState(false);
  const [bestmatch, setbestMatch] = useState(false);
  const [checkyear, setcheckYear] = useState(false);
  const [checknearme, setCheckNearme] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState({
    sector: "",
    state: "",
    profit: "",
    year: "",
    minRevenue: "",
    maxRevenue: "",
  });
  const [mimi, setMimi] = useState();
  const [filterStatus, setFilterStatus] = useState(false);

  const [status, setStatus] = useState({
    status: "",
    contract: "",
  });
  const investorID = getItem("UserId");
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  const toggleFavourites = (e, type, id) => {
    e.preventDefault();
    // Setloading(true);
    if (type == "add") {
      try {
        post(`${apiurl}investors/addToWishList`, {
          investorId: investorID,
          startUpId: id,
        })
          .then(res => {
            if (!res) {
            } else {
              if (!res.data.status) {
                toast.error(res.data.message);
              } else {
                getStartupByAdvanceFilter();
                toast("Startup added successfully to favourites");
              }
            }
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err);
      }
    } else if (type == "delete") {
      try {
        post(`${apiurl}investors/deleteWishList`, {
          investorId: investorID,
          startupId: id,
        })
          .then(res => {
            if (!res) {
            } else {
              if (!res.data.status) {
                toast.error(res.data.message);
              } else {
                getStartupByAdvanceFilter();
                toast("Startup removed from  favourites");
              }
            }
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getStartupByAdvanceFilter = () => {
    if (
      advanceFilter?.minRevenue !== "" &&
      advanceFilter?.maxRevenue !== "" &&
      Number(advanceFilter?.minRevenue) > Number(advanceFilter?.maxRevenue)
    ) {
      toast?.error("Min Revenue should be less than Max Revenue");
      return;
    }
    // if (advanceFilter.profit !== "" && advanceFilter?.year == "") {
    //   toast?.error("Year should be selected");
    //   return;
    // }
    const stateValue =
      advanceFilter && advanceFilter.state
        ? advanceFilter.state.replace(/\+/g, " ")
        : "";

    Setloading(true);
    try {
      get(`${apiurl}investors/getStartUpList/${getItem("UserId")}`, {
        sector: advanceFilter?.sector,
        location: stateValue,
        profit:
          advanceFilter && advanceFilter.profit
            ? `${advanceFilter.profit}`
            : "",
        year: advanceFilter?.year,
        minRevenue: advanceFilter?.minRevenue,
        maxRevenue: advanceFilter?.maxRevenue,
        search: gsearch,
        check_wishlist: checkwishlist ? checkwishlist : "",
        businessyear: checkyear ? "2" : "",
        investment_focus: bestmatch ? bestmatch : "",
        longitude: address.long,
        latitude: address?.lat,
      })
        .then(res => {
          if (!res) {
          } else {
            if (res?.data?.startups) {
              const startup = res?.data?.startups?.filter(
                data =>
                  data?.user_id?.status == "Active" ||
                  data?.user_id?.status == "Activated"
              );
              setBusinessList(startup);
              Setloading(false);
              Setclearsearch(true);
            } else if (
              !res?.data?.status &&
              res?.data?.message === "No startups found"
            ) {
              Setloading(false);
              setBusinessList();
              // SetIssearch(false);
            }
          }
        })
        .catch(err => {
          Setloading(false);
          console.log(err);
        });
    } catch (err) {
      Setloading(false);
      console.log(err);
    }
  };
  const getStatus = () => {
    try {
      get(`${apiurl}investors/giveStatus/${getItem("UserId")}`)
        .then(res => {
          if (!res) {
          } else {
            setStatus({
              ...status,
              status: res?.data?.data?.status,
              contract: res?.data?.data?.contractStatus,
            });
            if (
              (res?.data?.data?.status == "Activated" ||
                res?.data?.data?.status == "Active") &&
              res?.data?.data?.contractStatus == "Activated"
            ) {
              getStartupByAdvanceFilter();
            }
            getStartupByAdvanceFilter();
          }
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  const truncateDescription = (description, length) => {
    if (!description) {
      return "";
    }
    if (description.length > length) {
      return description.substring(0, length) + "...";
    }
    return description;
  };
  useEffect(() => {
    getStatus();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (userType != null) {
      if (userType != "Investor") {
        navigate("/");
      }
    }
  }, []);
  const getsinglestartupdetails = (e, id, _id) => {
    e.preventDefault();
    navigate("/investor-view-details", {
      state: { user_id: id, member_id: _id },
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const apiKey = process.env.REACT_APP_LOCATION_API_KEY;
  // const [location, setLocation] = useState({
  //   lat: "",
  //   long: "",
  // });
  const [address1, setAddress1] = useState();
  const [address, setAddress] = useState({
    lat: "",
    long: "",
  });
  const All = () => {
    setFilterStatus(false);
    setcheckWishlist(false);
    setcheckYear(false);
    setCheckNearme(false);
    setbestMatch(false);
    setAddress(prevData => ({
      ...prevData,
      lat: "",
      long: "",
    }));

    setgsearch("");
    setAdvanceFilter({
      sector: "",
      state: "",
      profit: "",
      year: "",
      minRevenue: "",
      maxRevenue: "",
    });
    Setclearsearch(false);
  };
  const check_wishlist = () => {
    setcheckYear(false);
    setbestMatch(false);
    setCheckNearme(false);
    setAddress(prevData => ({
      ...prevData,
      lat: "",
      long: "",
    }));
    setcheckWishlist(true);
  };
  const check_best = () => {
    setcheckWishlist(false);
    setAddress(prevData => ({
      ...prevData,
      lat: "",
      long: "",
    }));
    setcheckYear(false);
    setCheckNearme(false);
    setbestMatch(true);
  };
  const business_year = () => {
    setcheckWishlist(false);
    setCheckNearme(false);
    setAddress(prevData => ({
      ...prevData,
      lat: "",
      long: "",
    }));
    setbestMatch(false);

    setcheckYear(true);
  };
  useEffect(() => {
    if (checkwishlist || checkyear || bestmatch) {
      getStartupByAdvanceFilter();
    }
  }, [checkwishlist, checkyear, bestmatch]);
  const Nearmelocation = async address => {
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: address,
            key: apiKey,
          },
        }
      );

      if (response.data.status === "OK") {
        setAddress(prevData => ({
          ...prevData,
          lat: response.data.results[0].geometry.location.lat,
          long: response.data.results[0].geometry.location.lng,
        }));
        setcheckWishlist(false);
        setcheckYear(false);
        setCheckNearme(true);
        setbestMatch(false);
        Setclearsearch(false);
      } else {
      }
    } catch (error) {
      // setLocation(null);
      console.log(error);
      
    }
  };
  const fetchProfile = async () => {
    const profile = await Profile();
    setAddress1(profile.address);
  };
  const [investorLevelnontop, setInvestorLevelnontop] = useState(false);
  const [investorpercentegalevelless, setinvestorpercentegalevelless] =
    useState("");

  const [investorpercentegalevelmore, setInvestorpercentegalevelmore] =
    useState("");

  const [investorTopRated, setInvestorTopRated] = useState(false);
  const [investoraboveTopRated, setInvestoraboveTopRated] = useState(false);
  const getHistoryData = () => {
    axios
      .get(`${apiurl}investors/viewInvestments/${investorID}`)
      .then(response => {
        if (response?.data?.data) {
          const investments = response.data.data;
          const fiveYearsAgo = new Date();
          fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

          const recentInvestments = investments.filter(investment => {
            const investmentDate = new Date(investment.date);
            return investmentDate >= fiveYearsAgo;
          });

          const totalInvestment = recentInvestments.reduce(
            (total, investment) => {
              return total + parseFloat(investment.raise);
            },
            0
          );

          const crore = 10000000;
          const threeCrore = 30000000;

          let level,
            percentagelevelless,
            percentagelevelmore,
            topRated,
            abovetoprated = false;
          let percentage;

          if (totalInvestment < crore) {
            level = true;
            percentage = (totalInvestment / crore) * 100;
            percentagelevelless = percentage.toFixed(2);
          } else if (totalInvestment > crore && totalInvestment <= threeCrore) {
            level = false;
            percentage = (totalInvestment / threeCrore) * 100;
            percentagelevelmore = percentage.toFixed(2);
            topRated = true;
          } else if (totalInvestment > threeCrore) {
            abovetoprated = true;
            // level = 0;
            // topRated = true;
            // percentage = (totalInvestment / threeCrore) * 100;
          }

          // sethistoryData(response.data.data);
          setInvestorLevelnontop(level);
          setinvestorpercentegalevelless(percentagelevelless);
          setInvestorpercentegalevelmore(percentagelevelmore);
          setInvestorTopRated(topRated);
          setInvestoraboveTopRated(abovetoprated);
          // setInvestorPercentage3(percentage3);
        } else if (
          !response?.data?.data &&
          response?.data?.message === "Investment not found"
        ) {
          // sethistoryData([]);
          setInvestorLevelnontop(false);
          setinvestorpercentegalevelless("");
          setInvestorpercentegalevelmore("");
          setInvestorTopRated(false);
          setInvestoraboveTopRated(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchProfile();
    getHistoryData();
  }, []);
  const handleViewMore = e => {
    e.preventDefault();
    Setshowstartup(prevCount => prevCount + 12);
  };
  const handleViewLess = e => {
    e.preventDefault();
    Setshowstartup(prevCount => prevCount - 12);
  };
  useEffect(() => {
    if (!clearsearch) {
      getStartupByAdvanceFilter();
    }
  }, [clearsearch]);
  useEffect(() => {
    if (!clearsearch && gsearch == "") {
      getStartupByAdvanceFilter();
    }
  }, [clearsearch, gsearch]);

  const formatCurrency = value => {
    if (!value) return "";
    const number = parseInt(value, 10);
    const formattedNumber = number.toLocaleString("en-IN"); // Indian numbering format
    return `₹${formattedNumber}`;
  };

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Investor dashboard</title>
      </Helmet>
      <section className="dashboard-sec">
        <div
          style={
            (status?.status == "Activated" || status?.status == "Active") &&
              status?.contract == "Activated"
              ? {}
              : { display: "none" }
          }
          className="container"
        >
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Startups Business List</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Startups Business List</li>
                {/* <li>Main</li> */}
              </ul>
            </div>
          </div>
          <div className="progess-bar">
            <ul style={{ width: "95%" }} className="flex">
              <li className="active">
                <div className="main-bar">
                  <span className="bar">Level 1</span>
                  <div className="tooltip">
                    <p>
                      New investors automatically qualify for Level 1, which
                      permits them to initiate conversations with up to 5
                      different businesses per month.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="percentage-bar"
                  style={{
                    width:
                      investorTopRated || investoraboveTopRated
                        ? "100%"
                        : investorLevelnontop
                          ? `${investorpercentegalevelless}%`
                          : "0%",
                  }}
                ></div>
                <div className="main-bar">
                  <span
                    className="bar"
                    style={{
                      position: "absolute", top: "0px", right: "1px", lineHeight: 0,
                    }}
                  >
                    Level 2
                  </span>

                  <div className="tooltip">
                    <p>
                      Investors who have invested more than 1 crore INR over the
                      past 5 years advance to Level 2, allowing them to engage
                      with up to 10 businesses monthly.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="percentage-bar"
                  style={{
                    width: investoraboveTopRated
                      ? "100%"
                      : investorTopRated
                        ? `${investorpercentegalevelmore}%`
                        : "0%",
                  }}
                ></div>
                <div className="main-bar">
                  <span className="bar">Top-Rated</span>
                  <div className="tooltip">
                    <p>
                      Achieved by those who invest over 3 crore INR in the last
                      5 years, granting them unlimited access to communicate
                      with any number of businesses.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p>
              {investoraboveTopRated
                ? "100%"
                : investorLevelnontop
                  ? `${investorpercentegalevelless}%`
                  : investorTopRated
                    ? `${investorpercentegalevelmore}%`
                    : "0%"}
            </p>
          </div>
          <div className="filter-search-box flex space-between align-center">
            <div className="search-box">
              <img src="src/images/Search.svg" alt="searchImage" />
              <input
                type="search"
                id="gsearch"
                name="gsearch"
                value={gsearch}
                onChange={e => {
                  setgsearch(e.target.value);
                  // Setclearsearch(false);
                }}
                placeholder="  Browse and Filter business Listings..."
              />
            </div>
            <div className="advance-search-box flex">
              <a
                onClick={() => {
                  setFilterStatus(!filterStatus);
                  setgsearch("");
                  setcheckWishlist(false);
                  setcheckYear(false);
                  setCheckNearme(false);
                  setbestMatch(false);
                  setAddress(prevData => ({
                    ...prevData,
                    lat: "",
                    long: "",
                  }));
                  Setclearsearch(false);
                }}
                className="cta-btn advance_filter"
              >
                <img src="src/images/filter-icon.svg" alt="" />
                Advance Filter
              </a>
              <a
                className="cta-btn btn-orange"
                onClick={() => {
                  getStartupByAdvanceFilter();
                  Setclearsearch(true);
                }}
              >
                <img src="src/images/search-black-icon.svg" alt="" />
                Popular Search
              </a>
            </div>
          </div>
          <div
            style={{ display: filterStatus ? "block" : "none" }}
            className="advance-filter-text-box"
          >
            <form action="">
              <div className="filter-form-wrapper flex">
                <div className="gross-profit">
                  <div className="form-group">
                    <label htmlFor="">Gross Margin (Minimum) </label>
                    <input
                      onChange={e => {
                        HandleChange(e, setAdvanceFilter);
                        // if(e?.target?.value>=0&&e?.target?.value<=100){
                        // }
                      }}
                      value={advanceFilter?.profit}
                      // min="0"
                      // max="100"
                      name="profit"
                      type="text"
                      className="form-control"
                      placeholder="in %"
                    />
                  </div>
                </div>
                <div className="revenue flex space-between">
                  <div className="form-group half">
                    <label htmlFor="">Minimum revenue</label>
                    <input
                      onChange={e => HandleChange(e, setAdvanceFilter)}
                      value={advanceFilter?.minRevenue}
                      name="minRevenue"
                      type="text"
                      className="form-control"
                      placeholder="₹ 0"
                    />
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Maximum revenue</label>
                    <input
                      onChange={e => {
                        HandleChange(e, setAdvanceFilter);
                      }}
                      value={advanceFilter?.maxRevenue}
                      name="maxRevenue"
                      type="text"
                      className="form-control"
                      placeholder="₹ 0"
                    />
                  </div>
                </div>
                <div className="industry-type flex space-between">
                  <div className="form-group half">
                    <label htmlFor="">Industry Sector</label>
                    <select
                      defaultValue=""
                      name="sector"
                      onChange={e => HandleChange(e, setAdvanceFilter)}
                      id=""
                      className="form-control"
                    >
                      <option value="" disabled>
                        Select an industry
                      </option>
                      {Industries?.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group half">
                    <label htmlFor="">Location (by state)</label>
                    <select
                      defaultValue=""
                      name="state"
                      onChange={e => HandleChange(e, setAdvanceFilter)}
                      id=""
                      className="form-control"
                    >
                      <option value="" disabled>
                        Select a state
                      </option>
                      {states
                        ?.sort((a, b) => a.localeCompare(b))
                        ?.map((data, i) => {
                          return (
                            <option key={i} value={data}>
                              {data}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="btn-group-wrapper flex space-between">
                  <div className="left-btn-group">
                    <h4>Years in business:</h4>
                    <a
                      onClick={() =>
                        setAdvanceFilter({ ...advanceFilter, year: "1" })
                      }
                      className={`cta-btn ${advanceFilter?.year == "1"
                          ? "sucess-btn"
                          : "grey-white-btn"
                        }`}
                    >
                      Up to 1 Year
                    </a>
                    <a
                      onClick={() =>
                        setAdvanceFilter({ ...advanceFilter, year: "2" })
                      }
                      className={`cta-btn ${advanceFilter?.year == "2"
                          ? "sucess-btn"
                          : "grey-white-btn"
                        }`}
                    >
                      {" "}
                      2+ Years
                    </a>
                    <a
                      onClick={() =>
                        setAdvanceFilter({ ...advanceFilter, year: "3" })
                      }
                      className={`cta-btn ${advanceFilter?.year == "3"
                          ? "sucess-btn"
                          : "grey-white-btn"
                        }`}
                    >
                      {" "}
                      3+ Years
                    </a>
                  </div>
                  <div className="right-btn-group">
                    <a
                      onClick={() => {
                        setFilterStatus(false);
                        setcheckWishlist(false);
                        setcheckYear(false);
                        setCheckNearme(false);
                        setbestMatch(false);
                        Setclearsearch(false);
                        setAdvanceFilter({
                          sector: "",
                          state: "",
                          profit: "",
                          year: "",
                          minRevenue: "",
                          maxRevenue: "",
                        });

                        // setcheckWishlist(false);
                      }}
                      className="cta-btn grey-white-btn cancel"
                    >
                      Cancel
                    </a>
                    <a
                      onClick={() => {
                        getStartupByAdvanceFilter();
                        Setclearsearch(true);
                      }}
                      className="cta-btn"
                    >
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="filter-button-box flex space-between">
            <div className="left-group-button">
              <ul className="flex">
                <li>
                  <a
                    className={
                      !checkyear && !checkwishlist && !bestmatch && !checknearme
                        ? "cta-btn active dashboardinvestor"
                        : "cta-btn deactive dashboardinvestor"
                    }
                    onClick={() => All()}
                  >
                    All
                  </a>
                </li>
                <li>
                  <a
                    className={
                      bestmatch
                        ? "cta-btn active  dashboardinvestor"
                        : "cta-btn white-btn dashboardinvestor"
                    }
                    onClick={() => check_best()}
                  >
                    Best Match
                  </a>
                </li>
                <li>
                  <a
                    className={
                      checknearme
                        ? "cta-btn active  dashboardinvestor2"
                        : "cta-btn white-btn dashboardinvestor2"
                    }
                    onClick={() => Nearmelocation(address1)}
                  >
                    Near Your Area
                  </a>
                </li>
                <li>
                  <a
                    className={
                      checkwishlist
                        ? "cta-btn active  dashboardinvestor3"
                        : "cta-btn white-btn dashboardinvestor3"
                    }
                    onClick={() => check_wishlist()}
                  >
                    Your Favorites
                  </a>
                </li>
                <li>
                  <a
                    className={
                      checkyear
                        ? "cta-btn businessbutton"
                        : "cta-btn white-btn businessbutton"
                    }
                    onClick={() => business_year()}
                  >
                    Established Businesses (2+ Years in Business)
                  </a>
                </li>
              </ul>
            </div>
            <div className="right-grid-box">
              <ul className="flex">
                <li
                  style={activeTab == 1 ? {} : { filter: "grayscale(1" }}
                  className={activeTab === 1 ? "" : ""}
                  data-tab="1"
                  onClick={() => handleTabClick(1)}
                >
                  <img src="src/images/gridicon01.svg" alt="" />
                </li>
                <li
                  style={activeTab == 2 ? {} : { filter: "grayscale(1" }}
                  className={activeTab === 2 ? "" : ""}
                  data-tab="2"
                  onClick={() => handleTabClick(2)}
                >
                  <img
                    src={
                      activeTab === 2
                        ? "src/images/UiChecks.png"
                        : "src/images/gridicon02.svg"
                    }
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="box-wrapper">
            <div
              id="tab-1"
              className={`tab-content ${activeTab === 1 ? "active" : ""}`}
            >
              <div className="row flex">
                {businessList !== undefined &&
                  businessList &&
                  businessList.length > 0 ? (
                  <>
                    {businessList

                      .sort((a, b) => {
                        const aCreatedAt = a.user_id?.createdAt
                          ? new Date(a.user_id.createdAt)
                          : new Date(0);
                        const bCreatedAt = b.user_id?.createdAt
                          ? new Date(b.user_id.createdAt)
                          : new Date(0);
                        return bCreatedAt - aCreatedAt;
                      })
                      .slice(0, showstartup)
                      .map((startuplist, index) => (
                        <div key={index} className="box">
                          <div className="img-box img-box-custom">
                            <img src={startuplist.logo} alt="dashbord" />
                          </div>
                          <div
                            className="bottom-text"
                            onClick={e =>
                              getsinglestartupdetails(
                                e,
                                startuplist._id,
                                startuplist.user_id._id
                              )
                            }
                          >
                            <Link
                              to=""
                              onClick={e =>
                                getsinglestartupdetails(
                                  e,
                                  startuplist._id,
                                  startuplist.user_id._id
                                )
                              }
                            >
                              <h6 className="title">
                                {startuplist.business_name}
                              </h6>
                            </Link>

                            <p>
                              {" "}
                              {truncateDescription(
                                startuplist?.business_description,
                                80
                              )}
                            </p>
                            <div className="location flex align-center">
                              <img src="src/images/location.svg" alt="" />
                              {startuplist.state}
                            </div>
                            <div className="location technology flex align-center">
                              <img src="src/images/bulding.svg" alt="" />
                              {startuplist.industry_sector}
                            </div>
                            <div className="dashbaordbottombutton">
                              <div className="btn-group flex">
                                <a className="btn">
                                  LYR:{formatCurrency(startuplist.minimum_raise_description)}
                                </a>
                                <a className="btn">
                                  MR:{formatCurrency(startuplist?.maximum_raise_description)}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="hide-text">
                            <ul className="flex">
                              <li
                                onClick={e =>
                                  toggleFavourites(
                                    e,
                                    startuplist?.in_wishlist != true
                                      ? "add"
                                      : "delete",
                                    startuplist?.user_id?._id
                                  )
                                }
                              >
                                {startuplist?.in_wishlist == true ? (
                                  <>
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hearticonivestor"
                                    >
                                      <path
                                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                                        fill="red"
                                      />
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hearticonivestor"
                                    >
                                      <path
                                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                                        stroke="red"
                                        strokeWidth="2"
                                        fill="none"
                                      />
                                    </svg>
                                  </>
                                )}
                              </li>
                              <li
                                // onClick={(e) => {
                                //   e.preventDefault();
                                //   getsinglestartupdetails(e, startuplist._id);
                                // }}
                                onClick={e =>
                                  getsinglestartupdetails(
                                    e,
                                    startuplist._id,
                                    startuplist.user_id._id
                                  )
                                }
                              >
                                <img
                                  src="src/images/eye.svg"
                                  className="hearticonivestor"
                                  alt=""
                                />
                              </li>
                              {/* <li
                              onClick={() => {
                                mimi == startuplist?._id
                                  ? setMimi("")
                                  : setMimi(startuplist?._id);
                              }}
                            >
                              <img src="src/images/dots.svg" alt="" />
                            </li> */}
                            </ul>
                            <ul
                              style={{
                                display: startuplist?._id == mimi ? "" : "none",
                              }}
                              className="newbox"
                            >
                              <li
                                onClick={() =>
                                  navigate("/messenger", {
                                    state: { id: startuplist?.user_id?._id },
                                  })
                                }
                              >
                                Message
                              </li>
                              <li
                                style={{
                                  borderBottom: "1px solid grey",
                                  borderRadius: "0px",
                                }}
                              >
                                Interested
                              </li>
                              <li>Block</li>
                            </ul>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    <div className="startupnotfound">
                      {" "}
                      <h2>No startup found</h2>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              id="tab-2"
              className={`tab-content ${activeTab === 2 ? "active" : ""}`}
            >
              <div className="grid-view-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Industry Sector</th>
                      <th>Location</th>
                      <th>Minimum Raise</th>
                      <th>Year Established</th>
                      <th>Last Year Revenue</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {businessList &&
                      businessList
                        ?.slice()
                        .sort((a, b) => {
                          const aCreatedAt = a.user_id?.createdAt
                            ? new Date(a.user_id.createdAt)
                            : new Date(0);
                          const bCreatedAt = b.user_id?.createdAt
                            ? new Date(b.user_id.createdAt)
                            : new Date(0);
                          return bCreatedAt - aCreatedAt;
                        })
                        .slice(0, showstartup)
                        ?.map((startuplist, index) => (
                          <tr key={index}>
                            <td
                              onClick={e =>
                                getsinglestartupdetails(
                                  e,
                                  startuplist._id,
                                  startuplist.user_id._id
                                )
                              }
                              className="investortr"
                            >
                              <ul>
                                <li className="flex">
                                  <div className="img-box logo-size">
                                    <figure>
                                      <img
                                        src={
                                          startuplist?.logo
                                            ? `${startuplist?.logo}`
                                            : "src/images/dashbord-img01.jpg"
                                        }
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                  <div className="text">
                                    <h6>{startuplist?.business_name}</h6>
                                    <p>
                                      {truncateDescription(
                                        startuplist?.business_description,
                                        50
                                      )}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </td>
                            <td>{startuplist?.industry_sector}</td>
                            <td>{startuplist?.state}</td>
                            <td>{startuplist?.minimum_raise_description} </td>
                            <td>{startuplist?.year_of_establish}</td>
                            <td>
                              <img src="src/images/revenue.svg" alt="" />
                              <span>
                                {startuplist?.maximum_raise_description}
                              </span>
                            </td>
                            <td>
                              <div className="icons flex">
                                <a
                                  onClick={e =>
                                    toggleFavourites(
                                      e,
                                      startuplist?.in_wishlist != true
                                        ? "add"
                                        : "delete",
                                      startuplist?.user_id?._id
                                    )
                                  }
                                >
                                  {startuplist?.in_wishlist == true ? (
                                    <>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="hearticonivestor"
                                      >
                                        <path
                                          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                                          fill="red"
                                        />
                                      </svg>
                                    </>
                                  ) : (
                                    <>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="hearticonivestor"
                                      >
                                        <path
                                          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                                          stroke="red"
                                          strokeWidth="2"
                                          fill="none"
                                        />
                                      </svg>
                                    </>
                                  )}

                                  {/* <img
                                  src={
                                    startuplist?.in_wishlist == true
                                      ? `src/images/fill-heart.svg`
                                      : `src/images/heart.svg`
                                  }
                                  className="hearticonivestor"
                                  alt=""
                                /> */}
                                </a>
                                <a
                                  // onClick={(e) => {
                                  //   e.preventDefault();
                                  //   getsinglestartupdetails(e, startuplist._id);
                                  // }}
                                  onClick={e =>
                                    getsinglestartupdetails(
                                      e,
                                      startuplist._id,
                                      startuplist.user_id._id
                                    )
                                  }
                                >
                                  <img
                                    src="src/images/fill-eye-icon.svg"
                                    alt=""
                                    className="hearticonivestor"
                                  />
                                </a>
                                {/* <a
                                onClick={() => {
                                  mimi == startuplist?._id
                                    ? setMimi("")
                                    : setMimi(startuplist?._id);
                                }}
                              >
                                <img src="src/images/dots.svg" alt="" />
                              </a> */}
                                <ul
                                  style={{
                                    display:
                                      startuplist?._id == mimi ? "" : "none",
                                    position: "absolute",
                                  }}
                                  className="newbox1"
                                >
                                  <li>Message</li>
                                  <li
                                    style={{
                                      borderBottom: "1px solid grey",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    Interested
                                  </li>
                                  <li>Block</li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="viewmoredashboard">
            {showstartup > 12 && businessList && businessList.length > 0 && (
              <a
                className="cta-btn btn-blue header-btn"
                onClick={handleViewLess}
              >
                View Less
              </a>
            )}
            {businessList && businessList.length > showstartup && (
              <a
                className="cta-btn btn-blue header-btn"
                onClick={handleViewMore}
              >
                View More
              </a>
            )}
          </div>
        </div>
        <div
          className="approvals"
          style={{
            display:
              status?.status != "Active" || status?.contract != "Activated"
                ? "block"
                : "none",
          }}
        >
          {(status?.status == "Active" || status?.status == "Activated") &&
            status?.contract == "Pending" ? (
            <p className="contracttextsign">
              Last Step: Please sign{" "}
              <a
                href={investorprivacy}
                style={{ cursor: "pointer", color: "#43B8F5" }}
              >
                Privacy and Contractual Agreements
              </a>{" "}
              to start viewing business profiles.
            </p>
          ) : status?.status == "Pending" ? (
            <p>
              Your Profile has been sent for approval, please wait while your
              application gets reviewed.
            </p>
          ) : status?.status == "Deactive" ? (
            <>
              <p style={{ marginBottom: "0px" }}>
                Your Profile has been disapproved
              </p>
              <p>
                Please check your email for detailed reasons or{" "}
                <a
                  href={investorprofile}
                  style={{ cursor: "pointer", color: "#43B8F5" }}
                >
                  Resubmit
                </a>{" "}
                {""} the profile
              </p>
            </>
          ) : status?.status == "Resubmit" ? (
            <>
              <p style={{ marginBottom: "0px" }}>
                Your profile has been resubmitted and it would take 2 business
                days to approve or disapprove.
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default InvestorDashboard;
