/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { getItem } from "./reusable";
import { get } from "../services/services";
import { apiurl } from "../maincomponent/Url";

function Notifications({ notifactionOpenFunction, notificationdata,contractSigned }) {
  const [notifactiondata] = useState(notificationdata);
// console.log(contractSigned)
  const notificationboxref = useRef(null);
  const navigate = useNavigate();
  const calculateTimeDifference = createdAt => {
    const currentTime = new Date();
    const notificationTime = new Date(createdAt);
    const diffInMilliseconds = currentTime - notificationTime;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    if (diffInMinutes < 60) {
      return `${diffInMinutes} m ago`;
    } else if (diffInMinutes < 1440) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else {
      const diffInDays = Math.floor(diffInMinutes / 1440);
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }
  };
  const showmessage = (message,length,type) => {
    const index = message.indexOf("/");

    const extractedmessage = index !== -1 ? message.slice(index + 1) : message;
    if (length && length > 0 &&type === "Message") {
      return shortmessage(extractedmessage, length);
    }
    return extractedmessage;
  };

  const [pitchposted, setPitchposted] = useState(false);
  const [notificationsenderid, Setnotificationsenderid] = useState("");

  const startupdetail = () => {
    get(`${apiurl}users/getStartupProfile/${notificationsenderid}`).then(
      response => {
        // console.log(response,'notifavtion')
        notifactionOpenFunction();
        if (response.data) {
          navigate("/investor-view-details", {
            state: {
              user_id: response.data.user._id,
              member_id: notificationsenderid,
            },
          });
        }
      }
    );
  };

  useEffect(() => {
    if (pitchposted) {
      // console.log('picthposted')
      startupdetail();
    }
  }, [pitchposted]);

  const gotoNotiifcation = (e, item) => {
    if (item.notificationType === "Message") {
      notifactionOpenFunction();
      navigate("/messenger", {
        state: { id: item?.notificationsenderId },
      });
    } else if (item.notificationType === "Sign contract") {
      notifactionOpenFunction();
    
      if (contractSigned) {
        navigate("/privacy-contract-view");
      } else {
 
        navigate("/privacy-contract");
      }
    } else if (item.notificationType === "Profile Approved" ||item.notificationType ==="Profile Rejected") {
      if (getItem("userType") != "StartUp") {
        notifactionOpenFunction();
        navigate("/investor-profile");
      } else {
        notifactionOpenFunction();
        navigate("/startup-profile");
      }
    } else if (item.notificationType === "New Pitch") {
      // notifactionOpenFunction();
      Setnotificationsenderid(item.notificationsenderId);
      setPitchposted(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (notificationboxref.current && !notificationboxref.current.contains(event.target)) {
        notifactionOpenFunction();
      }
   
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const shortmessage = (message, length) => {
    if (!message) {
      return "";
    }
    if (message.length > length) {
      return message.substring(0, length) + "...";
    }
    return message;
  };

  return (
    <>
      <div className="notifaction-wrapper active" ref={notificationboxref}>
        <div className="cross-noti-box flex space-between align-center">
          <h4>
            Notifications{" "}
            <span>
              (
              {
                notifactiondata?.filter(notification => !notification.seen)
                  .length
              }{" "}
              New)
            </span>
          </h4>
          <div className="cross-btn" onClick={notifactionOpenFunction}>
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-notifaction">
          <div className="notificationallbox">
            {notifactiondata != undefined &&
              notifactiondata.length != 0 &&
              notifactiondata
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                // .slice(0, 2)
                .map(item => (
                  <div className="notifaction-box">
                    <div
                      className="img-box"
                      style={{
                        cursor:
                          item.notificationType === "Pitch view"||item.notificationType ==="Ready to Invest"||item.notificationType==="Due Diligence"
                            ? "inherit"
                            : "pointer",
                      }}
                      onClick={e => gotoNotiifcation(e, item)}
                    >
                      {item.notificationType === "Message" ? "" : ""}
                      <h3>
                        {item.notificationType === "Message" ? (
                          <>{item?.message?.charAt(0)}</>
                        ) : (
                          <>
                            <img src="src/images/top-chat.svg" alt="" />
                          </>
                        )}
                      </h3>
                    </div>
                    <div
                      className="text-box"
                      style={{
                        cursor:
                                                    item.notificationType === "Pitch view"||item.notificationType ==="Ready to Invest"||item.notificationType==="Due Diligence"
                            ? "inherit"
                            : "pointer",
                      }}
                      onClick={e => gotoNotiifcation(e, item)}
                    >
                      <ul>
                        <li>
                          <h6>
                            {item.notificationType === "Message" ? (
                              <> {item.message.split(" ")[0]}</>
                            ) : (
                              ""
                            )}{" "}
                            <span>
                              {
                                <>
                                  {item.notificationType === "Message" ? (
                                    <>New Message</>
                                  ) : item.notificationType ===
                                    "Due Diligence" ? (
                                    <>Due Diligence</>
                                  ) : item.notificationType ===
                                    "Ready to Invest" ? (
                                    <>Ready to Invest</>
                                  ) : (
                                    ""
                                  )}
                                </>
                              }
                            </span>
                          </h6>
                          <p className="time">
                            {calculateTimeDifference(item.createdAt)}
                          </p>
                        </li>
                        <li>
                          <p
                            className={
                              item.notificationType === "Message"
                                ? ""
                                : "normalpnotifications"
                            }
                            onClick={e => gotoNotiifcation(e, item)}
                          >
                            {showmessage(item.message,50,item.notificationType)}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default Notifications;
