/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Header2 from "../../common/Header2";
import Footer2 from "../../common/Footer2";
import { apiurl } from "../Url";
import Loader from "../../common/Loader";
import Checkout from "../../common/Checkout";
import { investordashboard } from "../../Routes/Routes";
import { getItem, isJsonString } from "../../common/reusable";
import { ChatContext } from "../../Context/ChatContext";
import { copyinvestorviewdetails } from "../../Routes/Routes";
import { post, get } from "../../services/services";
import Loaderbutton from "../../common/loaderbutton";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function InvestorViewDetails() {
  const investorID = getItem("UserId");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, Setloading] = useState(true);
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [singlestartupid, setSinglestartupid] = useState(null);
  const [memberstartupid, setMemberstartupid] = useState(null);

  const [shareurl, setSharedurl] = useState("");
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const querySinglestartupid = query.get("singlestartupid");
    const queryMemberstartupid = query.get("memberstartupid");
    setSinglestartupid(querySinglestartupid || location?.state?.user_id);
    setMemberstartupid(queryMemberstartupid || location?.state?.member_id);
  }, [location]);

  const [paymentpop, setPaymentpop] = useState(false);
  const [sharepop, setSharepop] = useState(false);
  const [isBlurred, setIsBlurred] = useState(true);
  const [requestInvestor, setRequestInvestor] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    note: "",
  });
  const [investForm, setInvestForm] = useState({
    ownerName: "",
    investmentAmount: "",
    equityDilution: "",
    termsAcceptance: "",
  });
  const [errors, setErrors] = useState({});
  const [contactMessages, setContactMessages] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [Amount, setAmount] = useState("");
  const [contectPopup, setContectPopup] = useState(false);
  const [startupData, setStartupData] = useState("");
  const [teamMembers, setTeamMembers] = useState("");
  const { socket, userChats } = useContext(ChatContext);
  const [ismessagesent, Setismessagesent] = useState(false);
  // const [blankmessage, setBlankmessage] = useState(false);
  const [startupreplied, setStartupreplied] = useState(false);
  useEffect(() => {
    if (userChats?.length > 0) {
      let u = userChats?.filter(
        a => a?.members?.filter(b => b?._id == memberstartupid)?.length > 0
      );

      if (u?.length > 0) {
        Setismessagesent(true);
      }
      if (u?.length > 0 && u[0]?.isStartupReplied == true) {
        setIsBlurred(false);
        setStartupreplied(true);
      }
      // setBlankmessage(true);
    } else {
      // setBlankmessage(true);
    }
  }, [userChats, memberstartupid]);

  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleClickMessage = message => {
    setSelectedMessage(message);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 16) {
      setErrors(previousError => ({
        ...previousError,
        phoneNumber: "Maximum 16 digits allowed",
      }));
      return;
    }
    const phoneNumberPattern = /^[+\d\s\-\(\)\.\/]*$/;
    if (name === "phoneNumber") {
      if (!phoneNumberPattern.test(value)) {
        setErrors({ ...errors, [name]: "Alphabets not allowed" });
        return;
      }
    }
    setRequestInvestor({
      ...requestInvestor,
      [name]: value,
    });
    setInvestForm({
      ...investForm,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    const newErrors = {};
    if (!requestInvestor.name) {
      newErrors.name = "Name is required.";
    }
    if (!requestInvestor.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    } else if (requestInvestor.phoneNumber.length < 6) {
      newErrors.phoneNumber = "Minimum 6 digits required for Phone Number";
    }
    if (!requestInvestor.email) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(requestInvestor.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setPaymentpop(true);
    }
  };

  const investSubmit = e => {
    e.preventDefault();
    const newErrors = {};
    if (!investForm.ownerName) {
      newErrors.ownerName = "Owner Name is required.";
    }
    if (!investForm.investmentAmount) {
      newErrors.investmentAmount = "Investment Amount is required.";
    }
    if (!investForm.equityDilution) {
      newErrors.equityDilution = "Equity Dilution is required.";
    }
    if (!investForm.termsAcceptance) {
      newErrors.termsAcceptance = "Terms Acceptance is required.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        post(`${apiurl}investors/readyToInvest`, {
          ownerName: investForm.ownerName,
          amount: investForm.investmentAmount,
          equity: investForm.equityDilution,
          user_id: memberstartupid,
          investoruser_id: JSON.parse(localStorage?.getItem("UserId")),
          startup_id: singlestartupid,
        })
          .then(response => {
            if (!response) {
            } else {
              if (!response?.data?.status) {
                toast.error(response?.data?.message);
              } else {
                toast.success(
                  "Thank you for submitting the form. Udharaa.com will contact you within 48 hours."
                );
                setInvestForm(prevState => ({
                  ...prevState,
                  ownerName: "",
                  investmentAmount: "",
                  equityDilution: "",
                  termsAcceptance: "",
                }));
              }
            }
          })
          .catch(error => {
            console.error(error);
          });
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    }
  };
  useEffect(() => {
    get(`${apiurl}investors/messageList`)
      .then(response => {
        if (!response) {
        } else {
          setContactMessages(response?.data?.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    get(`${apiurl}investors/getAmount`)
      .then(response => {
        if (!response) {
        } else {
          setAmount(response?.data?.data[0].amount);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const getTeamMembers = () => {
    axios
      .get(`${apiurl}startups/viewMember/${memberstartupid}`)
      .then(response => {
        setTeamMembers(response?.data?.data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (singlestartupid != null) {
      get(`${apiurl}investors/getstartupDetails/${singlestartupid}`)
        .then(response => {
          if (!response) {
          } else {
            setStartupData(response?.data?.data);
            Setloading(false);
          }
        })
        .catch(err => {
          console.log(err);
        });
      getTeamMembers();
    }
  }, [singlestartupid]);

  // const encodeContent = content => {
  //   // return btoa(content);
  // };

  // const decodeContent = encodedContent => {
  //   // return atob(encodedContent);
  // };
  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (userType != null) {
      if (userType != "Investor") {
        navigate("/");
      }
    }
  }, []);

  const closePaymentmodal = () => {
    setPaymentpop(false);
  };
  const openSharemodal = () => {
    setSharepop(true);
  };
  const closeSharemodal = () => {
    setSharepop(false);
  };
  const onFormSubmit = () => {
    closePaymentmodal();
    setRequestInvestor(prevState => ({
      ...prevState,
      name: "",
      email: "",
      phoneNumber: "",
      note: "",
    }));
  };

  const [videoSrc, setVideosrc] = useState("");
  useEffect(() => {
    // console.log(startupData.video)
    if (startupData.video != "") {
      function extractSrcFromIframe(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const iframe = doc.querySelector("iframe");
        return iframe ? iframe.src : html;
      }
      const iframeHtml = startupData.video;
      // console.log(iframeHtml,startupData.video,'src')
      setVideosrc(extractSrcFromIframe(iframeHtml));
    }
  }, [startupData]);

  const sendMessage = () => {
    setLoadingbutton(true);
    post(`${apiurl}investors/countSearch/${getItem("UserId")}`)
      .then(response => {
        if (!response) {
        } else {
          if (!response.data.status) {
            toast.error(response?.data?.message);
            setLoadingbutton(false);
          } else {
            if (selectedMessage.trim() !== "") {
              let chat_id = "new_chat";
              if (userChats?.length > 0) {
                // check if the chat is present or not
                for (let i = 0; i < userChats.length; i++) {
                  const c = userChats[i];
                  let isChatPresent = c?.members?.filter(
                    b => b?._id == memberstartupid
                  );
                  if (isChatPresent?.length > 0) {
                    chat_id = c?._id;
                    break;
                  }
                }
                userChats?.filter(
                  a =>
                    a?.members?.filter(b => a?._id == memberstartupid)?.length >
                    0
                );
              }

              let newMessage = {
                chatId: chat_id,
                token: localStorage?.getItem("token")?.replaceAll('"', ""),
                senderId: localStorage?.getItem("UserId")?.replaceAll('"', ""),
                text: selectedMessage?.trim(),
                receiverId: memberstartupid,
              };
              console.log(memberstartupid);
              if (memberstartupid) {
                socket.emit("createMessage", newMessage);
                toast.success(
                  "Message has been sent successfully. Once the user will reply, you will able to see the full profile."
                );
                setContectPopup(false);
                Setismessagesent(true);
                setLoadingbutton(false);
              }
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const [wishlistedstartup, setwishlistedstartup] = useState("");
  const getStartupByAdvanceFilter = () => {
    try {
      get(`${apiurl}investors/getStartUpList/${getItem("UserId")}`, {
        params: {
          sector: "",
          location: "",
          profit: "",
          year: "",
          minRevenue: "",
          maxRevenue: "",
          search: "",
        },
      })
        .then(res => {
          if (res?.data?.startups) {
            const investor = res?.data?.startups.filter(
              item => item._id == singlestartupid
            );

            setwishlistedstartup(investor[0].in_wishlist);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (singlestartupid != null) {
      getStartupByAdvanceFilter();
    }
  }, [singlestartupid]);
  const toggleFavourites = (e, type, id) => {
    e.preventDefault();
    Setloading(true);
    if (type == "add") {
      try {
        post(`${apiurl}investors/addToWishList`, {
          investorId: investorID,
          startUpId: id,
        })
          .then(res => {
            if (!res) {
            } else {
              if (!res.data.status) {
                toast.error(res.data.message);
              } else {
                getStartupByAdvanceFilter();
                toast("Startup added successfully to favourites");
                Setloading(false);
              }
            }
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err);
      }
    } else if (type == "delete") {
      try {
        post(`${apiurl}investors/deleteWishList`, {
          investorId: investorID,
          startupId: id,
        })
          .then(res => {
            if (!res) {
            } else {
              if (!res.data.status) {
                toast.error(res.data.message);
              } else {
                getStartupByAdvanceFilter();
                Setloading(false);
                toast("Startup removed from  favourites");
              }
            }
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    setSharedurl(
      `${window.location.origin}${copyinvestorviewdetails}?singlestartupid=${singlestartupid}&memberstartupid=${memberstartupid}`
    );
  }, [singlestartupid]);
  const [copied, setCopied] = useState(false);
  const handleCopyURL = () => {
    navigator.clipboard
      .writeText(shareurl)
      .then(() => {
        setCopied(true);
      })
      .catch(error => {});
  };
  useEffect(() => {
    if (singlestartupid != null) {
      post(`${apiurl}startups/viewstartups/${memberstartupid}`, {
        viewerid: getItem("UserId"),
      })
        .then(response => {
          if (!response) {
          } else {
            Setloading(false);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [singlestartupid]);
  const openContactpopup = () => {
    if (!startupreplied) {
      if (!ismessagesent) {
        setContectPopup(true);
      } else {
        toast.error("Message already sent to this Startup");
      }
    } else {
      navigate("/messenger");
    }
  };

  const cleanRevenue = revenueString => {
    return Number(revenueString?.toString()?.replace(/₹|,/g, ""));
  };

  const formatCurrency = value => {
    if (!value) return "";
    const number = parseInt(value, 10);
    const formattedNumber = number.toLocaleString("en-IN"); // Indian numbering format
    return `₹${formattedNumber}`;
  };

  const goInvestorProfile = e => {
    e.preventDefault();
    if (location?.state?.slug) {
      navigate(location?.state?.slug);
    } else {
      navigate("/investor-dashboard");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Investorviewdetails</title>
      </Helmet>
      {paymentpop && (
        <div className="popup-wrapper pop-open paymentpopup">
          <div className="pop-cross-btn" onClick={closePaymentmodal}>
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
          <div className="inner-poupup">
            <div className="payment-box-wrapper">
              <h2>Payment details</h2>
              {/* {clientSecret && stripePromise && ( */}
              <Elements stripe={stripePromise}>
                <Checkout
                  name={requestInvestor?.name}
                  email={requestInvestor?.email}
                  phoneNo={requestInvestor?.phoneNumber}
                  note={requestInvestor?.note}
                  user_id={memberstartupid}
                  amount={Amount}
                  investorid={getItem("UserId")}
                  startupId={singlestartupid}
                  onFormSubmit={onFormSubmit}
                />
              </Elements>
              {/* )} */}
            </div>
          </div>
        </div>
      )}
      {sharepop && (
        <div className="popup-wrapper pop-open sharepopup">
          <div className="pop-cross-btn" onClick={closeSharemodal}>
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
          <div className="inner-poupup">
            <h2>Share Links</h2>
            <div className="form-group">
              <input
                type="text"
                style={{ textTransform: "lowercase" }}
                value={`${window.location.href}?startupid=${singlestartupid}?memberstartupid=${memberstartupid}`}
                className="form-control"
              />
              <button className="cta-btn" type="button" onClick={handleCopyURL}>
                {copied ? "Copied!" : "Copy!"}
              </button>
            </div>
            <div className="social-icon">
              <ul className="flex">
                <li>Social Links:</li>
                <li className="flex align-center">
                  <FacebookShareButton url={shareurl}>
                    <a href="" target="_blank">
                      <img src="src/images/Facebook.svg" alt="socialicon" />
                    </a>
                  </FacebookShareButton>
                  <TwitterShareButton url={shareurl}>
                    <a href="" target="_blank">
                      <img src="src/images/Twitter.svg" alt="socialicon" />
                    </a>
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareurl}>
                    <a href="" target="_blank">
                      <img src="src/images/Linkedin1.png" alt="socialicon" />
                    </a>
                  </LinkedinShareButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {contectPopup && (
        <div className="popup-container">
          <div
            className="popup-wrapper pop-open custom-popup-hight custom-popup-width"
            id="investor-profile"
          >
            <div
              className="pop-cross-btn"
              onClick={() => {
                setContectPopup(false);
                setSelectedMessage("");
              }}
            >
              <img src="src/images/cross-icon.svg" alt="" />
            </div>
            <div className="inner-poupup">
              <div className="name-box-wrapper messengerpopup">
                <div className="row-box flex">
                  <div className="name-box custom-photo-box">
                    <img src={startupData?.logo} alt="" />
                  </div>
                  <div className="col-right">
                    <div className="stage-icon flex">
                      <img src="src/images/stage-icon.svg" alt="" />{" "}
                      <p>
                        Stage of the business: {startupData?.stage_of_business}
                      </p>
                    </div>
                    <div className="name-url">
                      <h3>{startupData?.founder_name}</h3>
                    </div>
                    {/* <div className="contact-btn-wrapper">
                      <ul>
                        <li className="flex">
                          <img src="src/images/mail-icon.svg" alt="" class="messageemail"/>{""}
                          <a href={startupData?.business_email}>
                            {startupData?.business_email}
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <form action="">
                  <div className="form-group">
                    <label htmlFor="">Your Message</label>
                    <textarea
                      name="selectedMessage"
                      className="form-control"
                      placeholder="Enter your text here (maximum 1500 characters)"
                      id=""
                      value={selectedMessage}
                      onChange={e => setSelectedMessage(e.target.value)}
                    ></textarea>
                  </div>
                </form>
                {contactMessages && (
                  <div className="interested flex">
                    <a
                      className="cta-btn"
                      onClick={() =>
                        handleClickMessage(contactMessages[0].message.Toptext1)
                      }
                    >
                      {contactMessages[0].message.Toptext1}
                    </a>
                    <a
                      className="cta-btn"
                      onClick={() =>
                        handleClickMessage(contactMessages[0].message.Toptext2)
                      }
                    >
                      {contactMessages[0].message.Toptext2}
                    </a>

                    <div
                      className="light-text"
                      onClick={() =>
                        handleClickMessage(
                          contactMessages[0].message.lighttext1
                        )
                      }
                    >
                      <div className="sub-title">
                        {contactMessages[0].message.Subtitle}
                      </div>
                      <p>{contactMessages[0].message.lighttext1}</p>
                    </div>
                    <div
                      className="light-text"
                      onClick={() =>
                        handleClickMessage(
                          contactMessages[0].message.lighttext2
                        )
                      }
                    >
                      <p>{contactMessages[0].message.lighttext2}</p>
                    </div>
                    <div
                      className="light-text"
                      onClick={() =>
                        handleClickMessage(
                          contactMessages[0].message.lighttext3
                        )
                      }
                    >
                      <p>{contactMessages[0].message.lighttext3}</p>
                    </div>
                  </div>
                )}
                <div className="btn-group flex">
                  {loadingbutton ? (
                    <span
                      className="cta-btn upate-btn"
                      style={{ cursor: "not-allowed" }}
                    >
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    </span>
                  ) : (
                    <a onClick={() => sendMessage()} className="cta-btn">
                      Send
                    </a>
                  )}

                  <a
                    className="cta-btn border"
                    onClick={() => {
                      setContectPopup(false);
                      setSelectedMessage("");
                    }}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {startupData ? (
        <section className="profile-Sec dashboard-sec">
          <div className="container">
            <div className="bredcrum-box flex align-center">
              <div className="left-box">
                <h5 style={{ display: "inline-block" }}>
                  {" "}
                  <button
                    className="back-buttondetails"
                    onClick={e => goInvestorProfile(e)}
                  >
                    <img src="src/images/Icon20.png" alt="icon" />
                  </button>
                  {startupData?.business_name}
                </h5>

                <ul className="flex">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to={investordashboard}>Startups business List </Link>
                  </li>
                  {/* <li>
                  <a href="/">Main</a>
                </li> */}
                  <li className="viewdeatilsbreadcrumb">View Detail</li>
                </ul>
              </div>

              <div className="right-box viewdeatilsbreadcrumbbutton">
                <div className="flex">
                  <div
                    className="breadCrumDiv"
                    onClick={e =>
                      toggleFavourites(
                        e,
                        wishlistedstartup != true ? "add" : "delete",
                        memberstartupid
                      )
                    }
                  >
                    <>
                      {wishlistedstartup == true ? (
                        <>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="hearticonivestor"
                          >
                            <path
                              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                              fill="red"
                            />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="hearticonivestor"
                          >
                            <path
                              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                              stroke="red"
                              strokeWidth="2"
                              fill="none"
                            />
                          </svg>
                        </>
                      )}
                    </>
                  </div>
                  <div className="breadCrumDiv" onClick={openSharemodal}>
                    <img
                      src="src/images/share-icon.png"
                      alt="site-logo"
                      className="hearticonivestor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row flex space-between">
              <div className="big-text-box">
                {/* <div className="media-box video">
                <video
                  id="bgvid"
                  muted
                  autoPlay
                  loop
                  playsInline
                  poster="src/images/viewDetailsImage.jpg"
                >
                  <source
                    src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1&loop=1&playlist=9xwazD5SyVg"
                    type="video/mp4"
                  />
                  <source
                    src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1&loop=1&playlist=9xwazD5SyVg"
                    type="video/webm"
                  />
                </video>
                <div className="play-icon">
                  <img src="src/images/play-white-icon.svg" alt="" />
                </div>
              </div> */}
                <div className="media-box video custom-video">
                  <iframe src={videoSrc} allow="fullscreen" title="iframe" />
                </div>
                <div className="shadow-box">
                  <div className="name-box-wrapper">
                    <div className="row-box flex">
                      <div className="name-box user-photo">
                        {/* {startupData?.founder_name
                          ? startupData.founder_name.charAt(0)
                          : ""} */}
                        <img src={startupData?.founderPhoto} alt="" />
                      </div>
                      <div className="col-right">
                        <div className="stage-icon flex">
                          <img src="src/images/stage-icon.svg" alt="" />{" "}
                          <p>
                            Stage of the business:{" "}
                            {startupData?.stage_of_business &&
                              startupData?.stage_of_business}
                          </p>
                        </div>
                        <div
                          className={`name-url ${isBlurred ? "blurred" : ""}`}
                        >
                          <h3>
                            {isBlurred
                              ? "Content is blurred"
                              : `${
                                  startupData?.founder_name
                                    ? startupData?.founder_name
                                    : "Content is blurred"
                                }`}{" "}
                            {startupData?.website != "" && (
                              <a href={startupData?.website} target="_blank">
                                <img src="src/images/white-globe.svg" alt="" />{" "}
                                {isBlurred
                                  ? "Content is blurred"
                                  : startupData?.website}
                              </a>
                            )}
                          </h3>
                        </div>
                        <div className="contact-btn-wrapper">
                          <ul className="flex">
                            <li className="flex custom-contact-flex">
                              <img src="src/images/mail-icon.svg" alt="" />
                              <a
                                href={
                                  isBlurred
                                    ? "#"
                                    : `mailto:${startupData?.business_email}`
                                }
                                className={isBlurred ? "blurred" : ""}
                              >
                                {" "}
                                {isBlurred
                                  ? "Content is blurred. Send message to  unblur."
                                  : `${
                                      startupData?.business_email
                                        ? startupData?.business_email
                                        : ""
                                    }`}
                              </a>
                            </li>
                            <li className="flex">
                              <img src="src/images/birthday-date.svg" alt="" />
                              <p>{startupData?.date_of_birth}</p>
                            </li>
                            <li className="flex">
                              <img src="src/images/location-blue.svg" alt="" />
                              <p>{startupData?.city}</p>
                            </li>
                            <li className="flex">
                              <img src="src/images/calender.svg" alt="" />
                              <p>YE: {startupData?.year_of_establish}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="dotted-box-wrapper flex">
                          <div className="dotted-box">
                            <small>Industry Sector</small>
                            <h6>{startupData?.industry_sector}</h6>
                          </div>
                          <div className="dotted-box">
                            <small className={isBlurred ? "blurred" : ""}>
                              {" "}
                              {isBlurred
                                ? "Content is blurred"
                                : "Business Registration No"}
                            </small>
                            <h6 className={isBlurred ? "blurred" : ""}>
                              {" "}
                              {isBlurred
                                ? "ABCDEFGH"
                                : `${
                                    startupData?.business_registration_number
                                      ? startupData?.business_registration_number
                                      : ""
                                  }`}
                            </h6>
                          </div>
                          <div className="dotted-box">
                            <small>Funding Needs</small>
                            <h6>₹ {startupData?.funding_need} </h6>
                            {/* <p>
                            Seeking ₹{startupData?.funding_need} for 10% equity
                            dilution
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`full-detail-box ${
                        isBlurred ? "blurred blurredview" : ""
                      }`}
                    >
                      <h3>
                        {" "}
                        {isBlurred
                          ? "Content is blurred. Send message to  unblur."
                          : `${
                              startupData?.business_name
                                ? startupData?.business_name
                                : ""
                            }`}
                      </h3>
                      <p>
                        {isBlurred
                          ? "Content is blurred. Send message to  unblur."
                          : `${
                              startupData?.business_description
                                ? startupData?.business_description
                                : ""
                            }`}

                        {/* Tech Genius is a software development company specializing
                      in mobile applications and web platforms for businesses.
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book. It has survived not only five centuries, but also
                      the leap into electronic typesetting, remaining
                      essentially unchanged. */}
                      </p>

                      {/* <p>
                      {isBlurred
                        ? "Content is blurred. Send message to  unblur."
                        : decodeContent(
                            encodeContent(
                              "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                            )
                          )}
                    </p> */}
                    </div>
                  </div>
                </div>
                <div className="shadow-box">
                  <div className="model-box">
                    <ul className="flex">
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6>Revenue Model</h6>

                        <p>
                          {startupData?.revenue_model &&
                          isJsonString(startupData?.revenue_model)
                            ? JSON.parse(startupData?.revenue_model)
                            : startupData?.revenue_model}
                          {startupData?.revenue_model &&
                            startupData?.revenue_model?.includes("Other") &&
                            ` (${JSON.parse(startupData?.revenue_model)})`}
                        </p>
                      </li>
                    </ul>
                    {startupData?.market_analist && (
                      <ul className="flex">
                        <li>
                          <img src="src/images/checkbox-icon.svg" alt="" />
                        </li>

                        <li>
                          <h6>Market Analysis</h6>
                          <p>{startupData?.market_analist}</p>
                        </li>
                      </ul>
                    )}

                    <ul className="flex">
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6 className={isBlurred ? "blurred" : ""}>
                          {" "}
                          {isBlurred
                            ? "Content is blurred. Send message to  unblur."
                            : "Team Background"}
                        </h6>
                        <p className={isBlurred ? "blurred" : ""}>
                          {isBlurred
                            ? "Content is blurred. Send message to  unblur."
                            : `${
                                startupData?.team_backGround
                                  ? startupData?.team_backGround
                                  : ""
                              }`}
                        </p>
                      </li>
                    </ul>
                    <ul className="flex">
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6 className={isBlurred ? "blurred" : ""}>
                          {" "}
                          {isBlurred
                            ? "Content is blurred. Send message to  unblur."
                            : "Previous Funding"}
                        </h6>
                        <p className={isBlurred ? "blurred" : ""}>
                          {isBlurred
                            ? "Content is blurred. Send message to  unblur."
                            : `${
                                startupData?.previous_funding
                                  ? startupData?.previous_funding === "Yes"
                                    ? startupData?.note
                                    : startupData?.previous_funding
                                  : ""
                              }`}
                        </p>
                      </li>
                    </ul>

                    <ul className="flex">
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6>Legal Structure</h6>
                        <p>
                          {startupData?.legal_structure === "Other"
                            ? startupData?.otherlegal
                            : startupData?.legal_structure}
                        </p>
                      </li>
                    </ul>

                    <ul className="flex" style={{ alignItems: "baseline" }}>
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6>Pending Litigation </h6>
                        <p>
                          {startupData?.pending_litigations === "Yes"
                            ? startupData?.pending_litigations_notes
                            : startupData?.pending_litigations}
                        </p>
                      </li>
                    </ul>
                    <ul className="flex" style={{ alignItems: "baseline" }}>
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6>GST Identification Number (GSTIN) </h6>
                        <p>
                          {startupData?.gstin
                            ? startupData?.gstin
                            : startupData?.gstin}
                        </p>
                      </li>
                    </ul>
                    {/* intellectualProperty */}
                    <ul className="flex" style={{ alignItems: "baseline" }}>
                      <li>
                        <img src="src/images/checkbox-icon.svg" alt="" />
                      </li>
                      <li>
                        <h6>Patents or trademarks</h6>
                        <p>
                          {startupData?.intellectual_property === "Yes"
                            ? startupData?.tradeMark
                            : startupData?.intellectual_property}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="shadow-box">
                  <div className="about-product">
                    <div className="h4">About Product</div>
                    <div className="img-box">
                      <img
                        src={startupData?.product_image}
                        alt="about-product"
                      />
                    </div>

                    <div
                      className={`social-icon ${isBlurred ? "blurred" : ""}`}
                    >
                      <ul className="flex">
                        <li>Social Links:</li>
                        <li className="flex align-center">
                          {console.log(startupData?.website)}
                          {startupData?.website != "" &&
                            startupData?.website != undefined &&
                            !startupData?.website && (
                              <a href={startupData?.website}>
                                <img
                                  src="src/images/globe.svg"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {startupData?.facebook != undefined &&
                            startupData?.facebook != "" && (
                              <a href={startupData?.facebook} target="_blank">
                                <img
                                  src="src/images/Facebook.svg"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {startupData?.twitter != undefined &&
                            startupData?.twitter != "" && (
                              <a href={startupData?.twitter} target="_blank">
                                <img
                                  src="src/images/Twitter.svg"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {startupData?.instagram != undefined &&
                            startupData?.instagram != "" && (
                              <a href={startupData?.instagram} target="_blank">
                                <img
                                  src="src/images/inst-new.svg"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {startupData?.linkedin != undefined &&
                            startupData?.linkedin != "" && (
                              <a href={startupData?.linkedin} target="_blank">
                                <img
                                  src="src/images/Linkedin1.png"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {startupData?.youtube != undefined &&
                            startupData?.youtube != "" && (
                              <a href={startupData?.youtube} target="_blank">
                                <img
                                  src="src/images/Youtube.svg"
                                  alt="socialicon"
                                />
                              </a>
                            )}

                          {!startupData?.website &&
                            !startupData?.facebook &&
                            !startupData?.twitter &&
                            !startupData?.instagram &&
                            !startupData?.linkedin &&
                            !startupData?.youtube && (
                              <p className="no_social">
                                No Social media icons added
                              </p>
                            )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`h4 ${isBlurred ? "blurred" : ""}`}>
                  {isBlurred
                    ? "Content is blurred. Send message to  unblur."
                    : "About Team"}
                </div>
                <div
                  className={`team-wrapper flex space-between ${
                    isBlurred ? "blurred" : ""
                  }`}
                >
                  <div className="shadow-box full">
                    <div className="about-team">
                      <ul className="flex">
                        <li>
                          <div className="team-img">
                            <img src={startupData?.founderPhoto} alt="" />
                          </div>
                        </li>
                        <li>
                          <div className="team-name">
                            <h6>
                              {isBlurred
                                ? "Content is blurred. Send message to  unblur."
                                : `${
                                    startupData?.founder_name
                                      ? startupData?.founder_name
                                      : ""
                                  }`}
                            </h6>
                            <span>
                              {isBlurred
                                ? "Content is blurred. Send message to  unblur."
                                : `${
                                    startupData.founderDesignation &&
                                    startupData.founderDesignation !=
                                      "undefined"
                                      ? startupData.founderDesignation
                                      : ""
                                  }`}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <p>
                        {isBlurred
                          ? "Content is blurred. Send message to  unblur."
                          : `${
                              startupData?.founderDescription &&
                              startupData?.founderDescription != "undefined"
                                ? startupData?.founderDescription
                                : ""
                            }`}

                        {/* John Doe is a visionary leader with a strong background in
                      technology and business development. He founded TechGenius
                      with the mission of revolutionizing the software
                      development industry. John's strategic vision and
                      commitment to excellence have been instrumental in driving
                      the company's growth and success. */}
                      </p>
                    </div>
                  </div>
                  {teamMembers &&
                    teamMembers.map((member, index) => {
                      return (
                        <div className="shadow-box">
                          <div className="about-team" key={index}>
                            <ul className="flex flex-colmun">
                              <li>
                                <div className="team-img">
                                  <img src={member.photo} alt="" />
                                </div>
                              </li>
                              <li>
                                <div className="team-name">
                                  <h6>
                                    {isBlurred
                                      ? "Content is blurred. Send message to  unblur."
                                      : `${member.name ? member.name : ""}`}
                                  </h6>
                                  <span>
                                    {isBlurred
                                      ? "Content is blurred. Send message to  unblur."
                                      : `${
                                          member.designation
                                            ? member.designation
                                            : ""
                                        }`}
                                  </span>
                                </div>
                              </li>
                            </ul>
                            <p>
                              {isBlurred
                                ? "Content is blurred. Send message to  unblur."
                                : `${member.bio ? member.designation : ""}`}

                              {/* Alan Johnson is a seasoned software engineer with over 10
                      years of experience in developing cutting-edge
                      applications. He specializes in mobile app development and
                      has a keen eye for detail. John is dedicated to pushing
                      the boundaries of technology and delivering exceptional
                      results. */}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="small-text-box">
                <div className="shadow-box">
                  <h3>
                    {(() => {
                      let totalRevenue = 0;

                      if (startupData?.annual_revenue_year1) {
                        totalRevenue += cleanRevenue(
                          startupData.annual_revenue_year1
                        );
                      }

                      if (startupData?.annual_revenue_year2) {
                        totalRevenue += cleanRevenue(
                          startupData.annual_revenue_year2
                        );
                      }

                      if (startupData?.annual_revenue_year3) {
                        totalRevenue += cleanRevenue(
                          startupData.annual_revenue_year3
                        );
                      }

                      return totalRevenue > 0
                        ? formatCurrency(totalRevenue)
                        : "";
                    })()}
                  </h3>
                  <p>Sum of the annual revenue for the past three years</p>
                  <div className="dotted-box-wrapper flex space-between">
                    <div className="dotted-box full">
                      <small>Year 1 ({new Date().getFullYear()})</small>
                      <h6>
                        {formatCurrency(startupData?.annual_revenue_year1)}
                      </h6>
                    </div>
                    <div className="dotted-box">
                      <small>Year 2 ({new Date().getFullYear() - 1})</small>
                      <h6>
                        {formatCurrency(startupData?.annual_revenue_year2)}
                      </h6>
                    </div>
                    <div className="dotted-box">
                      <small>Year 3 ({new Date().getFullYear() - 2})</small>
                      <h6>
                        {formatCurrency(startupData?.annual_revenue_year3)}{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="heading-text">
                    <h5>
                      Gross Profit Margins for the Past 3 Years{" "}
                      <strong>(in %)</strong>
                    </h5>
                    <p>
                      <b>Year 1 ({new Date().getFullYear()}):</b>{" "}
                      {startupData?.gross_profit_margin_year1}%
                    </p>
                    <p>
                      <b>Year 2 ({new Date().getFullYear() - 1}):</b>{" "}
                      {startupData?.gross_profit_margin_year2
                        ? `${startupData?.gross_profit_margin_year2}%`
                        : ""}
                    </p>
                    <p>
                      <b>Year 3 ({new Date().getFullYear() - 2}):</b>{" "}
                      {startupData?.gross_profit_margin_year3
                        ? `${startupData?.gross_profit_margin_year3}%`
                        : ""}
                    </p>
                  </div>
                  <div className="heading-text">
                    <h5>
                      EBITDA Margin for the Past 3 Years <strong>(in %)</strong>
                    </h5>
                    <p>
                      <b>Year 1 ({new Date().getFullYear()}):</b>{" "}
                      {startupData.ebidta_marginyear1
                        ? `${startupData.ebidta_marginyear1}%`
                        : ""}
                    </p>
                    <p>
                      <b>Year 2 ({new Date().getFullYear()}):</b>{" "}
                      {startupData.ebidta_marginyear2
                        ? `${startupData.ebidta_marginyear2}%`
                        : ""}
                    </p>
                    <p>
                      <b>Year 3 ({new Date().getFullYear()}):</b>{" "}
                      {startupData.ebidta_marginyear3
                        ? `${startupData.ebidta_marginyear3}%`
                        : ""}
                    </p>
                  </div>
                  <ul className="table-box">
                    <li className="flex">
                      <p>
                        Customer Acquisition Cost <small>(CAC)</small>
                      </p>
                      <span>
                        {formatCurrency(startupData?.cac)} per customer
                      </span>
                    </li>
                    <li className="flex">
                      <p>
                        Lifetime Value of a Customer <small>(LTV)</small>
                      </p>
                      <span>{formatCurrency(startupData?.liv)}</span>
                    </li>
                    <li className="flex">
                      <p>Burn Rate</p>
                      <span>₹ {startupData?.burn_rate} per month</span>
                    </li>
                    <li className="flex">
                      <p>Number of Active Users/Clients</p>
                      <span>{startupData?.noOfActiceUser} </span>
                    </li>
                    <li className="flex">
                      <p>Funding Allocation</p>
                      <span>₹ {startupData?.funding_sougth}</span>
                    </li>
                    <li className="flex">
                      <p> Total addressable Market(TAM)</p>
                      <span>{startupData?.tam}</span>
                    </li>
                  </ul>
                  <div className="btn-box">
                    <a className="cta-btn btn-orange minmaxrise">
                      {formatCurrency(startupData?.minimum_raise_description)}
                      <span>(Minimum Raise)</span>
                    </a>
                    <a className="cta-btn btn-orange minmaxrise">
                      {formatCurrency(startupData?.maximum_raise_description)}{" "}
                      <span>(Maximum Raise)</span>
                    </a>
                    <a
                      // href="javascript:void(0)"
                      className="cta-btn"
                      onClick={async () => {
                        openContactpopup();
                        // if (userChats?.length < 5) {
                        //   openContactpopup();
                        // } else {
                        //   const profile = await Profile();
                        //   if (profile?.user_id && profile?.user_id) {
                        //     if (
                        //       profile?.user_id?.level == "1" &&
                        //       userChats?.length >= 5
                        //     ) {
                        //       return toast.error("You can not contact");
                        //     } else if (
                        //       profile?.user_id?.level == "2" &&
                        //       userChats?.length >= 10
                        //     ) {
                        //       return toast.error("You can not contact");
                        //     } else {
                        //       openContactpopup();
                        //     }
                        //   }
                        // }
                      }}
                    >
                      <img src="src/images/contect-icon.png" alt="" />
                      {!ismessagesent ? " Contact" : "Message Sent"}
                    </a>
                    <p>
                      Please send a message, once the founder responds, you will
                      be able to view the complete pitch.{" "}
                    </p>
                  </div>
                </div>
                {!isBlurred && (
                  <div className="shadow-box">
                    <div className="request-form">
                      <h5> Request for Due Diligence</h5>
                      <form action="">
                        <div className="form-wrapper">
                          <div className="form-group">
                            <label htmlFor="">Investor Name</label>
                            <input
                              type="text"
                              placeholder="Enter your full name"
                              className="form-control"
                              name="name"
                              value={requestInvestor.name}
                              onChange={handleChange}
                            />
                            {errors.name && (
                              <p className="error-msg">{errors.name}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Investor Email</label>
                            <input
                              type="email"
                              placeholder="Enter your email address"
                              className="form-control"
                              name="email"
                              value={requestInvestor.email}
                              onChange={handleChange}
                            />
                            {errors.email && (
                              <p className="error-msg">{errors.email}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Investor Phone Number</label>
                            <input
                              type="text"
                              placeholder="Enter your phone number"
                              className="form-control"
                              name="phoneNumber"
                              value={requestInvestor.phoneNumber}
                              onChange={handleChange}
                            />
                            {errors.phoneNumber && (
                              <p className="error-msg">{errors.phoneNumber}</p>
                            )}
                          </div>
                          <div className="form-group not-requeried">
                            <label htmlFor="">
                              Note for{" "}
                              <a href="/" className="home-link">
                                (Udharaa.com)
                              </a>
                            </label>
                            <textarea
                              name="note"
                              id="note"
                              placeholder="Add any specific notes or requirements here"
                              className="form-control"
                              value={requestInvestor.note}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          <div className="form-group">
                            <div className="h6">Payment for Due Diligence</div>
                            <div className="dotted-box full">
                              <small>Services Amount</small>
                              <h6>₹ {Amount}</h6>
                              <p>
                                Payment Method: Stripe (ensure you integrate
                                Stripe’s <br /> payment gateway for processing
                                credit/debit card payments)
                              </p>
                            </div>
                          </div>
                          <div className="from-group">
                            <button onClick={handleSubmit} className="cta-btn">
                              Pay
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {!isBlurred && (
                  <div className="shadow-box">
                    <div className="request-form">
                      <h5>Ready to Invest</h5>
                      <form action="">
                        <div className="form-wrapper">
                          <div className="form-group">
                            <label htmlFor="">Owner's Name</label>
                            <textarea
                              placeholder="Enter the full name of the company owner or representative"
                              className="form-control"
                              name="ownerName"
                              value={investForm.ownerName}
                              onChange={handleChange}
                            ></textarea>
                            {errors.ownerName && (
                              <p className="error-msg">{errors.ownerName}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Investment Amount</label>
                            <input
                              type="text"
                              name="investmentAmount"
                              placeholder="How much would you like to invest?"
                              className="form-control"
                              value={investForm.investmentAmount}
                              onChange={handleChange}
                            />
                            {errors.investmentAmount && (
                              <p className="error-msg">
                                {errors.investmentAmount}
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Equity Dilution</label>
                            <input
                              type="text"
                              name="equityDilution"
                              placeholder="Proposed equity percentage"
                              className="form-control"
                              value={investForm.equityDilution}
                              onChange={handleChange}
                            />
                            {errors.equityDilution && (
                              <p className="error-msg">
                                {errors.equityDilution}
                              </p>
                            )}
                          </div>
                          <div className="h6">Terms Acceptance</div>
                          <label htmlFor="">Terms Acceptance</label>
                          <div className="form-group checkbox not-requeried">
                            <label className="flex">
                              I confirm that the company's owner/director or CEO
                              is ready and agrees with these terms.
                              <input
                                type="checkbox"
                                name="termsAcceptance"
                                checked={investForm.termsAcceptance}
                                onChange={handleChange}
                              />
                              {errors.termsAcceptance && (
                                <p className="error-msg">
                                  {errors.termsAcceptance}
                                </p>
                              )}
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="from-group">
                            <button onClick={investSubmit} className="cta-btn">
                              Invest
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <Footer2 />
    </>
  );
}
export default InvestorViewDetails;
