/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Header2 from "../../common/Header2";
import Footer2 from "../../common/Footer2";
import { apiurl } from "../Url";
import { getItem } from "../../common/reusable";
import { post, get } from "../../services/services";

function Watchlist() {
  const [activeTab, setActiveTab] = useState(1);
  const [watchList, setWatchList] = useState([]);
  const [showstartup, Setshowstartup] = useState(12);
  const navigate = useNavigate();
  const investorID = getItem("UserId");
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  const toggleFavourites = (e, type, id) => {
    e.preventDefault();

    try {
      post(`${apiurl}investors/deleteWishList`, {
        investorId: investorID,
        startupId: id,
      })
        .then(res => {
          if (!res) {
          } else {
            if (!res.data.status) {
              toast.error(res.data.message)
            } else {
              getWatchlist();
              toast("Startup removed from  favourites");
            }

          }


        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
    }

  };
  const truncateDescription = (description, length) => {
    if (!description) {
      return "";
    }
    if (description.length > length) {
      return description.substring(0, length) + "...";
    }
    return description;
  };
  const getWatchlist = () => {
    try {
      get(`${apiurl}investors/myWishList/${getItem("UserId")}`)
        .then(res => {
          if (!res) {

          } else {
            if (res?.data?.wishList) {
              setWatchList(res?.data?.wishList);
            }
          }
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getWatchlist();
  }, []);

  const getsinglestartupdetails = (e, id, _id) => {
    e.preventDefault();
    navigate("/investor-view-details", { state: { user_id: id, member_id: _id, slug: "/favourite-list" } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleViewMore = e => {
    e.preventDefault();
    Setshowstartup(prevCount => prevCount + 12);
  };
  const handleViewLess = e => {
    e.preventDefault();
    Setshowstartup(prevCount => prevCount - 12);
  };

  return (
    <>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Watchlist</title>
      </Helmet>
      <section className="dashboard-sec watchlist_page">
        <div className="container">
          <div className="bredcrum-box flex align-center space-between">
            <div className="left-box">
              <h5>My Favourite List</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>My Favourite List</li>
              </ul>
            </div>
            {watchList.length != 0 && <div className="right-grid-box">
              <ul className="flex">
                <li
                  style={activeTab == 1 ? {} : { filter: "grayscale(1)" }}
                  className={activeTab === 1 ? "" : ""}
                  data-tab="1"
                  onClick={() => handleTabClick(1)}
                >
                  <img src="src/images/gridicon01.svg" alt="" />
                </li>
                <li
                  style={activeTab == 2 ? {} : { filter: "grayscale(1)" }}
                  className={activeTab === 2 ? "" : ""}
                  data-tab="2"
                  onClick={() => handleTabClick(2)}
                >
                  {/* <img src="src/images/gridicon02.svg" alt="" /> */}
                  <img
                    src={
                      activeTab === 2
                        ? "src/images/activeicon.png"
                        : "src/images/gridicon02.svg"
                    }
                    alt=""
                  />
                </li>
              </ul>
            </div>}

          </div>
          <div className="box-wrapper">
            <div
              id="tab-1"
              className={`tab-content ${activeTab === 1 ? "active" : ""}`}
            >
              <div className="row flex">

                {watchList.length != 0 ? (
                  <>
                    {watchList?.sort((a, b) => {
                      const aCreatedAt = a.user_id?.createdAt
                        ? new Date(a.user_id.createdAt)
                        : new Date(0);
                      const bCreatedAt = b.user_id?.createdAt
                        ? new Date(b.user_id.createdAt)
                        : new Date(0);
                      return bCreatedAt - aCreatedAt;
                    }).slice(0, showstartup)?.map((data, i) => {
                      return (
                        <div key={i} className="box">
                          <div className="img-box img-box-custom">
                            <img
                              src={
                                data.logo
                                  ? `${data.logo}`
                                  : "src/images/dashbord-img01.jpg"
                              }
                              alt="dashbord"
                            />
                          </div>
                          <div className="bottom-text">
                            <Link
                              to=""
                              onClick={e =>
                                getsinglestartupdetails(
                                  e,
                                  data._id,
                                  data.user_id._id
                                )
                              }
                            >
                              <h6 className="title">{data.business_name}</h6>
                            </Link>
                            <p>
                              {truncateDescription(
                                data.business_description,
                                180
                              )}
                            </p>
                            <div className="location flex align-center">
                              <img src="src/images/location.svg" alt="" />
                              {data.state}
                            </div>
                            <div className="location technology flex align-center">
                              <img src="src/images/bulding.svg" alt="" />
                              {data.industry_sector}
                            </div>
                            <div className="btn-group flex">
                              <a className="btn">
                                LYR: {data.minimum_raise_description}
                              </a>
                              <a className="btn">
                                MR: {data.maximum_raise_description}
                              </a>
                            </div>
                          </div>
                          <div className="hide-text">
                            <ul className="flex">
                              <li
                                onClick={e =>
                                  toggleFavourites(
                                    e,
                                    "delete",
                                    data?.user_id?._id
                                  )
                                }
                              >
                                <img src="src/images/fill-heart.svg" alt="" />
                              </li>
                              <li
                                onClick={() =>
                                  navigate("/investor-view-details", {
                                    state: { user_id: data?._id },
                                  })
                                }
                              >
                                <img src="src/images/eye.svg" alt="" />
                              </li>
                              {/* <li
                            onClick={() => {
                              mimi == data?.user_id
                                ? setMimi("")
                                : setMimi(data?.user_id);
                            }}
                          >
                            <img src="src/images/dots.svg" alt="" />
                          </li> */}
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="noresult-wishlist">
                      <p>You have no startup in your Favourite-list.</p>
                      <p>
                        Click the{" "}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="hearticonivestor"
                        >
                          <path
                            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                            stroke="red"
                            strokeWidth="2"
                            fill="none"
                          />
                        </svg>
                        {" "}icon next to any startup in your dashboard to add
                        your favourites.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              id="tab-2"
              className={`tab-content ${activeTab === 2 ? "active" : ""}`}
            >
              {watchList.length != 0 ? (
                <>
                  <div className="grid-view-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Industry Sector</th>
                          <th>Location</th>
                          <th>Minimum Raise</th>
                          <th>Year Established</th>
                          <th>Last Year Revenue</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {watchList?.sort((a, b) => {
                          const aCreatedAt = a.user_id?.createdAt
                            ? new Date(a.user_id.createdAt)
                            : new Date(0);
                          const bCreatedAt = b.user_id?.createdAt
                            ? new Date(b.user_id.createdAt)
                            : new Date(0);
                          return bCreatedAt - aCreatedAt;
                        }).slice(0, showstartup)?.map((startuplist, index) => (
                          <tr key={index}>
                            <td>
                              <ul>
                                <li className="flex">
                                  <div className="img-box logo-size">
                                    <figure>
                                      <img
                                        src={
                                          startuplist?.logo
                                            ? `${startuplist?.logo}`
                                            : "src/images/dashbord-img01.jpg"
                                        }
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                  <div className="text">
                                    <h6>{startuplist?.business_name}</h6>
                                    <p>
                                      {truncateDescription(
                                        startuplist?.business_description,
                                        50
                                      )}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </td>
                            <td>{startuplist?.industry_sector}</td>
                            <td>{startuplist?.state}</td>
                            <td>{startuplist?.minimum_raise_description}</td>
                            <td>{startuplist?.year_of_establish}</td>
                            <td>
                              <img src="src/images/revenue.svg" alt="" />
                              <span>
                               {startuplist?.maximum_raise_description}
                              </span>
                            </td>
                            <td>
                              <div className="flex">
                                <div className="icons flex custom-icons-flex ">
                                  <a
                                    onClick={e =>
                                      toggleFavourites(
                                        e,
                                        "delete",
                                        startuplist?.user_id?._id
                                      )
                                    }
                                  >
                                    <img
                                      src="src/images/fill-heart.svg"
                                      alt=""
                                    />
                                  </a>
                                  <a
                                    onClick={e =>
                                      getsinglestartupdetails(
                                        e,
                                        startuplist._id,
                                        startuplist.user_id._id
                                      )
                                    }
                                  >
                                    <img
                                      src="src/images/fill-eye-icon.svg"
                                      alt=""
                                    />
                                  </a>
                                  {/* <a
                                onClick={() => {
                                  mimi == startuplist?.user_id
                                    ? setMimi("")
                                    : setMimi(startuplist?.user_id);
                                }}
                              >
                                <img src="src/images/dots.svg" alt="" />
                              </a> */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="noresult-wishlist">
                    <p>You have no startup in your Favourite-list.</p>
                    <p>
                      Click the{" "}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hearticonivestor"
                      >
                        <path
                          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                          stroke="red"
                          strokeWidth="2"
                          fill="none"
                        />
                      </svg>
                      {" "}
                      icon next to any startup in your dashboard to add your
                      favourites.
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="viewmoredashboard">
            {showstartup > 12 && watchList && watchList.length > 0 && (
              <a
                className="cta-btn btn-blue header-btn"
                onClick={handleViewLess}
              >
                View Less
              </a>
            )}
            {watchList && watchList.length > showstartup && (
              <a
                className="cta-btn btn-blue header-btn"
                onClick={handleViewMore}
              >
                View More
              </a>
            )}
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default Watchlist;
